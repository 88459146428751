import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { PaymentState } from './types'
import APIClient from '@/calendesk/api/APIClient'
import { plainToClass } from 'class-transformer'
import BookingPaymentTransactionsRequestData from '@/calendesk/models/DTO/Request/BookingPaymentTransactionsRequestData'
import BookingPaymentTransactionResponse from '@/calendesk/models/DTO/Response/BookingPaymentTransactionResponse'
import SimpleStoreProductTransactionRequestData from '@/calendesk/models/DTO/Request/SimpleStoreProductTransactionRequestData'
import SimpleStoreProductTransactionResponse
  from '@/calendesk/models/DTO/Response/SimpleStoreProductTransactionResponse'
import SimpleStoreProduct from '@/calendesk/models/DTO/Response/SimpleStoreProduct'

export const actions: ActionTree<PaymentState, RootState> = {
  resetState ({ commit }) {
    commit('SET_BOOKING_PAYMENT_TRANSACTIONS', null)
  },
  fetchBookingPaymentTransactions ({ commit }, data: BookingPaymentTransactionsRequestData): Promise<BookingPaymentTransactionResponse> {
    return new Promise((resolve, reject) => {
      APIClient.getBookingPaymentTransactions(data)
        .then(({ data }) => {
          const response = plainToClass(BookingPaymentTransactionResponse, data as BookingPaymentTransactionResponse)
          commit('SET_BOOKING_PAYMENT_TRANSACTIONS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchSimpleStoreProductPaymentTransactions ({ commit }, data: SimpleStoreProductTransactionRequestData): Promise<SimpleStoreProductTransactionResponse> {
    return new Promise((resolve, reject) => {
      APIClient.getSimpleStoreProductPaymentTransactions(data)
        .then(({ data }) => {
          const response = plainToClass(SimpleStoreProductTransactionResponse, data as SimpleStoreProductTransactionResponse)
          commit('SET_SIMPLE_STORE_PRODUCT_PAYMENT_TRANSACTIONS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchSimpleStoreProduct (context, productId: string|number): Promise<SimpleStoreProduct> {
    return new Promise((resolve, reject) => {
      APIClient.getSimpleStoreProduct(productId)
        .then(({ data }) => {
          const response = plainToClass(SimpleStoreProduct, data as SimpleStoreProduct)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
