





























































import mixins from 'vue-typed-mixins'
import DraftElement from '../../calendesk/sections/section/mixins/DraftElement'
import { mapActions, mapGetters } from 'vuex'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import UpdateUserRequestData from '@/calendesk/models/DTO/Request/UpdateUserRequestData'

export default mixins(DraftElement).extend({
  name: 'UserAvatar',
  data () {
    return {
      isLoading: false,
      avatarMaxSize: 10485760
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    })
  },
  methods: {
    ...mapActions({
      uploadAvatar: 'user/uploadAvatar',
      updateUser: 'user/updateData'
    }),
    selectImageAction () {
      (this.$refs.imageInputRef as any).click()
    },
    uploadAvatarHandle (e: any) {
      const image = e.target.files[0]

      if (image && image.size < this.avatarMaxSize) {
        this.isLoading = true
        const body = new FormData()
        body.append('image', image)
        this.uploadAvatar(body)
          .catch(error => {
            errorNotification(null, error)
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        errorNotification('file_is_too_large', null, false)
      }
    },
    removeImageAction () {
      const requestData = new UpdateUserRequestData()
      requestData.default_image_id = null

      this.isLoading = true
      this.updateUser(requestData).finally(() => {
        this.isLoading = false
      })
    }
  }
})
