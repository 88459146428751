import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import { StripeSelect } from './types'

export const getters: GetterTree<StripeSelect, RootState> = {
  getStripe (state): Stripe | null {
    return state.stripe
  },
  getAccountId (state): string | null {
    return state.accountId
  }
}
