import { GetterTree } from 'vuex'
import { UserState } from './types'
import { RootState } from '../../types'
import User from '@/calendesk/models/DTO/Response/User'

export const getters: GetterTree<UserState, RootState> = {
  getUser (state): User | null {
    return state.user
  },
  isUserLogged (state): boolean {
    return !!state.user
  },
  getAfterLoginAction (state): Function | null {
    return state.afterLoginAction
  }
}
