import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { BookingSelect } from './types'
import { RootState } from '../../types'

export const state: BookingSelect = {
  bookings: null,
  reloadAllBookings: false,
  availableBookingFilters: null,
  selectedBookingFilters: null,
  bookingToProcess: null
}

const namespaced = true

export const booking: Module<BookingSelect, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
