










































































































import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import { mapActions, mapGetters } from 'vuex'
import DateRange from '@/calendesk/models/DTO/Response/DateRange'

export default mixins(DraftElement).extend({
  name: 'FilterDates',
  data () {
    return {
      loading: false,
      ignoreChange: false,
      fromDate: null,
      toDate: null,
      fromMenu: false,
      toMenu: false
    }
  },
  computed: {
    ...mapGetters({
      selectedFilters: 'booking/getSelectedFilters'
    })
  },
  watch: {

  },
  created () {
    this.selectFilters()
  },
  methods: {
    ...mapActions({
      setSelectedFiltersForDates: 'booking/setSelectedFiltersForDates'
    }),
    onChange () {
      if (!this.ignoreChange) {
        const dateRange = new DateRange(this.fromDate, this.toDate)

        this.setSelectedFiltersForDates(dateRange)
      } else {
        this.ignoreChange = false
      }
    },
    selectFilters () {
      if (this.selectedFilters && this.selectedFilters.dates) {
        this.ignoreChange = true
        this.fromDate = this.selectedFilters.dates.from
        this.toDate = this.selectedFilters.dates.to
      }
    },
    fromDateHandle () {
      (this.$refs.fromMenu as any).save(this.fromDate)
      this.onChange()
    },
    toDateHandle () {
      (this.$refs.toMenu as any).save(this.toDate)
      this.onChange()
    }
  }
})
