














import Vue from 'vue'
import AppDialog from '@/components/dialogs/AppDialog.vue'
import dialogList from '@/components/dialogs/dialogList'
import confirmDialogList from '@/components/dialogs/confirmDialogList'
import NavigationDrawer from '@/components/drawer/NavigationDrawer.vue'
import PreviewAsset from '@/components/PreviewAsset.vue'
import SideDrawer from '@/components/drawer/SideDrawer.vue'
import Snackbar from '@/views/components/Snackbar.vue'
import Layout from '@/views/Layout.vue'
import ReloadSnackbar from '@/components/ReloadSnackbar.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'

export default Vue.extend({
  name: 'Index',
  components: {
    ConfirmDialog,
    ReloadSnackbar,
    Layout,
    Snackbar,
    AppDialog,
    NavigationDrawer,
    PreviewAsset,
    SideDrawer
  },
  data () {
    return {
      dialogList,
      confirmDialogList
    }
  }
})
