









































































































































import Subheader from '@/views/components/Subheader.vue'
import mixins from 'vue-typed-mixins'
import Renderer from '@/calendesk/mixins/Renderer'
import { mapActions, mapGetters } from 'vuex'
import Page from '@/calendesk/models/DTO/Response/Page'
import { PageType, SideDrawerTypes, UserSubscriptionStatus } from '@/calendesk/models/BuilderTypes'
import CNoDataInfo from '@/components/CNoDataInfo.vue'
import AccountNotActivatedInfo from '@/views/components/AccountNotActivatedInfo.vue'
import UserSubscriptionsResponse from '@/calendesk/models/DTO/Response/UserSubscriptionsResponse'
import UserSubscriptionsRequestData from '@/calendesk/models/DTO/Request/UserSubscriptionsRequestData'
import ConfirmDialog from '@/calendesk/models/ConfirmDialog'
import { ConfirmDialogTypes } from '@/components/dialogs/ConfirmDialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import UserSubscription from '@/calendesk/models/DTO/Response/UserSubscription'
import DataTableFooter from '@/calendesk/models/DataTableFooter'
import { getServiceNameForId } from '@/calendesk/prototypes/getServiceNameForId'
import Service from '@/calendesk/models/DTO/Response/Service'

export default mixins(Renderer).extend({
  name: 'Subscriptions',
  components: {
    AccountNotActivatedInfo,
    Subheader,
    CNoDataInfo
  },
  data () {
    return {
      dataTableFooterProps: DataTableFooter,
      isLoading: false,
      total: 0,
      tableOptions: {
        itemsPerPage: 25,
        page: 1,
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      statuses: UserSubscriptionStatus,
      openItems: [] as Array<number>,
      maxNumberOfServicesShown: 5
    }
  },
  created () {
    const page = new Page(
      null,
      null,
      this.$trans('subscriptions'),
      PageType.STATIC_PAGE,
      this.$router.currentRoute.name as string,
      null,
      false,
      null)
    this.setCurrentPage(page)
  },
  computed: {
    ...mapGetters({
      shouldReload: 'subscription/getReloadAllSubscriptions',
      services: 'service/getServices'
    }),
    activeServiceIds () {
      return (serviceIds: Array<number>) => {
        const activeServiceIds = this.services.map((service: Service) => service.id)
        return serviceIds.filter((id) => activeServiceIds.includes(id))
      }
    },
    headers (): Array<Record<string, any>> {
      return [
        {
          text: this.$trans('number'),
          value: 'id',
          sortable: true,
          class: 'header-style'
        },
        {
          text: this.$trans('created_at'),
          value: 'created_at',
          sortable: true,
          class: 'header-style'
        },
        {
          text: this.$trans('subscription'),
          value: 'name',
          sortable: false,
          class: 'header-style'
        },
        {
          text: this.$trans('payment_price'),
          value: 'payment_price',
          sortable: false,
          class: 'header-style'
        },
        {
          text: this.$trans('status'),
          value: 'status',
          sortable: false,
          class: 'header-style',
          align: 'center'
        },
        {
          text: this.$trans('usage'),
          value: 'usage',
          sortable: false,
          class: 'header-style',
          align: 'center'
        },
        {
          text: this.$trans('cancel'),
          value: 'cancel',
          sortable: false,
          class: 'header-style',
          align: 'center'
        }
      ]
    }
  },
  watch: {
    shouldReload (reload) {
      if (reload) {
        this.tableOptions.page = 1
        this.reloadDataDebounced()
        this.setReloadAllSubscriptions(false)
      }
    }
  },
  mounted () {
    this.reload()
    this.$watch('tableOptions', this.reloadDataDebounced)
  },
  methods: {
    ...mapActions({
      cancelUserSubscription: 'subscription/cancelUserSubscription',
      fetchUserSubscriptions: 'subscription/fetchUserSubscriptions',
      setReloadAllSubscriptions: 'subscription/setReloadAllSubscriptions'
    }),
    getServiceNameForId,
    getColorForStatus (status: string): string {
      if (status !== 'active') {
        return 'warning'
      }

      return 'success2'
    },
    canShowUsage (userSubscription: UserSubscription): boolean {
      if (userSubscription.status === this.statuses.ACTIVE) {
        return true
      }

      if (userSubscription.status === this.statuses.CANCELED) {
        return userSubscription.endsAt ? this.$moment(userSubscription.endsAt).isAfter(this.$moment()) : true
      }

      return false
    },
    getUsage (userSubscription: UserSubscription): string {
      if (userSubscription.subscription.serviceLimit) {
        return `${userSubscription.currentPeriodUsage}/${userSubscription.subscription.serviceLimit}`
      }

      return `${userSubscription.currentPeriodUsage}/∞`
    },
    reloadDataDebounced () {
      this.debounce(this.reload, 400)()
    },
    reload () {
      this.isLoading = true

      this.fetchUserSubscriptions(this.getFilters()).then((response: UserSubscriptionsResponse) => {
        this.total = response.total
        this.tableOptions.page = response.currentPage
      }).finally(() => {
        this.isLoading = false
      })
    },
    openFilters () {
      this.openSideDrawer(SideDrawerTypes.BOOKINGS_FILTER)
    },
    getFilters () {
      const filters = new UserSubscriptionsRequestData()

      filters.page = this.tableOptions.page
      filters.limit = this.tableOptions.itemsPerPage
      filters.order_by = this.tableOptions.sortBy[0] ? this.tableOptions.sortBy[0] : 'created_at'
      filters.ascending = this.tableOptions.sortDesc[0] ? 0 : 1

      return filters
    },
    cancelUserSubscriptionClicked (userSubscription: UserSubscription) {
      this.openConfirmDialog(
        new ConfirmDialog(true, ConfirmDialogTypes.COMMON, DialogSize.SMALL, {}, () => {
          this.setConfirmDialogLoader(true)

          this.cancelUserSubscription(userSubscription.id).then(async () => {
            if (typeof (window as any).calendeskUserSubscriptionCanceled === 'function') {
              await (window as any).calendeskUserSubscriptionCanceled(userSubscription, this.user)
            }

            this.setReloadAllSubscriptions(true)
          }).catch((error) => {
            errorNotification(null, error, false)
          }).finally(() => {
            this.closeConfirmDialog()
            this.setConfirmDialogLoader(false)
          })
        })
      )
    },
    shouldShowService (index: number, itemId: number) {
      if (this.isItemOpen(itemId)) {
        return true // Show all user services if the item is open
      } else {
        return index < this.maxNumberOfServicesShown // Show first 5 services if the item is not open
      }
    },
    isItemOpen (itemId: number) {
      return this.openItems.includes(itemId)
    },
    toggleItemOpen (itemId: number) {
      if (this.isItemOpen(itemId)) {
        // Remove the item id from the array if it's already open
        this.openItems = this.openItems.filter(id => id !== itemId)
      } else {
        // Add the item id to the array if it's not open
        this.openItems.push(itemId)
      }
    }
  }
})
