




































































































import mixins from 'vue-typed-mixins'
import Navigation from '@/calendesk/mixins/Navigation'
export default mixins(Navigation).extend({
  name: 'NavigationDrawer',
  data () {
    return {
      drawer: false,
      selectedPage: null
    }
  },
  watch: {
    drawer (): void {
      this.setNavigationDrawer(this.drawer)
    },
    showNavigationDrawer (): void {
      this.drawer = this.showNavigationDrawer
    }
  }
})
