export default class GdprFieldUserData {
  public name: string
  public label: string
  public required: boolean
  public selected: boolean

  constructor (name: string, label: string, required: boolean, selected: boolean) {
    this.name = name
    this.label = label
    this.required = required
    this.selected = selected
  }
}
