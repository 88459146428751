import Vue from 'vue'

/* filters */
import { money } from '@/calendesk/filters/money'
import { capitalize } from '@/calendesk/filters/capitalize'
import { lowercase } from '@/calendesk/filters/lowercase'
import { renderDuration } from '@/calendesk/filters/renderDuration'
import { phoneNumber } from '@/calendesk/filters/phoneNumber'
import timeNotation from '@/calendesk/filters/timeNotation'
import plural from '@/calendesk/filters/plural'
import truncate from '@/calendesk/filters/truncate'
import { stripHtmlTags } from '@/calendesk/filters/stripHtmlTags'
import { stripSurroundingPTags } from '@/calendesk/filters/stripSurroundingPTags'

Vue.filter('plural', plural)
Vue.filter('money', money)
Vue.filter('lowercase', lowercase)
Vue.filter('capitalize', capitalize)
Vue.filter('durationFilter', renderDuration)
Vue.filter('phoneNumber', phoneNumber)
Vue.filter('timeNotation', timeNotation)
Vue.filter('truncate', truncate)
Vue.filter('stripHtmlTags', stripHtmlTags)
Vue.filter('stripSurroundingPTags', stripSurroundingPTags)
