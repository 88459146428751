import { ActionTree } from 'vuex'
import { SetupState } from './types'
import { RootState } from '../../types'
import APIClient from '@/calendesk/api/APIClient'
import vuetify from '@/plugins/vuetify'
import store from '@/store'
import Configuration from '@/calendesk/models/DTO/Response/Configuration'
import { plainToClass } from 'class-transformer'
import moment from 'moment-timezone'
import * as storage from '@/calendesk/tools/storage'
import { getAccessToken } from '@/calendesk/prototypes/token'

export const actions: ActionTree<SetupState, RootState> = {
  init ({ state, commit, rootState }): Promise<void> {
    return new Promise((resolve, reject) => {
      const tenant = state.tenant
      const draftUuid = state.draftUuid

      if (!tenant || !draftUuid) {
        store.dispatch('setup/fetchTenant').then(() => {
          store.dispatch('setup/initialFetchForTenant').then(() => {
            if (getAccessToken()) {
              store.dispatch('user/fetchData', null, { root: true }).finally(() => {
                resolve()
              })
            } else {
              resolve()
            }
          })
        }).catch((error) => {
          reject(error)
        })
      } else {
        if (!state.appConfiguration || !state.translations || !rootState.builder.draft) {
          commit('SET_TENANT', tenant)
          commit('SET_DRAFT_UUID', draftUuid)

          const requests = [
            store.dispatch('setup/initialFetchForTenant')
          ]

          if (getAccessToken()) {
            requests.push(store.dispatch('user/fetchData', null, { root: true }))
          }

          return Promise.all(requests).then(() => {
            resolve()
          }).catch((error) => {
            reject(error)
          })
        } else {
          resolve()
        }
      }
    })
  },
  fetchTenant ({ commit }): Promise<void> {
    return new Promise((resolve, reject) => {
      const domain = 'https://' + window.location.hostname
      APIClient.getTenantByDomain(domain as string)
        ?.then(({ data }) => {
          if (data && data.tenant) {
            commit('SET_TENANT', data.tenant.name)
            commit('SET_DRAFT_UUID', data.draft_uuid)
          }
          resolve()
        })
        ?.catch(error => {
          reject(error)
        })
    })
  },
  initialFetchForTenant ({ rootGetters, dispatch }): Promise<void> {
    return new Promise((resolve, reject) => {
      const fetchDraft = store.dispatch('builder/fetchDraft')
      const fetchConfiguration = store.dispatch('setup/fetchConfiguration')
      const fetchCategoryAndServices = store.dispatch('service/fetchCategoryAndServices')
      Promise.all([fetchDraft, fetchConfiguration, fetchCategoryAndServices])
        .then(() => {
          const draft = rootGetters['builder/getDraft']
          const configuration = rootGetters['setup/getAppConfiguration']

          let language = configuration.language
          if (draft.configuration && draft.configuration.wb_primary_language__select__ && draft.configuration.wb_primary_language__select__.value) {
            language = draft.configuration.wb_primary_language__select__.value
          }

          dispatch('updateLanguage', language).then(() => {
            resolve()
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchConfiguration ({ commit }): Promise<Configuration> {
    return new Promise((resolve, reject) => {
      APIClient.configuration()
        ?.then(({ data }) => {
          const configuration = plainToClass(Configuration, data, { excludeExtraneousValues: true })
          commit('SET_APP_CONFIGURATION', configuration)
          resolve(configuration)
        })
        ?.catch(error => {
          reject(error)
        })
    })
  },
  fetchRegulations ({ commit }): Promise<string> {
    return new Promise((resolve, reject) => {
      APIClient.getRegulations()
        ?.then(({ data }) => {
          commit('SET_REGULATIONS', data.regulations)
          resolve(data.regulations)
        })
        ?.catch(error => {
          reject(error)
        })
    })
  },
  async updateLanguage ({ commit }, language: string) {
    let locale = language.toLowerCase()

    switch (locale) {
      case 'bg':
      case 'cs':
      case 'de':
      case 'el':
      case 'en':
      case 'es':
      case 'et':
      case 'fi':
      case 'fr':
      case 'ga':
      case 'hi':
      case 'hr':
      case 'hu':
      case 'lt':
      case 'lv':
      case 'mt':
      case 'nl':
      case 'pl':
      case 'pt':
      case 'ru':
      case 'sk':
      case 'sl':
      case 'sq':
      case 'sv':
      case 'uk':
        await import(`@/calendesk/lang/${locale}`).then(language => {
          commit('SET_TRANSLATIONS', { ...language.default })
        })
        break
      default:
        locale = 'en'
        await import('@/calendesk/lang/en').then(language => {
          commit('SET_TRANSLATIONS', { ...language.default })
        })
        break
    }
    storage.local.setItem('locale', locale)
    vuetify.framework.lang.current = locale
    document.documentElement.lang = locale
    moment.locale(locale)

    const timeNotation = storage.local.getItem('timeNotation')

    if (!timeNotation && locale === 'en') {
      commit('SET_TIME_NOTATION', '12h')
    }
  },
  fetchPrivacyPolicy ({ commit }): Promise<string> {
    return new Promise((resolve, reject) => {
      APIClient.getPrivacyPolicy()
        ?.then(({ data }) => {
          commit('SET_PRIVACY_POLICY', data.privacy_policy)
          resolve(data.privacy_policy)
        })
        ?.catch(error => {
          reject(error)
        })
    })
  },
  setPreloader ({ commit }, preloader: boolean) {
    commit('SET_PRELOADER', preloader)
  },
  setTimeNotation ({ commit }, timeNotation: string) {
    commit('SET_TIME_NOTATION', timeNotation)
  },
  setInitialSetup ({ commit }, finished: boolean) {
    commit('SET_INITIAL_SETUP', finished)
  }
}
