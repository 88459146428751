import Dialog from '@/calendesk/models/Dialog'
import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import { Common } from './types'
import ConfirmDialog from '@/calendesk/models/ConfirmDialog'

export const getters: GetterTree<Common, RootState> = {
  getDialog2 (state): Dialog {
    return state.dialog2
  },
  getClosedDialogData2 (state): any {
    return state.closedDialogData2
  },
  getDialog (state): Dialog {
    return state.dialog
  },
  getClosedDialogData (state): any {
    return state.closedDialogData
  },
  getConfirmDialog (state): ConfirmDialog {
    return state.confirmDialog
  },
  getClosedConfirmDialogData (state): any {
    return state.closedConfirmDialogData
  },
  getConfirmDialogLoader (state): boolean {
    return state.confirmDialogLoader
  }
}
