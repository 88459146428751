






































import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import Service from '@/calendesk/models/DTO/Response/Service'
import { mapActions, mapGetters } from 'vuex'

export default mixins(DraftElement).extend({
  name: 'FilterServices',
  props: {
    items: {
      type: Array,
      default: () => Array<Service>()
    }
  },
  data () {
    return {
      loading: false,
      selectedIndexes: Array<number>(),
      ignoreChange: false
    }
  },
  computed: {
    ...mapGetters({
      selectedFilters: 'booking/getSelectedFilters'
    })
  },
  watch: {
    selectedIndexes () {
      this.onChange()
    }
  },
  created () {
    this.selectFilters()
  },
  methods: {
    ...mapActions({
      setSelectedFiltersForServices: 'booking/setSelectedFiltersForServices'
    }),
    onChange () {
      if (!this.ignoreChange) {
        const result = Array<Service>()
        this.selectedIndexes.forEach((index: number) => {
          result.push(this.items[index] as Service)
        })

        this.setSelectedFiltersForServices(result)
      } else {
        this.ignoreChange = false
      }
    },
    selectFilters () {
      if (this.selectedFilters && this.selectedFilters.services) {
        const selectedIndexes = Array<number>()
        this.selectedFilters.services.forEach((selectedService: Service) => {
          const index = (this.items as Service[]).findIndex((service: Service) => service.id === selectedService.id)
          selectedIndexes.push(index)
        })

        this.ignoreChange = true
        this.selectedIndexes = selectedIndexes
      }
    }
  }
})
