export enum DialogTypes {
  NONE,
  LOGIN,
  SIGN_UP,
  FORGOTTEN_PASSWORD,
  CONFIRMATION_DIALOG,
  CREATE_BOOKING,
  ACCOUNT_ACTIVATION_REQUEST,
  RESET_PASSWORD,
  BOOKING_DIALOG_1,
  BOOKING_FORM_DIALOG,
  BOOKING_FORM_CONFIRMATION_DIALOG,
  BOOKING_SERVICE_DESCRIPTION_DIALOG,
  SIMPLE_STORE_PRODUCT_CONFIRMATION_DIALOG,
  BOOKING_CALENDAR_SERVICE_TYPES_DIALOG
}
