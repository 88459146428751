import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { Preview } from './types'
import { RootState } from '../../types'
import PreviewAsset from '@/calendesk/models/PreviewAsset'

export const state: Preview = {
  previewAsset: new PreviewAsset()
}

const namespaced = true

export const preview: Module<Preview, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
