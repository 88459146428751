import { trans } from '../prototypes/trans'

export const renderDuration = (value: number): string => {
  if (!value) {
    return `0 ${trans('minutes')}`
  }

  const days = Math.floor(value / 1440)
  const hours = Math.floor((value - (days * 1440)) / 60)
  const minutes = Math.round(value % 60)

  if (days > 0) {
    if (hours > 0 || minutes > 0) {
      return `${days} ${trans('days')} ${hours} ${trans('hours')} ${minutes} ${trans('minutes')}`
    }

    return `${days} ${trans('days')}`
  } else if (hours > 0) {
    if (minutes > 0) {
      return `${hours} ${trans('hours')} ${minutes} ${trans('minutes')}`
    }

    return `${hours} ${trans('hours')}`
  } else {
    return `${minutes} ${trans('minutes')}`
  }
}
