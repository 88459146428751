











import CalendeskInformationMessage from '@/components/shared/CalendeskInformationMessage.vue'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'

export default mixins(DraftElement).extend({
  name: 'CalendeskWarningMessage',
  components: {
    CalendeskInformationMessage
  }
})
