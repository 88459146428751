import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import CtaButtonData from '@/calendesk/models/CtaButtonData'
import { plainToClass } from 'class-transformer'
import { AlignType } from '@/calendesk/models/AlignType'
import { RoundType } from '@/calendesk/models/RoundType'
import { GalleryStyleType } from '@/calendesk/models/GalleryStyleType'

export default mixins(DraftElement).extend({
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    getImageWidth (): number | string {
      if (this.isMobile) {
        return '100%'
      }

      return (this.data.configuration.wb_image_width__number__ <= 1200)
        ? this.data.configuration.wb_image_width__number__ : 1200
    },
    getImageHeight (): number | null {
      return this.data.configuration.wb_image_height__number__
    },
    imageRoundType (): RoundType | null {
      if (this.data.configuration.wb_image_rounded__select__) {
        return this.data.configuration.wb_image_rounded__select__.value as RoundType
      }

      return null
    },
    viewRoundType (): RoundType | null {
      if (this.data.configuration.wb_view_rounded__select__) {
        return this.data.configuration.wb_view_rounded__select__.value as RoundType
      }

      return null
    },
    galleryStyle1 (): boolean {
      if (this.data.configuration.wb_gallery_style__select__) {
        return this.data.configuration.wb_gallery_style__select__.value === GalleryStyleType.STYLE_1
      }

      return false
    },
    galleryStyle2 (): boolean {
      if (this.data.configuration.wb_gallery_style__select__) {
        return this.data.configuration.wb_gallery_style__select__.value === GalleryStyleType.STYLE_2
      }

      return false
    },
    imagePositionType (): AlignType {
      return this.data.configuration.wb_image_position__select__.value as AlignType
    },
    buttonsPositionType (): AlignType {
      return this.data.configuration.wb_buttons_position__select__.value as AlignType
    },
    formPositionType (): AlignType | null {
      if (this.data.configuration.wb_form_position__select__) {
        return this.data.configuration.wb_form_position__select__.value as AlignType
      }

      return null
    },
    formOnRightSide (): boolean {
      return this.formPositionType === AlignType.TOP_RIGHT
    },
    imageOnTop (): boolean {
      return this.imagePositionType === AlignType.TOP_RIGHT || this.imagePositionType === AlignType.TOP_LEFT || this.imagePositionType === AlignType.TOP_CENTER
    },
    imageOnRightSide (): boolean {
      return this.imagePositionType === AlignType.TOP_RIGHT || this.imagePositionType === AlignType.BOTTOM_RIGHT
    },
    imageOnCenter (): boolean {
      return this.imagePositionType === AlignType.TOP_CENTER || this.imagePositionType === AlignType.BOTTOM_CENTER
    },
    imageHidden (): boolean {
      return !!this.data.configuration.wb_image_hide__checkbox__
    },
    textHidden (): boolean {
      return !!this.data.configuration.wb_text_hide__checkbox__
    },
    locationAlwaysSelected (): boolean {
      // Can be undefined or true, false will disable it for sure
      return this.data.configuration.wb_initial_location_select__checkbox__ !== false
    },
    isSettingRandomEmployeeSupported () {
      return !!this.data.configuration.wb_any_employee_enabled__checkbox__
    },
    classForImagePosition (): string {
      switch (this.imagePositionType) {
        case AlignType.TOP_LEFT:
        case AlignType.BOTTOM_LEFT:
          return 'float-left'
        case AlignType.TOP_RIGHT:
        case AlignType.BOTTOM_RIGHT:
          return 'float-right'
        case AlignType.TOP_CENTER:
        case AlignType.BOTTOM_CENTER:
          return 'align-center'
      }
    },
    classForButtonsPosition (): string {
      switch (this.buttonsPositionType) {
        case AlignType.TOP_LEFT:
        case AlignType.BOTTOM_LEFT:
          return 'justify-start'
        case AlignType.TOP_RIGHT:
        case AlignType.BOTTOM_RIGHT:
          return 'justify-end'
        case AlignType.TOP_CENTER:
        case AlignType.BOTTOM_CENTER:
          return 'justify-center'
      }
    },
    classForImageRound (): string {
      switch (this.imageRoundType) {
        case RoundType.ZERO:
          return 'rounded-0'
        case RoundType.SM:
          return 'rounded-sm'
        case RoundType.MD:
          return 'rounded'
        case RoundType.LG:
          return 'rounded-lg'
        case RoundType.XL:
          return 'rounded-xl'
        case RoundType.PILL:
          return 'rounded-pill'
        case RoundType.CIRCLE:
          return 'rounded-circle'
        default:
          return 'rounded-0'
      }
    },
    classForViewRound (): string {
      switch (this.viewRoundType) {
        case RoundType.ZERO:
          return 'rounded-0'
        case RoundType.SM:
          return 'rounded-sm'
        case RoundType.MD:
          return 'rounded'
        case RoundType.LG:
          return 'rounded-lg'
        case RoundType.XL:
          return 'rounded-xl'
        case RoundType.PILL:
          return 'rounded-pill'
        case RoundType.CIRCLE:
          return 'rounded-circle'
        default:
          return 'rounded-0'
      }
    },
    isMobile (): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    getCtaButton (): CtaButtonData | null {
      if (this.data.configuration.wb_button__cta_button__) {
        return this.transformCtaButton(this.data.configuration.wb_button__cta_button__)
      }

      return null
    },
    hasCtaButtons (): boolean {
      return (this.data.configuration.wb_buttons__array_cta_buttons__ &&
        this.data.configuration.wb_buttons__array_cta_buttons__.length > 0)
    },
    invertColors (): boolean {
      return !!this.data.configuration.wb_invert_colors__checkbox__
    },
    textColor1 () {
      if (this.invertColors) {
        return this.draftConfiguration.wb_color_text_2__color__
      } else {
        return this.draftConfiguration.wb_color_text__color__
      }
    },
    textColor2 () {
      if (this.invertColors) {
        return this.draftConfiguration.wb_color_text__color__
      } else {
        return this.draftConfiguration.wb_color_text_2__color__
      }
    },
    textColorVar () {
      if (this.invertColors) {
        return 'wb_color_text_2__color__'
      } else {
        return 'wb_color_text__color__'
      }
    },
    backgroundColorVar () {
      if (this.invertColors) {
        return 'wb_color_bg_2__color__'
      } else {
        return 'wb_color_bg__color__'
      }
    },
    backgroundColor1 () {
      if (this.invertColors) {
        return this.draftConfiguration.wb_color_bg_2__color__
      } else {
        return this.draftConfiguration.wb_color_bg__color__
      }
    },
    backgroundColor2 () {
      if (this.invertColors) {
        return this.draftConfiguration.wb_color_bg__color__
      } else {
        return this.draftConfiguration.wb_color_bg_2__color__
      }
    }
  },
  methods: {
    getButtonForId (id: string): CtaButtonData | null {
      // IE 11 hack, it doesn't support Object.values() this.data.configuration[id]
      const hasKey = Object.keys(this.data.configuration).some(key => this.data.configuration[key])
      if (hasKey) {
        return plainToClass(CtaButtonData, this.data.configuration[id] as CtaButtonData)
      }

      return null
    },
    transformCtaButton (plainButton: object): CtaButtonData {
      return plainToClass(CtaButtonData, plainButton as CtaButtonData)
    },
    getImageUrl (slug: string, placeholderForEmpty = false): string | null {
      const url = this.getImageUrlFromSection(this.data, slug)

      if (url) {
        return url
      } else if (!url && placeholderForEmpty) {
        return this.placeholderImageUrl
      }

      return null
    },
    backgroundImg (slug: string): Record<string, string> | null {
      const url = this.getImageUrl(slug)
      if (url) {
        return { backgroundImage: `url(${url})` }
      } else {
        return null
      }
    },
    backgroundImgWithMinHeight (slug: string) {
      const style = this.backgroundImg(slug) || {}
      style.minHeight = `${this.data.configuration.wb_height__style_height__}px`

      return style
    },
    getRandomItemFromSet (set: Set<any>) {
      const items = Array.from(set)
      return items[Math.floor(Math.random() * items.length)]
    },
    hasTextToDisplay (text: string | null) {
      return (text !== null && text !== '' && text !== ' ' && text !== '<p></p>')
    }
  }
})
