import { EventBus } from '@/calendesk/plugins/eventBus'

export async function verifyEmail (emailToVerify: string, headers: Record<string, any>) {
  const verificationToken: string|null = await new Promise((resolve, reject) => {
    EventBus.$emit(
      'EMAIL_EXTRA_VERIFICATION',
      resolve,
      reject,
      emailToVerify
    )
  })

  if (!verificationToken) {
    throw new Error('EXTRA_EMAIL_VERIFICATION_FAILED')
  }

  return {
    ...headers,
    'X-Verification-Token': verificationToken
  }
}
