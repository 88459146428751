import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import { Common } from './types'
import Snackbar from '@/calendesk/models/Snackbar'

export const getters: GetterTree<Common, RootState> = {
  get (state): Snackbar | null {
    return state.snackbar
  }
}
