import log from '@/calendesk/prototypes/log'
import store from '@/store/index'
import { trans } from '@/calendesk/prototypes/trans'
import Snackbar from '@/calendesk/models/Snackbar'
import Bugsnag from '@bugsnag/js'
import * as storage from '@/calendesk/tools/storage'

export const errorNotification = (message: string | null, details: any, report = true, timeout = 5000) => {
  message = message ? trans(message) : trans('error_occurred')

  const snackbar = new Snackbar(message as string, 'error', timeout)
  store.dispatch('snackbar/show', snackbar)

  if (details && details.response && details.response.data) {
    details = details.response.data
  }

  log.error('errorNotification', [message, details])

  if (report && process.env.NODE_ENV === 'production') {
    const tenant = store.getters['setup/getTenant'] || storage.local.getItem('tenant')
    const locale = storage.local.getItem('locale')

    Bugsnag.notify(new Error(JSON.stringify([message, details, tenant, locale, window.calendesk.version])))
  }
}

export const successNotification = (message: string, doNotTranslate = false) => {
  const text = doNotTranslate ? message : trans(message) as string
  const snackbar = new Snackbar(text, 'success')
  store.dispatch('snackbar/show', snackbar)
}
