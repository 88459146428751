






























































import Vue from 'vue'

import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'AppDialog',
  props: {
    componentList: {
      type: Array,
      required: true
    },
    isSecondDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      dialog: this.isSecondDialog ? this.$store.state.dialog.dialog2 : this.$store.state.dialog.dialog
    }
  },
  computed: {
    ...mapGetters({
      updateDialog: 'dialog/getDialog',
      updateDialog2: 'dialog/getDialog2',
      draftConfiguration: 'builder/getDraftConfiguration'
    }),
    getComponent (): any {
      const component = this.componentList.find((dialog: any) => dialog.type === this.dialog.type)
      return component || null
    }
  },
  watch: {
    'updateDialog.open' (): void {
      if (!this.isSecondDialog) {
        this.dialog = { ...this.updateDialog }
      }
    },
    'updateDialog.type' (): void {
      if (!this.isSecondDialog) {
        this.dialog = { ...this.updateDialog }
        this.loading = false
      }
    },
    'updateDialog2.open' (): void {
      if (this.isSecondDialog) {
        this.dialog = { ...this.updateDialog2 }
      }
    },
    'updateDialog2.type' (): void {
      if (this.isSecondDialog) {
        this.dialog = { ...this.updateDialog2 }
        this.loading = false
      }
    },
    'dialog.open' (): void {
      if (!this.isSecondDialog) {
        if (this.dialog.open !== this.updateDialog.open) {
          this.closeDialog()
        }
      } else {
        if (this.dialog.open !== this.updateDialog2.open) {
          this.closeDialog2()
        }
      }

      this.$nextTick(() => {
        if (this.dialog.open && window.self !== window.top) {
          // Website in an iframe, force showing scrolls.
          (this.$refs.appDialog as any).showScroll()
        }
      })
    }
  },
  methods: {
    ...mapActions({
      closeDialog: 'dialog/closeDialog',
      closeDialog2: 'dialog/closeDialog2'
    }),
    close () {
      if (this.isSecondDialog) {
        this.closeDialog2()
      } else {
        this.closeDialog()
      }
    }
  }
})
