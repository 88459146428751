










































































































































import mixins from 'vue-typed-mixins'
import DraftElement from '../../calendesk/sections/section/mixins/DraftElement'
import { mapActions } from 'vuex'
import { errorNotification, successNotification } from '@/calendesk/prototypes/notifications'
import UserPreferences from '@/calendesk/models/DTO/UserPreferences'

export default mixins(DraftElement).extend({
  name: 'UserPreferencesForm',
  data: () => ({
    isLoading: false,
    valid: false,
    email_notification_booking_created: false,
    email_notification_booking_updated: false,
    email_notification_booking_deleted: false,
    email_notification_booking_reminder: false,
    sms_notification_booking_created: false,
    sms_notification_booking_updated: false,
    sms_notification_booking_deleted: false,
    sms_notification_booking_reminder: false
  }),
  created () {
    if (this.user && this.user.preferences) {
      this.email_notification_booking_created = this.user.preferences.email_notification_booking_created
      this.email_notification_booking_updated = this.user.preferences.email_notification_booking_updated
      this.email_notification_booking_deleted = this.user.preferences.email_notification_booking_deleted
      this.email_notification_booking_reminder = this.user.preferences.email_notification_booking_reminder
      this.sms_notification_booking_created = this.user.preferences.sms_notification_booking_created
      this.sms_notification_booking_updated = this.user.preferences.sms_notification_booking_updated
      this.sms_notification_booking_deleted = this.user.preferences.sms_notification_booking_deleted
      this.sms_notification_booking_reminder = this.user.preferences.sms_notification_booking_reminder
    }
  },
  methods: {
    ...mapActions({
      updateUserPreferencesData: 'user/updateUserPreferencesData'
    }),
    submitHandle () {
      (this.$refs.form as any).validate()
      if (!this.valid) {
        errorNotification('form_is_invalid', null, false)
        return
      }

      this.isLoading = true
      const requestData = new UserPreferences(
        this.email_notification_booking_created,
        this.email_notification_booking_updated,
        this.email_notification_booking_deleted,
        this.email_notification_booking_reminder,
        this.sms_notification_booking_created,
        this.sms_notification_booking_updated,
        this.sms_notification_booking_deleted,
        this.sms_notification_booking_reminder
      )

      this.updateUserPreferencesData(requestData)
        .then(() => {
          successNotification('profile_update_success')
        }).catch((error) => {
          errorNotification(null, error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
})
