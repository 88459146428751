import { MutationTree } from 'vuex'
import { BuilderState } from './types'
import Draft from '@/calendesk/models/DTO/Response/Draft'
import Page from '@/calendesk/models/DTO/Response/Page'

const setMetaTagContent = (selector: string, content: string) => {
  document.querySelector(selector)?.setAttribute('content', content)
}

const setLinkHref = (selector: string, href: string) => {
  document.querySelector(selector)?.setAttribute('href', href)
}

export const mutations: MutationTree<BuilderState> = {
  SET_DRAFT (state, payload: Draft) {
    state.draft = payload

    // The meta tags will be used by external service which delivers generated html page to bots (google, fb, twitter etc.)
    let description = ''
    if (state.draft.configuration && state.draft.configuration.wb_website_description__long_text__) {
      description = state.draft.configuration.wb_website_description__long_text__
    }

    let ogImageURL = 'https://media.calendesk.com/demo/og-en-1.jpg'
    if (state.draft.configuration && state.draft.configuration.wb_og_image_url__text__) {
      ogImageURL = state.draft.configuration.wb_og_image_url__text__
    }

    let faviconURL = 'data:image/png;base64,iVBORw0KGgo='
    if (state.draft.configuration && state.draft.configuration.wb_favicon_url__text__) {
      faviconURL = state.draft.configuration.wb_favicon_url__text__
    }

    ['meta[name="description"]', 'meta[property="og:description"]', 'meta[property="twitter:description"]'].forEach(selector => {
      setMetaTagContent(selector, description)
    })

    const ogImages = ['meta[property="og:image"]', 'meta[property="twitter:image"]']
    ogImages.forEach(selector => {
      setMetaTagContent(selector, ogImageURL)
    })

    setLinkHref("link[rel*='icon']", faviconURL)
  },
  SET_CURRENT_PAGE (state, page: Page) {
    state.currentPage = page

    // The meta tags will be used by external service which delivers generated html page to bots (google, fb, twitter etc.)
    const url = 'https://' + window.location.host + '/' + state.currentPage.route
    const title = state.currentPage.name
    document.title = title
    document.querySelector('meta[name="title"]')?.setAttribute('content', state.currentPage.name)

    const description = state.currentPage.configuration?.description ||
      (state.draft?.configuration?.wb_website_description__long_text__ || '')

    const metaDescriptions = ['meta[name="description"]', 'meta[property="og:description"]', 'meta[property="twitter:description"]']
    metaDescriptions.forEach(selector => {
      setMetaTagContent(selector, description)
    })

    const metaTitles = ['meta[property="twitter:title"]', 'meta[property="og:title"]']
    metaTitles.forEach(selector => {
      setMetaTagContent(selector, title)
    })

    const metaUrls = ['meta[property="twitter:url"]', 'meta[property="og:url"]']
    metaUrls.forEach(selector => {
      setMetaTagContent(selector, url)
    })
  },
  SET_SECTIONS_LOADER_VISIBILITY (state, loader) {
    state.sectionsLoaderVisibility = loader
  },
  SET_INITIAL_LOADING (state, initialLoading) {
    state.initialLoading = initialLoading
  }
}
