import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import APIClient from '@/calendesk/api/APIClient'
import { SubscriptionState } from '@/store/modules/subscription/types'
import AvailableSubscriptionsRequestData from '@/calendesk/models/DTO/Request/AvailableSubscriptionsRequestData'
import { plainToClass } from 'class-transformer'
import Subscription from '@/calendesk/models/DTO/Response/Subscription'
import UserSubscriptionsRequestData from '@/calendesk/models/DTO/Request/UserSubscriptionsRequestData'
import UserSubscriptionPaymentTransactionsRequestData
  from '@/calendesk/models/DTO/Request/UserSubscriptionPaymentTransactionsRequestData'
import UserSubscription from '@/calendesk/models/DTO/Response/UserSubscription'
import UserSubscriptionPaymentTransactionResponse
  from '@/calendesk/models/DTO/Response/UserSubscriptionPaymentTransactionResponse'
import UserSubscriptionsResponse from '@/calendesk/models/DTO/Response/UserSubscriptionsResponse'
import PayForBookingsWithUserSubscriptionRequestData
  from '@/calendesk/models/DTO/Request/PayForBookingsWithUserSubscriptionRequestData'

export const actions: ActionTree<SubscriptionState, RootState> = {
  fetchAvailableSubscriptions ({ commit }, data: AvailableSubscriptionsRequestData): Promise<Subscription[]> {
    return new Promise((resolve, reject) => {
      APIClient.getAvailableSubscriptions(data).then(({ data }) => {
        const subscriptions = plainToClass(Subscription, data.data as Subscription[])
        commit('SET_AVAILABLE_SUBSCRIPTIONS', subscriptions)
        resolve(subscriptions)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  fetchSubscription (context, subscriptionId: number): Promise<Subscription> {
    return new Promise((resolve, reject) => {
      APIClient.getSubscription(subscriptionId).then(({ data }) => {
        resolve(plainToClass(Subscription, data))
      }).catch((error) => {
        reject(error)
      })
    })
  },
  payForBookingsWithUserSubscription (context, data: PayForBookingsWithUserSubscriptionRequestData): Promise<number[]> {
    return new Promise((resolve, reject) => {
      APIClient.payForBookingsWithUserSubscription(data).then(async ({ data }) => {
        if (typeof (window as any).calendeskUserSubscriptionPaidForBooking === 'function') {
          await (window as any).calendeskUserSubscriptionPaidForBooking(data.paid)
        }

        resolve(data.paid as number[])
      }).catch((error) => {
        reject(error)
      })
    })
  },
  cancelUserSubscription (context, subscriptionId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      APIClient.cancelUserSubscription(subscriptionId).then(() => {
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  fetchUserSubscriptions ({ commit }, data: UserSubscriptionsRequestData): Promise<UserSubscriptionsResponse> {
    return new Promise((resolve, reject) => {
      APIClient.getUserSubscriptions(data).then(({ data }) => {
        const response = plainToClass(UserSubscriptionsResponse, data as UserSubscriptionsResponse)

        const subscriptions = plainToClass(UserSubscription, data.data as UserSubscription[])
        commit('SET_USER_SUBSCRIPTIONS', subscriptions)

        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  fetchUserSubscriptionPaymentTransactions (context, data: UserSubscriptionPaymentTransactionsRequestData): Promise<UserSubscriptionPaymentTransactionResponse> {
    return new Promise((resolve, reject) => {
      APIClient.getUserSubscriptionPaymentTransactions(data).then(({ data }) => {
        const response = plainToClass(UserSubscriptionPaymentTransactionResponse, data as UserSubscriptionPaymentTransactionResponse)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  setReloadAllSubscriptions ({ commit }, reload: boolean) {
    commit('SET_RELOAD_ALL_SUBSCRIPTIONS', reload)
  }
}
