import { Exclude, Expose } from 'class-transformer'

@Exclude()
export class PaginatedResponse {
  @Expose({ name: 'current_page' })
  public currentPage!: number

  @Expose()
  public from!: number

  @Expose()
  public to!: number

  @Expose({ name: 'per_page' })
  public perPage!: number

  @Expose({ name: 'last_page' })
  public lastPage!: number

  @Expose()
  public total!: number
}
