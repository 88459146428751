import { Expose, Type } from 'class-transformer'
import SubscriptionProductPrice from '@/calendesk/models/DTO/Response/SubscriptionProductPrice'

export default class BasicSubscription {
  public id!: number

  @Expose({ name: 'service_limit' })
  public serviceLimit!: number | null

  @Expose({ name: 'allow_users_to_cancel' })
  public allowUsersToCancel!: boolean | null

  public name!: string

  @Type(() => Number)
  @Expose({ name: 'service_ids' })
  public serviceIds!: Array<number> | null

  @Type(() => SubscriptionProductPrice)
  public price?: SubscriptionProductPrice | null
}
