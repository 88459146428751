





















import mixins from 'vue-typed-mixins'
import Section from '@/calendesk/sections/section/mixins/Section'

export default mixins(Section).extend({
  props: {
    title: {
      type: String
    },
    showButton: {
      type: Boolean,
      default: false
    },
    buttonTitle: {
      type: String,
      default: null
    },
    buttonIcon: {
      type: String,
      default: null
    }
  },
  name: 'Subheader'
})
