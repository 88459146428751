import Bugsnag from '@bugsnag/js'
import store from '@/store'
import * as storage from '@/calendesk/tools/storage'

const debug = process.env.NODE_ENV !== 'production'

const log = {
  debug (message: string, data: any = null): void {
    if (!debug) {
      return
    }
    if (!data) {
      console.log(message)
      return
    }
    console.log(message, data)
  },
  info (message: string, data: any = null): void {
    if (!debug) {
      return
    }
    if (!data) {
      console.info(`[INFO] ${message}`)
      return
    }
    console.info(`[INFO] ${message}`, data)
  },
  warn (message: string, data: any = null): void {
    if (!debug) {
      return
    }
    if (!data) {
      console.warn(message)
      return
    }
    console.warn(message, data)
  },
  error (message: string, data: any = null): void {
    if (!data) {
      console.error(message)
      return
    }
    console.error(message, data)
  },
  notify (message: string | null, details: any) {
    if (process.env.NODE_ENV === 'production') {
      const tenant = store.getters['setup/getTenant'] || storage.local.getItem('tenant')
      const locale = storage.local.getItem('locale')

      Bugsnag.notify(new Error(JSON.stringify([message, details, tenant, locale, window.calendesk.version])))
    }
  }
}

export default log
