import { Expose } from 'class-transformer'

export default class SimpleStoreProduct {
  public id!: number
  public name!: string
  public price!: number

  @Expose({ name: 'additional_information' })
  public additionalInformation?: string|null = null

  @Expose({ name: 'gdpr_fields' })
  public gdprFields?: string|null = null

  @Expose({ name: 'created_at' })
  public createdAt!: string
}
