import { ConfirmDialogTypes } from './ConfirmDialogTypes'

const confirmDialogList: Array<Record<string, any>> = [
  {
    type: ConfirmDialogTypes.COMMON,
    content: () => import('./components/CommonConfirmDialog.vue')
  },
  {
    type: ConfirmDialogTypes.PAY_WITH_SUBSCRIPTION,
    content: () => import('./components/PayWithSubscriptionDialog.vue')
  }
]

export default confirmDialogList
