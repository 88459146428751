





































































































import VStripeCard from '@/calendesk/lib/VStripeElements/VStripeElements'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import { mapActions, mapGetters } from 'vuex'
import { maxChars, required } from '@/calendesk/forms/validators'
import DialogSize from '@/calendesk/models/DialogSize'
import { ConfirmDialogTypes } from '@/components/dialogs/ConfirmDialogTypes'
import ConfirmDialog from '@/calendesk/models/ConfirmDialog'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import StripeConnectSetupIntentRequestData from '@/calendesk/models/DTO/Request/StripeConnectSetupIntentRequestData'

export default mixins(DraftElement).extend({
  components: {
    VStripeCard
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    handleSave: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cardName: null,
      stripeCardData: null,
      isStoringMethod: false,
      rules: {
        required,
        maxChars
      },
      acceptedCards: {
        visa: 'visa',
        mastercard: 'mastercard',
        diners_club: 'diners_club',
        discover: 'discover',
        jcb: 'jcb',
        amex: 'amex'
      }
    }
  },
  computed: {
    ...mapGetters({
      stripeAccountId: 'stripe/getAccountId',
      stripe: 'stripe/getStripe'
    }),
    cardInfo (): string | null {
      if (this.user && this.user.cardBrand && this.user.cardLastFour) {
        return `•••• •••• •••• ${this.user.cardLastFour}`
      }

      return null
    },
    cardLogoURL (): string | null {
      if (this.isUserLogged &&
        this.user &&
        this.user.cardBrand &&
        this.user.cardBrand in this.acceptedCards) {
        return require(`@/assets/cards/${this.user.cardBrand}.png`)
      }

      return null
    },
    cardRules (): any {
      if (this.$refs.stripeCard && (this.$refs.stripeCard as any).internalValue) {
        return []
      }

      return [this.rules.required]
    },
    apiKey (): string {
      return process.env.VUE_APP_STRIPE_API_KEY
    },
    paymentMethodData (): any {
      return {
        payment_method: {
          card: (this.$refs.stripeCard as any).card,
          billing_details: {
            name: this.cardName
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      fetchUser: 'user/fetchData',
      createStripeCustomerForConnectedAccount: 'stripe/createStripeCustomerForConnectedAccount',
      getStripeConnectSetupIntentClientSecret: 'stripe/getStripeConnectSetupIntentClientSecret',
      attachPaymentMethodToStripeConnectCustomerAccount: 'stripe/attachPaymentMethodToStripeConnectCustomerAccount',
      detachPaymentMethodFromStripeConnectCustomerAccount: 'stripe/detachPaymentMethodFromStripeConnectCustomerAccount'
    }),
    deletePaymentMethod () {
      this.openConfirmDialog(
        new ConfirmDialog(true, ConfirmDialogTypes.COMMON, DialogSize.SMALL, {}, this.deletePaymentMethodHandler)
      )
    },
    async deletePaymentMethodHandler () {
      try {
        this.setConfirmDialogLoader(true)
        await this.detachPaymentMethodFromStripeConnectCustomerAccount()
        await this.fetchUser()
        this.stripeCardData = null
        this.cardName = null
      } catch (error) {
        errorNotification(null, error)
      }

      this.closeConfirmDialog()
      this.setConfirmDialogLoader(false)
    },
    async getCardSetup (): Promise<any | null> {
      const clientSecret = await this.getStripeConnectSetupIntentClientSecret(new StripeConnectSetupIntentRequestData())

      if (clientSecret) {
        return await (this.$refs.stripeCard as any).stripe.confirmCardSetup(clientSecret, this.paymentMethodData)
      }

      return null
    },
    async getPaymentMethod (): Promise<string | null> {
      const cardSetup = await this.getCardSetup()

      if (cardSetup && cardSetup.setupIntent && cardSetup.setupIntent.payment_method) {
        return cardSetup.setupIntent.payment_method
      }

      return null
    },
    async attachPaymentMethod (paymentMethodIdentifier: string, defaultPaymentMethod = false): Promise<any> {
      return this.attachPaymentMethodToStripeConnectCustomerAccount({
        payment_method_identifier: paymentMethodIdentifier,
        default: defaultPaymentMethod
      })
    },
    async save () {
      try {
        if (this.cardName && this.stripeCardData && !this.user.cardBrand) {
          this.isStoringMethod = true

          if (!this.user.stripeId) {
            try {
              await this.createStripeCustomerForConnectedAccount()
            } catch (error) {
              errorNotification('create_account_in_stripe_failed', error, false)
              return
            }
          }

          const paymentMethodId = await this.getPaymentMethod()
          if (paymentMethodId) {
            await this.attachPaymentMethod(paymentMethodId, true)
          } else {
            errorNotification('payment_method_setup_error', null, false)
          }

          await this.fetchUser()
        } else {
          errorNotification('fill_card_details', null, false)
        }
      } catch (error) {
        errorNotification(null, error)
      }

      this.isStoringMethod = false
    }
  }
})
