



















import Vue from 'vue'

export default Vue.extend({
  name: 'CalendeskInformationMessage',
  props: {
    html: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'info_light'
    },
    iconColor: {
      type: String,
      default: 'info'
    },
    icon: {
      type: String,
      default: '$info'
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    additionalClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    getClass (): string {
      return this.additionalClass
    }
  }
})
