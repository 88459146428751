import { Expose } from 'class-transformer'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'

export default class SectionImage {
  public id!: number | null
  public slug!: string
  public type!: SectionImageType

  @Expose({ name: 'section_id' })
  public sectionId!: number | null

  @Expose({ name: 'user_image_id' })
  public userImageId!: number | null

  public name!: string | null
}
