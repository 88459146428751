import Booking from '@/calendesk/models/DTO/Response/Booking'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import moment from 'moment'

export default mixins(DraftElement).extend({
  methods: {
    timeZone (booking: Booking): string {
      return this.isTimeZoneHidden ? this.appConfiguration.timeZone : booking.customerTimeZone || this.guessTimeZone()
    },
    getStartDate (booking: Booking): string {
      return moment(booking.startsAt).tz(this.timeZone(booking)).format(DateTimeFormats.FULL)
    },
    getEndDate (booking: Booking): string {
      return moment(booking.endsAt).tz(this.timeZone(booking)).format(DateTimeFormats.FULL)
    },
    displayStartDate (booking: Booking): string {
      return moment(booking.startsAt).tz(this.timeZone(booking)).format(DateTimeFormats.FULL_WITH_MONTH_TEXT)
    },
    displayEndDate (booking: Booking): string {
      return moment(booking.endsAt).tz(this.timeZone(booking)).format(DateTimeFormats.FULL_WITH_MONTH_TEXT)
    },
    getStartsAtTime (booking: Booking): string {
      return moment(booking.startsAt).tz(this.timeZone(booking)).format(DateTimeFormats.TIME)
    },
    getEndsAtTime (booking: Booking): string {
      return moment(booking.endsAt).tz(this.timeZone(booking)).format(DateTimeFormats.TIME)
    },
    isMultipleDays (booking: Booking): boolean {
      return moment(booking.startsAt).format(DateTimeFormats.FULL) !== moment(booking.endsAt).format(DateTimeFormats.FULL)
    }
  }
})
