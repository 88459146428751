import moment from 'moment'
import store from '@/store/index'

export default (time: string): string => {
  const timeNotation = store.getters['setup/getTimeNotation']

  if (timeNotation === '24h') {
    return time
  }

  return moment(time, 'hh:mm').format('h:mma')
}
