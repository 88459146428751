import { Type } from 'class-transformer'
import Service from '@/calendesk/models/DTO/Response/Service'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import DateRange from '@/calendesk/models/DTO/Response/DateRange'

export default class BookingFilter {
  @Type(() => Employee)
  public employees!: Array<Employee> | null

  @Type(() => Service)
  public services!: Array<Service> | null

  @Type(() => DateRange)
  public dates!: DateRange | null

  public constructor (employees: Array<Employee> | null, services: Array<Service> | null, dates: DateRange | null) {
    this.employees = employees
    this.services = services
    this.dates = dates
  }
}
