import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { BuilderState } from './types'
import { RootState } from '../../types'

export const state: BuilderState = {
  draft: null,
  currentPage: null,
  sectionsLoaderVisibility: false,
  initialLoading: true,
  pages: {}
}

const namespaced = true

export const builder: Module<BuilderState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
