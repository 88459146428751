








































import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import { mapActions, mapGetters } from 'vuex'
import Employee from '@/calendesk/models/DTO/Response/Employee'

export default mixins(DraftElement).extend({
  name: 'FilterEmployees',
  props: {
    availableEmployees: {
      type: Array,
      default: () => Array<Employee>()
    }
  },
  data () {
    return {
      loading: false,
      selectedIndexes: Array<number>(),
      ignoreChange: false
    }
  },
  computed: {
    ...mapGetters({
      selectedFilters: 'booking/getSelectedFilters'
    })
  },
  watch: {
    selectedIndexes () {
      this.onChange()
    }
  },
  created () {
    this.selectFilters()
  },
  methods: {
    ...mapActions({
      setSelectedFiltersForEmployees: 'booking/setSelectedFiltersForEmployees'
    }),
    onChange () {
      if (!this.ignoreChange) {
        const result = Array<Employee>()
        this.selectedIndexes.forEach((index: number) => {
          result.push(this.availableEmployees[index] as Employee)
        })

        this.setSelectedFiltersForEmployees(result)
      } else {
        this.ignoreChange = false
      }
    },
    selectFilters () {
      if (this.selectedFilters && this.selectedFilters.employees) {
        const selectedIndexes = Array<number>()
        this.selectedFilters.employees.forEach((selectedEmployee: Employee) => {
          const index = (this.availableEmployees as Employee[]).findIndex((employee: Employee) => employee.id === selectedEmployee.id)
          selectedIndexes.push(index)
        })

        this.ignoreChange = true
        this.selectedIndexes = selectedIndexes
      }
    }
  }
})
