export default class UserPreferences {
  public email_notification_booking_created: boolean
  public email_notification_booking_updated: boolean
  public email_notification_booking_deleted: boolean
  public email_notification_booking_reminder: boolean
  public sms_notification_booking_created: boolean
  public sms_notification_booking_updated: boolean
  public sms_notification_booking_deleted: boolean
  public sms_notification_booking_reminder: boolean

  constructor (email_notification_booking_created: boolean,
    email_notification_booking_updated: boolean,
    email_notification_booking_deleted: boolean,
    email_notification_booking_reminder: boolean,
    sms_notification_booking_created: boolean,
    sms_notification_booking_updated: boolean,
    sms_notification_booking_deleted: boolean,
    sms_notification_booking_reminder: boolean) {
    this.email_notification_booking_created = email_notification_booking_created
    this.email_notification_booking_updated = email_notification_booking_updated
    this.email_notification_booking_deleted = email_notification_booking_deleted
    this.email_notification_booking_reminder = email_notification_booking_reminder
    this.sms_notification_booking_created = sms_notification_booking_created
    this.sms_notification_booking_updated = sms_notification_booking_updated
    this.sms_notification_booking_deleted = sms_notification_booking_deleted
    this.sms_notification_booking_reminder = sms_notification_booking_reminder
  }
}
