import SectionImage from './SectionImage'
import { SectionType } from '@/calendesk/models/BuilderTypes'
import { Expose, plainToClass, Type } from 'class-transformer'

export default class Section {
  public id!: number | null
  public uuid!: string
  public type!: SectionType

  @Expose({ name: 'page_id' })
  public pageId!: number | null

  public configuration!: Record<string, any>

  @Type(() => SectionImage)
  public images!: Array<SectionImage>

  public static createEmptySection (pageId: number, position: number) {
    return plainToClass(Section, {
      id: 0,
      type: SectionType.EMPTY,
      pageId: pageId,
      configuration: { component_id: 1, wb_position: position },
      images: []
    })
  }
}
