import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { EmployeesSelect } from './types'
import APIClient from '@/calendesk/api/APIClient'
import { plainToClass } from 'class-transformer'
import Employee from '@/calendesk/models/DTO/Response/Employee'

export const actions: ActionTree<EmployeesSelect, RootState> = {
  fetch ({ commit }, data: Record<string, any>): Promise<Employee[]> {
    return new Promise((resolve, reject) => {
      APIClient.getEmployees(data)
        .then(({ data }) => {
          const employees = plainToClass(Employee, data.data as Employee[])
          commit('SET_EMPLOYEES', employees)
          resolve(employees)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
