import { Expose, Type } from 'class-transformer'
import { SubscriptionStatus } from '@/calendesk/models/DTO/Response/SubscriptionStatus'
import { UserSubscriptionStatus } from '@/calendesk/models/BuilderTypes'
import moment from 'moment/moment'
import BasicSubscription from '@/calendesk/models/DTO/Response/BasicSubscription'

export default class UserSubscription {
  public id!: number

  @Expose({ name: 'subscription_id' })
  public subscriptionId!: number

  @Expose({ name: 'stripe_subscription_id' })
  public stripeSubscriptionId!: number

  @Expose({ name: 'canceled_at' })
  public canceledAt!: string | null

  @Expose({ name: 'ends_at' })
  public endsAt!: string | null

  @Expose({ name: 'created_at' })
  public createdAt!: string

  public status!: SubscriptionStatus

  @Type(() => BasicSubscription)
  public subscription!: BasicSubscription

  @Expose({ name: 'current_period_usage' })
  public currentPeriodUsage!: number

  public isActive (): boolean {
    return this.status === UserSubscriptionStatus.ACTIVE ||
    (this.status === UserSubscriptionStatus.CANCELED && moment(this.endsAt).isAfter(moment()))
  }
}
