import { NavigationGuardNext, Route } from 'vue-router'
import store from '@/store'
import * as storage from '@/calendesk/tools/storage'
import { errorNotification } from '@/calendesk/prototypes/notifications'

function handleFinalRedirect (next: NavigationGuardNext, routeName: string | undefined | null = null) {
  store.commit('setup/SET_PRELOADER', false)
  if (routeName) {
    next({ name: routeName })
  } else {
    next()
  }
}

export default (to: Route, from: Route, next: NavigationGuardNext): void => {
  store.dispatch('setup/init')
    .then(() => {
      const protectedRouteWithUnauthenticatedUser =
        to.matched.some(m => m.meta.auth) && (!store.getters['user/getUser'] || !store.getters['user/isUserLogged'])

      if (protectedRouteWithUnauthenticatedUser) {
        handleFinalRedirect(next, 'home')
      } else {
        handleFinalRedirect(next)
      }
    })
    .catch(() => {
      if (process.env.VUE_APP_CALENDESK_ENV !== 'development') {
        storage.local.clear()
        errorNotification(null, null, false)
      }
    })
}
