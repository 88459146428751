import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import { EmployeesSelect } from './types'
import Employee from '@/calendesk/models/DTO/Response/Employee'

export const getters: GetterTree<EmployeesSelect, RootState> = {
  getEmployees (state): Employee[] | null {
    return state.employees
  }
}
