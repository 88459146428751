import { Expose } from 'class-transformer'

export default class SubscriptionProduct {
  public id!: number

  @Expose({ name: 'stripe_id' })
  public stripeId!: string

  public name!: string
}
