import router from '@/router'
import store from '@/store'
import { errorNotification } from '@/calendesk/prototypes/notifications'

export default class StaticPage {
  // Constants for UUIDs
  public static readonly BOOKINGS_UUID = '1c95a138-1800-4596-b6ab-abc5638c6921';
  public static readonly USER_SUBSCRIPTIONS_UUID = '23ccbbea-8ff1-47f9-98c9-c95253503ec0';
  public static readonly PAYMENTS_UUID = '08f22a76-41fb-4f6f-ba99-2ebd8e02de75';
  public static readonly MY_ACCOUNT_UUID = '920f1a9d-7b67-4285-8b23-5ec48f206ab9';

  // Constants for route names
  public static readonly BOOKINGS_ROUTE = 'bookings';
  public static readonly USER_SUBSCRIPTIONS_ROUTE = 'subscriptions';
  public static readonly PAYMENTS_ROUTE = 'payments';
  public static readonly MY_ACCOUNT_ROUTE = 'account';

  // Function to redirect to a route based on UUID
  public static redirectToUuid (uuid: string): void {
    let route: string

    switch (uuid) {
      case StaticPage.BOOKINGS_UUID:
        route = StaticPage.BOOKINGS_ROUTE
        break
      case StaticPage.USER_SUBSCRIPTIONS_UUID:
        route = StaticPage.USER_SUBSCRIPTIONS_ROUTE
        break
      case StaticPage.PAYMENTS_UUID:
        route = StaticPage.PAYMENTS_ROUTE
        break
      case StaticPage.MY_ACCOUNT_UUID:
        route = StaticPage.MY_ACCOUNT_ROUTE
        break
      default:
        throw new Error('Invalid UUID')
    }

    if (route && !store.getters['user/isUserLogged']) {
      errorNotification('subpage_auth_required', null, false)
      return
    }

    router.push({ name: route })
  }
}
