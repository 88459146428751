





























































































































































































































import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import { required, email, maxChars } from '@/calendesk/forms/validators'
import { mapActions, mapGetters } from 'vuex'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import CPhoneInput from '@/views/components/CPhoneInput.vue'
import { errorNotification, successNotification } from '@/calendesk/prototypes/notifications'
import UpdateUserRequestData from '@/calendesk/models/DTO/Request/UpdateUserRequestData'
import GetCompanyDataRequestData from '@/calendesk/models/DTO/Request/GetCompanyDataRequestData'
import CompanyData from '@/calendesk/models/DTO/Response/CompanyData'
import CCountryAutocomplete from '@/components/CCountryAutocomplete.vue'

export default mixins(DraftElement).extend({
  name: 'UserDataForm',
  components: {
    CCountryAutocomplete,
    CPhoneInput
  },
  data: () => ({
    dateFormat: DateTimeFormats,
    isLoading: false,
    menuDate: false,
    valid: false,
    displayDateOfBirth: null as string | null,
    dateOfBirth: null as string | null,
    defaultPhone: null as string | null,
    name: null as string | null,
    surname: null as string | null,
    email: null as string | null,
    maxDate: null as string | null,
    minDate: null as string | null,
    showBillingData: false,
    companyVatIsoPrefix: null as string | null,
    companyTaxNumber: null as string | null,
    companyName: null as string | null,
    companyStreet: null as string | null,
    companyPostalCode: null as string | null,
    companyCity: null as string | null,
    companyCountryIsoCode: null as string | null,
    isLoadingCompanyData: false,
    rules: {
      required,
      email,
      maxChars
    }
  }),
  mounted () {
    this.name = this.user.name
    this.surname = this.user.surname
    this.email = this.user.email
    this.defaultPhone = this.user.defaultPhone ? this.user.defaultPhone.e164 : ''
    this.dateOfBirth = this.user.dateOfBirth
    this.maxDate = this.$moment().subtract('15', 'years').format(this.dateFormat.FULL)
    this.minDate = this.$moment().subtract('80', 'years').format(this.dateFormat.FULL)

    if (this.user.hasDefaultAddress()) {
      this.showBillingData = true
      this.companyName = this.user.defaultAddress.name
      this.companyVatIsoPrefix = this.user.defaultAddress.vatIsoPrefix
      this.companyTaxNumber = this.user.defaultAddress.taxNumber
      this.companyStreet = this.user.defaultAddress.street
      this.companyCity = this.user.defaultAddress.city
      this.companyPostalCode = this.user.defaultAddress.postalCode
      this.companyCountryIsoCode = this.user.defaultAddress.countryIsoCode
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    })
  },
  watch: {
    menuDate (val) {
      val && setTimeout(() => ((this.$refs.picker as any).activePicker = 'YEAR'))
    },
    dateOfBirth (value: string) {
      if (value) {
        this.displayDateOfBirth = this.$moment(value).format(this.dateFormat.FULL_WITH_MONTH_TEXT)
      } else {
        this.displayDateOfBirth = null
      }
    }
  },
  methods: {
    ...mapActions({
      updateUser: 'user/updateData',
      getCompanyData: 'user/getCompanyData'
    }),
    profileDetailsSaveHandle () {
      (this.$refs.profileDetails as any).validate()
      if (!this.valid) {
        errorNotification('form_is_invalid', null, false)
        return
      }

      const requestData = new UpdateUserRequestData()
      requestData.name = this.name
      requestData.surname = this.surname
      requestData.email = this.email
      requestData.default_phone = this.defaultPhone
      requestData.date_of_birth = this.dateOfBirth

      if (this.showBillingData) {
        requestData.default_address = {
          name: this.companyName,
          street: this.companyStreet,
          postal_code: this.companyPostalCode,
          city: this.companyCity,
          country_iso_code: this.companyCountryIsoCode,
          vat_iso_prefix: this.companyVatIsoPrefix,
          tax_number: this.companyTaxNumber
        }
      } else {
        requestData.default_address = {
          name: null,
          street: null,
          postal_code: null,
          city: null,
          country_iso_code: null,
          vat_iso_prefix: null,
          tax_number: null
        }
      }

      this.isLoading = true
      this.updateUser(requestData)
        .then(() => {
          successNotification('profile_update_success')
        })
        .catch(error => {
          errorNotification(null, error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateDate () {
      this.menuDate = false;
      (this.$refs.menu as any).save(this.dateOfBirth)
    },
    getCompanyDataHandler () {
      this.isLoadingCompanyData = true

      this.getCompanyData(new GetCompanyDataRequestData(this.companyVatIsoPrefix, this.companyTaxNumber))
        .then((data: CompanyData) => {
          this.companyName = data.name
          this.companyStreet = data.street
          this.companyCity = data.city
          this.companyPostalCode = data.postalCode
          this.companyCountryIsoCode = data.countryIsoCode
        }).catch(() => {
          errorNotification('get_company_data_failed', null, false)
        }).finally(() => {
          this.isLoadingCompanyData = false
        })
    }
  }
})
