import { Expose, Type } from 'class-transformer'
import Booking from '@/calendesk/models/DTO/Response/Booking'

export default class PaymentTransaction {
  public id!: number

  @Expose({ name: 'provider_tag' })
  public providerTag!: string

  @Expose({ name: 'transaction_id' })
  public transactionId!: string

  @Expose({ name: 'fakturownia_invoice_id' })
  public fakturowniaInvoiceId!: string

  @Expose({ name: 'invoice_number' })
  public invoiceNumber!: string

  public status!: string

  @Expose({ name: 'paid_at' })
  public paidAt!: string

  public price!: number

  @Type(() => Booking)
  public booking!: Booking
}
