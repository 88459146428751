



























import { mapGetters } from 'vuex'
import Navbars from '@/calendesk/sections/navbar/Navbars'
import Footers from '@/calendesk/sections/footer/Footers'
import PreviewSection from '@/calendesk/models/PreviewSection'
import mixins from 'vue-typed-mixins'
import Renderer from '@/calendesk/mixins/Renderer'

export default mixins(Renderer).extend({
  name: 'Layout',
  computed: {
    ...mapGetters({
      navbar: 'builder/getNavbar',
      footer: 'builder/getFooter',
      isSectionsLoaderVisible: 'builder/isSectionsLoaderVisible'
    }),
    navbarComponent (): PreviewSection | null {
      return this.navbar ? this.createPreviewSection(this.navbar, Navbars) : null
    },
    footerComponent (): PreviewSection | null {
      return this.footer ? this.createPreviewSection(this.footer, Footers) : null
    }
  }
})
