
























































































































































































































































































































































































import mixins from 'vue-typed-mixins'
import parseCheckboxDescription from '@/calendesk/tools/parseCheckboxDescription'
import Renderer from '@/calendesk/mixins/Renderer'
import { mapActions, mapGetters } from 'vuex'
import Subscription from '@/calendesk/models/DTO/Response/Subscription'
import { errorNotification, successNotification } from '@/calendesk/prototypes/notifications'
import PaymentMethodForm from '@/components/PaymentMethodForm.vue'
import {
  email,
  lowercase,
  maxChars,
  minChars,
  number,
  required,
  specialChar,
  uppercase
} from '@/calendesk/forms/validators'
import CPhoneInput from '@/views/components/CPhoneInput.vue'
import CCountryAutocomplete from '@/components/CCountryAutocomplete.vue'
import StripeConnectSubscriptionData from '@/calendesk/models/DTO/Request/StripeConnectSubscriptionData'
import GdprFieldData from '@/calendesk/models/GdprFieldData'
import GdprFieldUserData from '@/calendesk/models/GdprFieldUserData'
import Page from '@/calendesk/models/DTO/Response/Page'
import { PageType } from '@/calendesk/models/BuilderTypes'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import CompanyDataActions from '@/calendesk/sections/section/shared/mixins/CompanyDataActions'

export default mixins(Renderer, CompanyDataActions).extend({
  name: 'Subscription',
  components: {
    CCountryAutocomplete,
    CPhoneInput,
    PaymentMethodForm
  },
  data () {
    return {
      show3DSDialog: false,
      paymentIntentClientSecret: null,
      stripe3DsSrc: null,
      isSettingUp: false,
      isLoading: false,
      formIsValid: false,
      subscription: null as Subscription | null,
      password: null as string | null,
      showPassword: false,
      promoCode: null,
      selectedGdprFields: [] as GdprFieldUserData[],
      rules: {
        required,
        email,
        minChars,
        maxChars,
        lowercase,
        uppercase,
        number,
        specialChar
      }
    }
  },
  mounted () {
    this.setPreloader(true)
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.receiveMessage)
  },
  computed: {
    ...mapGetters({
      stripe: 'stripe/getStripe'
    }),
    hasRecurringPrice (): boolean {
      if (this.subscription && this.subscription.price && !this.subscription.price.recurringInterval) {
        return false
      }

      return true
    },
    requireBillingData (): boolean {
      return !!(this.subscription && this.subscription.requireBillingData)
    }
  },
  methods: {
    ...mapActions({
      fetchUser: 'user/fetchData',
      fetchSubscription: 'subscription/fetchSubscription',
      getCompanyData: 'user/getCompanyData',
      signUpUserAndStoreSession: 'user/signUpUserAndStoreSession',
      updateUser: 'user/updateData',
      createStripeCustomerForConnectedAccount: 'stripe/createStripeCustomerForConnectedAccount',
      createUserSubscription: 'stripe/createUserSubscription',
      openDialog: 'dialog/openDialog'
    }),
    parseCheckboxDescription,
    receiveMessage (event: any) {
      if (event.data === '3DS-authentication-complete') {
        this.process3DsAuth()
      }
    },
    process3DsAuth () {
      this.close3DsFrame()

      if (this.paymentIntentClientSecret && this.stripe) {
        this.stripe.retrievePaymentIntent(this.paymentIntentClientSecret)
          .then((result: any) => {
            if (result.error) {
              this.isLoading = false
              errorNotification('general_payment_error', result.error)
            } else {
              if (result.paymentIntent.status === 'succeeded') {
                this.subscribeSuccess()
              } else {
                this.isLoading = false
                errorNotification('general_payment_error', result)
              }
            }
          })
      } else {
        this.isLoading = false
        errorNotification('general_payment_error', null, false)
      }
    },
    close3DsFrame () {
      this.show3DSDialog = false
      this.isLoading = false
    },
    open3DsFrame (data: any) {
      this.paymentIntentClientSecret = data.client_secret
      this.stripe3DsSrc = data.next_action.redirect_to_url.url
      this.show3DSDialog = true
    },
    gdprFieldChanged (element: GdprFieldData, selected: boolean) {
      const found = this.selectedGdprFields.find((field: GdprFieldUserData) => (field.name === element.name && field.label === element.label))

      if (found) {
        const index = this.selectedGdprFields.indexOf(found)
        this.selectedGdprFields.splice(index, 1)
      } else {
        const newElement = new GdprFieldUserData(element.name, element.label, element.required, selected)
        this.selectedGdprFields.push(newElement)
      }
    },
    auth3dsRedirectRequiredForData (data: any) {
      return !!(data && data.next_action && data.client_secret &&
        data.next_action.type && data.next_action.type === 'redirect_to_url' &&
        data.next_action.redirect_to_url && data.next_action.redirect_to_url.url)
    },
    async subscribeHandle () {
      (this.$refs.subscriptionForm as any).validate()

      if (this.formIsValid && this.subscription) {
        this.isLoading = true

        try {
          const payload: Record<string, any> = {
            name: this.name,
            surname: this.surname,
            email: this.email,
            default_phone: this.phone
          }

          if (!this.isUserLogged) {
            payload.password = this.password
          }

          if (this.showBillingData || this.requireBillingData) {
            payload.default_address = {
              name: this.billingDataTypeCompany ? this.companyName : null,
              street: this.companyStreet,
              postal_code: this.companyPostalCode,
              city: this.companyCity,
              country_iso_code: this.companyCountryIsoCode,
              vat_iso_prefix: this.billingDataTypeCompany ? this.companyVatIsoPrefix : null,
              tax_number: this.billingDataTypeCompany ? this.companyTaxNumber : null
            }
          }

          if (this.isUserLogged) {
            await this.updateUser(payload)
          } else {
            await this.signUpUserAndStoreSession(payload)
          }

          if (this.isUserLogged) {
            if (!this.user.stripeId) {
              try {
                await this.createStripeCustomerForConnectedAccount()
              } catch (error) {
                errorNotification('create_account_in_stripe_failed', error, false)
              }
            }

            if (this.user.stripeId) {
              if (this.hasRecurringPrice && !this.user.cardBrand) {
                const paymentMethodId = await (this.$refs.paymentMethodForm as any).getPaymentMethod()

                if (paymentMethodId) {
                  await (this.$refs.paymentMethodForm as any).attachPaymentMethod(paymentMethodId, true)
                } else {
                  errorNotification('payment_method_setup_error', null, false)
                  this.isLoading = false
                  return
                }
              }

              let gdprFields = null
              if (this.selectedGdprFields.length > 0) {
                gdprFields = JSON.stringify(this.selectedGdprFields)
              }

              const data = await this.createUserSubscription(new StripeConnectSubscriptionData(this.subscription.id, this.promoCode, gdprFields))
              const redirectRequire = this.auth3dsRedirectRequiredForData(data)
              if (redirectRequire) {
                this.open3DsFrame(data)
                await this.fetchUser()
                return
              } else {
                if (data && data.session_id) {
                  if (typeof (window as any).calendeskUserSubscriptionCreated === 'function') {
                    await (window as any).calendeskUserSubscriptionCreated(this.subscription, this.user)
                  }

                  await this.stripe.redirectToCheckout({
                    sessionId: data.session_id
                  }).then((result: any) => {
                    errorNotification(null, result)
                  })
                } else {
                  await this.subscribeSuccess()
                }
              }
            } else {
              errorNotification('create_account_in_stripe_failed', null, false)
            }
          }
        } catch (error) {
          if ((error.response && error.response.data && error.response.data.errors && error.response.data.errors.email)) {
            errorNotification('email_is_taken', error, false)
          } else if (error.response.data && error.response.data.code === 'PAYMENT_FAILED') {
            errorNotification('general_payment_error', error, false)
          } else if (error.response.data && error.response.data.code === 'STRIPE_CUSTOMER_MISSING_OR_INVALID') {
            errorNotification('stripe_customer_invalid', error, false)
          } else if (error.response.data && error.response.data.code === 'STRIPE_RESOURCE_MISSING_OR_INVALID') {
            errorNotification('stripe_resource_invalid', error, false)
          } else if (error.response.data && error.response.data.code === 'DISCOUNT_CODE_INVALID') {
            errorNotification('promo_code_invalid_message', error, false)
          } else {
            errorNotification(null, error)
          }
        }

        this.isLoading = false
      } else {
        errorNotification('form_is_invalid', null, false)
      }
    },
    async subscribeSuccess () {
      await this.fetchUser()

      if (typeof (window as any).calendeskUserSubscriptionCreated === 'function') {
        await (window as any).calendeskUserSubscriptionCreated(this.subscription, this.user)
      }

      if (this.subscription && this.subscription.successUrl) {
        window.location.href = this.subscription.successUrl
      } else {
        successNotification('subscription_set_successfully')
        this.redirectToMainPage()
      }

      this.isLoading = false
    },
    openLoginDialog (): void {
      this.openDialog2(new Dialog(true, DialogTypes.LOGIN, this.$trans('login'), DialogSize.MIDDLE, false, 'center'))
    }
  },
  created () {
    const page = new Page(
      null,
      null,
      this.$trans('subscriptions'),
      PageType.STATIC_PAGE,
      this.$router.currentRoute.name as string,
      null,
      true,
      null)
    this.setCurrentPage(page)

    this.isSettingUp = true
    this.fetchSubscription(this.$route.params.id).then((subscription: Subscription) => {
      this.subscription = subscription

      if (this.subscription.disableRobotIndexing) {
        document.querySelector('meta[name="robots"]')?.setAttribute('content', 'noindex')
      }

      if (this.subscription.onlyCompanyInvoiceDataType) {
        this.billingDataTypeForcedToCompany = true
      }
    }).catch((error) => {
      errorNotification(null, error, false)
    }).finally(() => {
      this.isSettingUp = false
      this.setPreloader(false)
    })
  }
})
