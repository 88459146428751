import { MutationTree } from 'vuex'
import { BookingSelect } from './types'
import Booking from '@/calendesk/models/DTO/Response/Booking'
import BookingFilter from '@/calendesk/models/DTO/Response/BookingFilter'
import BookingCreateRequestData from '@/calendesk/models/DTO/Request/BookingCreateRequestData'

export const mutations: MutationTree<BookingSelect> = {
  SET_BOOKINGS (state, payload: Booking[]) {
    state.bookings = payload
  },
  SET_RELOAD_ALL_BOOKINGS (state, payload: boolean) {
    state.reloadAllBookings = payload
  },
  SET_AVAILABLE_BOOKING_FILTERS (state, payload: BookingFilter) {
    state.availableBookingFilters = payload
  },
  SET_SELECTED_BOOKING_FILTERS (state, payload: BookingFilter) {
    state.selectedBookingFilters = payload
  },
  SET_BOOKING_TO_PROCESS (state, payload: BookingCreateRequestData) {
    state.bookingToProcess = payload
  }
}
