














import mixins from 'vue-typed-mixins'
import Navigation from '@/calendesk/mixins/Navigation'
import FilterBookings from '@/components/drawer/FilterBookings.vue'
import { SideDrawerTypes } from '@/calendesk/models/BuilderTypes'

export default mixins(Navigation).extend({
  components: { FilterBookings },
  data () {
    return {
      drawer: false,
      type: null,
      availableTypes: SideDrawerTypes
    }
  },
  watch: {
    drawer (value): void {
      if (!value) {
        this.closeSideDrawer()
      }
    },
    showSideDrawer (value: boolean): void {
      this.type = this.getSideDrawerType
      this.drawer = value
    }
  }
})
