var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subscriptions"},[_c('v-container',[_c('v-row',[_c('v-col',[_c('subheader',{attrs:{"title":_vm.$trans('subscriptions')}})],1)],1),_c('v-row',[_c('v-col',[(_vm.user && !_vm.user.emailVerifiedAt)?_c('v-container',{staticClass:"d-flex align-center pb-2 pt-2"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-center"},[_c('account-not-activated-info')],1)],1)],1):_vm._e(),_c('v-container',{staticClass:"subscriptions-wrapper"},[_c('v-row',{staticClass:"subscriptions-top"},[_c('v-col',{staticClass:"justify-center",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"user-subscriptions-table mb-4",attrs:{"loading":_vm.isLoading,"color":"primary","headers":_vm.headers,"footer-props":_vm.dataTableFooterProps,"items":_vm.userSubscriptions,"server-items-length":_vm.total,"no-results-text":_vm.$trans('nothing_found_here'),"hide-default-header":!_vm.userSubscriptions || _vm.userSubscriptions.length === 0,"hide-default-footer":!_vm.userSubscriptions || _vm.userSubscriptions.length === 0,"options":_vm.tableOptions},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('c-no-data-info',{attrs:{"description":_vm.$trans('no_subscriptions')}})]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.createdAt,_vm.dateFormat.FULL_WITH_MONTH_TEXT_AND_TIME)))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-medium my-2"},[_vm._v(_vm._s(item.subscription.name))]),(item.subscription && item.subscription.serviceIds && item.subscription.serviceIds.length > 0)?[_vm._l((_vm.activeServiceIds(item.subscription.serviceIds)),function(serviceId,index){return [(_vm.shouldShowService(index, item.id))?[(_vm.serviceName = _vm.getServiceNameForId(serviceId))?_c('v-chip',{key:serviceId,staticClass:"ma-1 ml-0",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.serviceName)+" ")]):_vm._e()]:_vm._e()]}),(_vm.activeServiceIds(item.subscription.serviceIds).length > _vm.maxNumberOfServicesShown && !_vm.isItemOpen(item.id))?[_c('div',{staticClass:"my-2"},[_c('button',{staticClass:"ml-2",on:{"click":function($event){return _vm.toggleItemOpen(item.id)}}},[_vm._v(" "+_vm._s(_vm.$trans('show_more'))+" (+"+_vm._s(_vm.activeServiceIds(item.subscription.serviceIds).length - _vm.maxNumberOfServicesShown)+")")])])]:_vm._e()]:_vm._e()]}},{key:"item.payment_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.subscription.price.unitAmount))),_c('br'),(item.subscription.price.recurringInterval)?_c('span',[_vm._v(" "+_vm._s(_vm.$trans('recurrent_fee_info'))+" "+_vm._s(_vm._f("plural")(item.subscription.price.intervalCount,item.subscription.price.recurringInterval, true))+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text ma-2",attrs:{"text":"","dense":"","color":_vm.getColorForStatus(item.status)}},[_c('span',[_vm._v(_vm._s(_vm.$trans('subscription_status_' + item.status)))])]),(item.status !== _vm.statuses.INITIATED)?[(item.endsAt)?_c('small',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.$trans('ends_at'))+": "+_vm._s(_vm._f("moment")(item.endsAt,_vm.dateFormat.FULL_WITH_MONTH_TEXT_AND_TIME))+" ")]):_vm._e(),(item.status === _vm.statuses.CANCELED && !item.endsAt)?_c('small',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.$trans('ends_at_default'))+" ")]):_vm._e()]:_vm._e()]}},{key:"item.usage",fn:function(ref){
var item = ref.item;
return [(_vm.canShowUsage(item))?_c('v-chip',{staticClass:"white--text ma-2",attrs:{"text":"","dense":"","color":"success2"}},[_c('span',[_vm._v(_vm._s(_vm.getUsage(item)))])]):_vm._e()]}},{key:"item.cancel",fn:function(ref){
var item = ref.item;
return [(item.subscription.allowUsersToCancel && item.status === _vm.statuses.ACTIVE)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.cancelUserSubscriptionClicked(item)}}},[_c('v-icon',{attrs:{"large":"","color":"error"}},[_vm._v(" $close ")])],1):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }