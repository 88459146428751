import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { SetupState } from './types'
import { RootState } from '../../types'
import * as storage from '@/calendesk/tools/storage'

export const state: SetupState = {
  appConfiguration: null,
  translations: null,
  preloader: true,
  tenant: null,
  draftUuid: null,
  regulations: null,
  privacyPolicy: null,
  timeNotation: storage.local.getItem('timeNotation') || '24h',
  initialSetup: false
}

const namespaced = true

export const setup: Module<SetupState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
