import log from '@/calendesk/prototypes/log'
import store from '@/store/index'

export const trans = (key: string, attributes: Record<string, string> | null = null) => {
  const translations = { ...store.state.setup.translations }

  let trans = translations[key]

  if (window.replacedTranslations && window.replacedTranslations[key]) {
    trans = window.replacedTranslations[key]
  }

  if (trans) {
    if (attributes) {
      Object.keys(attributes).forEach(value => {
        trans = trans.replace('@{' + value + '}', attributes[value])
      })
    }

    return trans
  }

  log.warn('Translation missing!', key)
  return key
}
