export default class GdprFieldData {
  public name: string
  public label: string
  public required: boolean

  constructor (name: string, label: string, required: boolean) {
    this.name = name
    this.label = label
    this.required = required
  }
}
