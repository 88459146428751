import SubscriptionProduct from '@/calendesk/models/DTO/Response/SubscriptionProduct'
import { Expose, Type } from 'class-transformer'

export default class SubscriptionProductPrice {
  public id!: number

  @Expose({ name: 'stripe_product_id' })
  public stripeProductId!: number

  @Expose({ name: 'stripe_id' })
  public stripeId!: string

  public currency!: string

  @Expose({ name: 'recurring_interval' })
  public recurringInterval!: string | null

  @Expose({ name: 'interval_count' })
  public intervalCount!: number | null

  @Expose({ name: 'unit_amount' })
  public unitAmount!: number

  @Type(() => SubscriptionProduct)
  public product!: SubscriptionProduct
}
