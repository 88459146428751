import { GetterTree } from 'vuex'
import { SetupState } from './types'
import { RootState } from '../../types'
import Configuration from '@/calendesk/models/DTO/Response/Configuration'

export const getters: GetterTree<SetupState, RootState> = {
  getAppConfiguration (state): Configuration | null {
    return state.appConfiguration
  },
  getTranslations (state): object | null {
    return state.translations
  },
  getPreloader (state): boolean {
    return state.preloader
  },
  getTenant (state): string | null {
    return state.tenant
  },
  getDraftUuid (state): string | null {
    return state.draftUuid
  },
  getRegulations (state): string | null {
    return state.regulations
  },
  getPrivacyPolicy (state): string | null {
    return state.privacyPolicy
  },
  getTimeNotation (state): string {
    return state.timeNotation
  },
  getInitialSetup (state): boolean {
    return state.initialSetup
  }
}
