import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { refreshAuthLogic } from '@/calendesk/api/RefreshToken'

// Global hooks for request and response modifications
let globalBeforeRequestHook: ((config: AxiosRequestConfig) => AxiosRequestConfig) | null = null
let globalAfterResponseHook: ((response: AxiosResponse) => AxiosResponse) | null = null

export const httpClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 300000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json'
  }
})

// Add a global request interceptor
httpClient.interceptors.request.use(request => {
  // Apply the global beforeRequest hook if it exists
  if (globalBeforeRequestHook) {
    request = globalBeforeRequestHook(request)
  }

  return request
}, error => {
  return Promise.reject(error)
})

// Add a global response interceptor
httpClient.interceptors.response.use(response => {
  // Apply the global afterResponse hook if it exists
  if (globalAfterResponseHook) {
    response = globalAfterResponseHook(response)
  }

  return response
}, error => {
  return Promise.reject(error)
})

// Set up the authentication refresh interceptor
createAuthRefreshInterceptor(httpClient, refreshAuthLogic)

// Functions to set the global hooks
export function setBeforeRequestHook (hook: (config: AxiosRequestConfig) => AxiosRequestConfig) {
  globalBeforeRequestHook = hook
}

export function setAfterResponseHook (hook: (response: AxiosResponse) => AxiosResponse) {
  globalAfterResponseHook = hook
}

// Expose the hook functions globally
window.setBeforeRequestHook = setBeforeRequestHook
window.setAfterResponseHook = setAfterResponseHook
