import { trans } from '@/calendesk/prototypes/trans'

export const required = value => !!value || trans('field_is_required')
export const email = value => !value || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || trans('email_is_incorrect')
export const minChars = min => value => {
  if (!value || (min !== null && value.length >= min)) {
    return true
  }
  return `${trans('min_n_characters', { min: min })}`
}
export const maxChars = max => value => {
  if (!value || (max !== null && value.length <= max)) {
    return true
  }
  return `${trans('max_n_characters', { max: max })}`
}

export const equalsValue = requiredValue => value => {
  if (requiredValue === value) {
    return true
  }
  return `${trans('value_is_incorrect')}`
}

export const phone = value =>
  /^\+?([0-9]{2,3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})?[-. ]?([0-9]{3})$/.test(value) || trans('phone_is_invalid')
export const number = value => /\d/.test(value) || `${trans('no_numeric_in_password')}`
export const lowercase = value => /[a-z]/.test(value) || `${trans('no_lowercase_in_password')}`
export const uppercase = value => /[A-Z]/.test(value) || `${trans('no_uppercase_in_password')}`
export const specialChar = value => /\W/.test(value) || `${trans('no_special_character')}`
