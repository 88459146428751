import { ServiceLocationType } from '@/calendesk/models/DTO/Response/ServiceLocationType'
import { Expose } from 'class-transformer'

export default class ServiceLocation {
  public id!: number
  public type!: ServiceLocationType
  public data!: string | null
  public name!: string | null

  @Expose({ name: 'location_id' })
  public locationId!: number
}
