import { GetterTree } from 'vuex'
import { DrawerState } from './types'
import { RootState } from '../../types'
import { SideDrawerTypes } from '@/calendesk/models/BuilderTypes'

export const getters: GetterTree<DrawerState, RootState> = {
  showNavigationDrawer (state): boolean {
    return state.showNavigationDrawer
  },
  showSideDrawer (state): boolean {
    return state.showSideDrawer
  },
  sideDrawerType (state): SideDrawerTypes | null {
    return state.sideDrawerType
  }
}
