import { MutationTree } from 'vuex'
import { SubscriptionState } from './types'
import Subscription from '@/calendesk/models/DTO/Response/Subscription'
import UserSubscription from '@/calendesk/models/DTO/Response/UserSubscription'

export const mutations: MutationTree<SubscriptionState> = {
  SET_AVAILABLE_SUBSCRIPTIONS (state, payload: Subscription[]) {
    state.availableSubscriptions = payload
  },
  SET_USER_SUBSCRIPTIONS (state, payload: UserSubscription[]) {
    state.userSubscriptions = payload
  },
  SET_RELOAD_ALL_SUBSCRIPTIONS (state, payload: boolean) {
    state.reloadAllSubscriptions = payload
  }
}
