import { ActionTree } from 'vuex'
import { BuilderState } from './types'
import { RootState } from '../../types'
import api from '@/calendesk/api/APIClient'
import Page from '@/calendesk/models/DTO/Response/Page'
import { plainToClass } from 'class-transformer'
import Draft from '@/calendesk/models/DTO/Response/Draft'
import { PageType } from '@/calendesk/models/BuilderTypes'

export const actions: ActionTree<BuilderState, RootState> = {
  fetchDraft ({ rootGetters, commit }): Promise<Draft> {
    return new Promise((resolve, reject) => {
      api.getPublishedWebsite(rootGetters['setup/getDraftUuid'])
        ?.then(({ data }) => {
          const draft = plainToClass(Draft, data)
          commit('SET_DRAFT', draft)
          resolve(draft)
        })
        ?.catch(error => {
          reject(error)
        })
    })
  },

  setRequestedPage ({ dispatch, commit, state }, page: Page): Promise<Page> {
    return new Promise((resolve, reject) => {
      commit('SET_CURRENT_PAGE', page)

      const cachedPage: Page | null = state.pages[page.uuid as string]
      if (cachedPage) {
        commit('SET_CURRENT_PAGE', cachedPage)
        resolve(cachedPage)
        return
      }

      if (page.type === PageType.BLOG_HANDY) {
        resolve(page)
      } else {
        dispatch('fetchPage', page.id).then((page) => {
          commit('SET_CURRENT_PAGE', page)
          resolve(page)
        }).catch(error => {
          reject(error)
        })
      }
    })
  },

  fetchAllPages ({ dispatch }, draft: Draft): Promise<Page> {
    return new Promise((resolve, reject) => {
      const requests: Promise<any>[] = []

      draft.pages.forEach((page: Page) => {
        requests.push(dispatch('fetchPage', page.id))
      })

      Promise.allSettled(requests).then(() => {
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  fetchPage ({ state, rootGetters }, pageId: number): Promise<Page> {
    return new Promise((resolve, reject) => {
      api.getDraftPage(rootGetters['setup/getDraftUuid'], pageId as number).then(({ data }) => {
        const page = plainToClass(Page, data as Page)
        state.pages[page.uuid as string] = page
        resolve(page)
      })
        ?.catch(error => {
          reject(error)
        })
    })
  },

  setSectionsLoaderVisibility ({ commit }, loader: boolean): void {
    commit('SET_SECTIONS_LOADER_VISIBILITY', loader)
  },

  setInitialLoading ({ commit }, initialLoading: boolean): void {
    commit('SET_INITIAL_LOADING', initialLoading)
  },

  setCurrentPage ({ commit }, page: Page) {
    commit('SET_CURRENT_PAGE', page)
  }
}
