import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { ServiceState } from './types'
import APIClient from '@/calendesk/api/APIClient'
import { plainToClass } from 'class-transformer'
import Service from '@/calendesk/models/DTO/Response/Service'
import Category from '@/calendesk/models/DTO/Response/Category'

export const actions: ActionTree<ServiceState, RootState> = {
  fetchCategoryAndServices (
    { commit },
    data: Record<string, any>
  ): Promise<Category[]> {
    return new Promise((resolve, reject) => {
      APIClient.getCategoriesAndServices(data)
        .then(({ data }) => {
          const categories = plainToClass(Category, data as Category[])
          commit('SET_CATEGORY_AND_SERVICES', categories)

          const services: Service[] = []

          categories.forEach(category => {
            if (category.services != null) {
              services.push(...category.services)
            }
          })

          commit('SET_SERVICES', services)

          resolve(categories)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
