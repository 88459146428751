import 'reflect-metadata'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import beforeGuard from './router/guards/beforeGuard'
import './calendesk/filters/createFilters'
import moment from 'moment-timezone'
import VueMoment from 'vue-moment'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { trans } from '@/calendesk/prototypes/trans'
import { hexToRgba } from '@/calendesk/prototypes/hexToRgba'
import log from '@/calendesk/prototypes/log'
import './styles/index.scss'
import './styles/customHtmlSection.scss'

window.calendesk = {
  version: process.env.VUE_APP_APP_VERSION,
  env: process.env.NODE_ENV
}

Vue.prototype.$trans = trans
Vue.prototype.$hexToRgba = hexToRgba
Vue.prototype.$log = log

Vue.use(VueMoment, { moment })

Vue.config.productionTip = false

router.beforeEach(beforeGuard)

if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: '67b135675bd7dc4fe2e3405e4cd1cfdf',
    appVersion: window.calendesk.version,
    plugins: [new BugsnagPluginVue()],
    releaseStage: process.env.NODE_ENV
  })

  Bugsnag.getPlugin('vue')?.installVueErrorHandler(Vue)
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
