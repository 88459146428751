







































































import mixins from 'vue-typed-mixins'
import DraftElement from '../../calendesk/sections/section/mixins/DraftElement'
import { required, lowercase, uppercase, number, specialChar, minChars, maxChars } from '@/calendesk/forms/validators'
import { mapActions } from 'vuex'
import { errorNotification, successNotification } from '@/calendesk/prototypes/notifications'
import UpdateUserRequestData from '@/calendesk/models/DTO/Request/UpdateUserRequestData'

export default mixins(DraftElement).extend({
  name: 'ChangePasswordForm',
  data: () => ({
    isLoading: false,
    showOldPassword: false,
    showNewPassword: false,
    valid: false,
    form: {
      old_password: null,
      new_password: null,
      new_password_confirmation: null
    },
    rules: {
      required,
      minChars,
      maxChars,
      lowercase,
      uppercase,
      number,
      specialChar
    }
  }),
  methods: {
    ...mapActions({
      updateUser: 'user/updateData'
    }),
    changePasswordHandle () {
      (this.$refs.form as any).validate()
      if (!this.valid) {
        errorNotification('form_is_invalid', null, false)
        return
      }

      this.isLoading = true
      const requestData = new UpdateUserRequestData()
      requestData.old_password = this.form.old_password
      requestData.new_password = this.form.new_password
      requestData.new_password_confirmation = this.form.new_password

      this.updateUser(requestData)
        .then(() => {
          successNotification('password_changed')
        }).catch((error) => {
          errorNotification('incorrect_old_password', error, false)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
})
