


















































import Subheader from '@/views/components/Subheader.vue'
import mixins from 'vue-typed-mixins'
import Renderer from '@/calendesk/mixins/Renderer.ts'
import UserAvatar from '@/views/components/UserAvatar.vue'
import UserDataForm from '@/views/components/UserDataForm.vue'
import ChangePasswordForm from '@/views/components/ChangePasswordForm.vue'
import AccountNotActivatedInfo from '@/views/components/AccountNotActivatedInfo.vue'
import PaymentMethodForm from '@/components/PaymentMethodForm.vue'
import Page from '@/calendesk/models/DTO/Response/Page.ts'
import { PageType } from '@/calendesk/models/BuilderTypes.ts'
import UserPreferencesForm from '@/views/components/UserPreferencesForm.vue'

export default mixins(Renderer).extend({
  name: 'Account',
  components: { UserPreferencesForm, PaymentMethodForm, AccountNotActivatedInfo, ChangePasswordForm, UserDataForm, Subheader, UserAvatar },
  created () {
    const page = new Page(
      null,
      null,
      this.$trans('account'),
      PageType.STATIC_PAGE,
      this.$router.currentRoute.name as string,
      null,
      true,
      null)

    this.setCurrentPage(page)
  }
})
