import { DialogTypes } from '@/components/dialogs/DialogTypes'
import Dialog from '../models/Dialog'
import DialogSize from '../models/DialogSize'
import Page from '../models/DTO/Response/Page'
import mixins from 'vue-typed-mixins'
import Section from '@/calendesk/sections/section/mixins/Section'
import UserMenuItem from '@/calendesk/models/UserMenuItem'
import { mapActions } from 'vuex'

export default mixins(Section).extend({
  data () {
    return {
      userMenu: false,
      date: new Date().getFullYear()
    }
  },
  computed: {
    socialMedia (): Record<string, any> {
      return [
        { icon: '$fb', link: this.data.configuration.wb_facebook__url__ },
        { icon: '$instagram', link: this.data.configuration.wb_instagram__url__ },
        { icon: '$yt', link: this.data.configuration.wb_youtube__url__ },
        { icon: '$twitter', link: this.data.configuration.wb_twitter__url__ }
      ]
    },
    userMenuList (): Array<UserMenuItem> {
      const menu: Array<UserMenuItem> = []
      menu.push({ title: this.$trans('bookings'), icon: '$bookings', route: 'bookings' })

      if (this.hasUserSubscriptions) {
        menu.push({ title: this.$trans('subscriptions'), icon: '$subscriptions', route: 'subscriptions' })
      }

      const others: Array<UserMenuItem> = [
        { title: this.$trans('payments'), icon: '$payments', route: 'payments' },
        { title: this.$trans('account'), icon: '$account', route: 'account' },
        { divider: true },
        { title: this.$trans('logout'), icon: '$logout', action: this.logoutUser },
        { divider: true }
      ]

      return menu.concat(others)
    },
    authorizationButtons (): Record<string, any> {
      const buttons = []

      if (!this.isLoginHidden) {
        buttons.push({
          title: this.$trans('login'),
          icon: '$account',
          loginClass: true,
          key: 'top-login',
          dialogType: DialogTypes.LOGIN,
          color: 'wb_color_text__color__',
          borderOpacity: '0.16'
        })
      }

      if (!this.isSignupHidden) {
        buttons.push({
          title: this.$trans('sign_up'),
          icon: '$addUser',
          key: 'top-signup',
          dialogType: DialogTypes.SIGN_UP,
          color: 'wb_color_primary__color__',
          borderOpacity: '1'
        })
      }

      return buttons
    },
    hasAnyPages () {
      return this.navBarPageList.length > 0
    },
    navbarMainPages (): Array<Page> {
      const pages = this.navBarPageList.slice(0, this.data.configuration.wb_main_pages_number__number__)

      if (!this.isUserLogged) {
        return pages.filter((page) => !page.authOnly)
      }

      return pages
    },
    navbarAdditionalPages (): Array<Page> {
      if (this.navBarPageList.length > this.data.configuration.wb_main_pages_number__number__) {
        const pages = this.navBarPageList.slice(this.data.configuration.wb_main_pages_number__number__, this.navBarPageList.length)

        if (!this.isUserLogged) {
          return pages.filter((page) => !page.authOnly)
        }

        return pages
      }
      return []
    },
    getLogoSRC (): string | null {
      return this.getImageUrl('logo')
    }
  },
  methods: {
    ...mapActions({
      paymentsResetState: 'payments/resetState',
      bookingResetState: 'booking/resetState'
    }),
    openHandleDialog (menuItem: Record<string, any>): void {
      if (menuItem.dialogType === DialogTypes.LOGIN || menuItem.dialogType === DialogTypes.SIGN_UP) {
        this.openDialog2(new Dialog(true, menuItem.dialogType, menuItem.title, DialogSize.MIDDLE, false, 'center'))
      } else {
        this.openDialog(new Dialog(true, menuItem.dialogType, menuItem.title, DialogSize.MIDDLE, false, 'center'))
      }
    },
    openLoginDialog (): void {
      this.openDialog2(new Dialog(true, DialogTypes.LOGIN, this.$trans('login'), DialogSize.MIDDLE, false, 'center'))
    },
    logoutUser (): void {
      this.setPreloader(true)
      this.logoutUserAndDestroySession().finally(() => {
        this.paymentsResetState()
        this.bookingResetState()
        this.setPreloader(false)
        this.redirectToMainPage()
      })
    },
    handleMenuActionForItem (item: UserMenuItem): void {
      if (item.route && item.route !== this.$router.currentRoute.name) {
        this.$router.push({ name: item.route })
      } else if (item.action) {
        item.action()
      }
    }
  }
})
