import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { SubscriptionState } from './types'
import { RootState } from '../../types'

export const state: SubscriptionState = {
  availableSubscriptions: null,
  userSubscriptions: null,
  reloadAllSubscriptions: false
}

const namespaced = true

export const subscription: Module<SubscriptionState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
