import { ActionTree } from 'vuex'
import { DrawerState } from './types'
import { RootState } from '../../types'
import { SideDrawerTypes } from '@/calendesk/models/BuilderTypes'

export const actions: ActionTree<DrawerState, RootState> = {
  setNavigationDrawer ({ commit }, isOpen: boolean): void {
    commit('SET_SHOW_NAVIGATION_DRAWER', isOpen)
  },
  openSideDrawer ({ commit }, type: SideDrawerTypes) {
    commit('SET_SHOW_SIDE_DRAWER', true)
    commit('SET_SHOW_SIDE_DRAWER_TYPE', type)
  },
  closeSideDrawer ({ commit }) {
    commit('SET_SHOW_SIDE_DRAWER', false)
    commit('SET_SHOW_SIDE_DRAWER_TYPE', null)
  }
}
