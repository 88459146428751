import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { EmployeesSelect } from './types'
import { RootState } from '../../types'

export const state: EmployeesSelect = {
  employees: null
}

const namespaced = true

export const employee: Module<EmployeesSelect, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
