
























































































































































































































































import Subheader from '@/views/components/Subheader.vue'
import mixins from 'vue-typed-mixins'
import Renderer from '@/calendesk/mixins/Renderer'
import { mapActions, mapGetters } from 'vuex'
import Page from '@/calendesk/models/DTO/Response/Page'
import { PageType, SideDrawerTypes } from '@/calendesk/models/BuilderTypes'
import Service from '@/calendesk/models/DTO/Response/Service'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import BookingFiltersRequestData from '@/calendesk/models/DTO/Request/BookingFiltersRequestData'
import BookingsResponse from '@/calendesk/models/DTO/Response/BookingsResponse'
import CNoDataInfo from '@/components/CNoDataInfo.vue'
import AccountNotActivatedInfo from '@/views/components/AccountNotActivatedInfo.vue'
import Booking from '@/calendesk/models/DTO/Response/Booking'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import BookingActions from '@/views/components/mixins/BookingActions'
import DataTableFooter from '@/calendesk/models/DataTableFooter'
import PaymentActions from '@/views/components/mixins/PaymentActions'

export default mixins(Renderer, PaymentActions, BookingActions).extend({
  name: 'Bookings',
  components: {
    AccountNotActivatedInfo,
    Subheader,
    CNoDataInfo
  },
  data () {
    return {
      dataTableFooterProps: DataTableFooter,
      selectedBookings: [] as Booking[],
      showPromoCode: false,
      promoCode: null,
      isLoading: false,
      total: 0,
      bookings: [] as Booking[],
      tableOptions: {
        itemsPerPage: 25,
        page: 1,
        sortBy: ['start_date_time'],
        sortDesc: [true]
      }
    }
  },
  created () {
    const page = new Page(
      null,
      null,
      this.$trans('bookings'),
      PageType.STATIC_PAGE,
      this.$router.currentRoute.name as string,
      null,
      true,
      null)
    this.setCurrentPage(page)
  },
  computed: {
    ...mapGetters({
      shouldReload: 'booking/getReloadAllBookings',
      selectedFilters: 'booking/getSelectedFilters'
    }),
    cartPrice (): number {
      let price = 0
      if (this.selectedBookings && this.selectedBookings.length > 0) {
        this.selectedBookings.forEach((booking: Booking) => {
          price += booking.getPrice()
        })
      }

      return price
    },
    headers (): Array<Record<string, any>> {
      const headers = [
        {
          text: this.$trans('number'),
          value: 'id',
          sortable: true,
          class: 'header-style'
        },
        {
          text: this.$trans('date'),
          value: 'start_date_time',
          sortable: true,
          class: 'header-style'
        },
        {
          text: this.$trans('service'),
          value: 'service',
          sortable: false,
          class: 'header-style'
        },
        {
          text: this.$trans('payment_price'),
          value: 'price',
          sortable: false,
          class: 'header-style'
        }
      ]

      if (!this.areEmployeesHidden) {
        headers.push({
          text: this.$trans('employee'),
          value: 'employee',
          sortable: false,
          class: 'header-style'
        })
      }

      headers.push({
        text: this.$trans('status'),
        value: 'status',
        sortable: false,
        class: 'header-style'
      })

      headers.push({
        text: this.$trans('details'),
        value: 'details',
        sortable: false,
        class: 'header-style'
      })

      if (this.paymentsActive) {
        headers.push({
          text: this.$trans('bulk_payment'),
          value: 'payment',
          sortable: false,
          class: 'header-style'
        })
      }

      return headers
    }
  },
  watch: {
    shouldReload (reload) {
      if (reload) {
        this.tableOptions.page = 1
        this.reloadDataDebounced()
        this.setReloadAllBookings(false)
      }
    }
  },
  mounted () {
    this.reload()
    this.fetchBookingFilters()

    this.$watch('tableOptions', this.reloadDataDebounced)
  },
  methods: {
    ...mapActions({
      fetchBookings: 'booking/fetchAll',
      setReloadAllBookings: 'booking/setReloadAllBookings',
      fetchBookingFilters: 'booking/fetchFilters'
    }),
    getColorForStatus (status: string): string {
      if (status !== 'approved') {
        return 'warning'
      }

      return 'success2'
    },
    reloadDataDebounced () {
      this.debounce(this.reload, 400)()
    },
    reload () {
      this.isLoading = true

      this.fetchBookings(this.getFilters()).then((response: BookingsResponse) => {
        this.total = response.total
        this.bookings = response.data
        this.tableOptions.page = response.currentPage
      }).finally(() => {
        this.isLoading = false
      })
    },
    openFilters () {
      this.openSideDrawer(SideDrawerTypes.BOOKINGS_FILTER)
    },
    getFilters () {
      const filters = new BookingFiltersRequestData()

      if (this.selectedFilters) {
        if (this.selectedFilters.services && this.selectedFilters.services.length > 0) {
          filters.service_ids = this.selectedFilters.services.map((service: Service) => service.id).join()
        }

        if (this.selectedFilters.employees && this.selectedFilters.employees.length > 0) {
          filters.employee_ids = this.selectedFilters.employees.map((employee: Employee) => employee.id).join()
        }

        if (this.selectedFilters.dates) {
          filters.from = this.selectedFilters.dates.from
          filters.to = this.selectedFilters.dates.to
        }
      }

      filters.page = this.tableOptions.page
      filters.limit = this.tableOptions.itemsPerPage
      filters.order_by = this.tableOptions.sortBy[0] ? this.tableOptions.sortBy[0] : 'start_date_time'
      filters.ascending = this.tableOptions.sortDesc[0] ? 0 : 1

      return filters
    },
    openBooking (booking: Booking) {
      this.openDialog(new Dialog(
        true,
        DialogTypes.BOOKING_FORM_CONFIRMATION_DIALOG,
        null,
        DialogSize.MIDDLE,
        false,
        'center',
        {
          bookings: [booking],
          section: null
        }))
    },
    addToPaymentCart (booking: Booking) {
      this.selectedBookings.push(booking)
    },
    removeFromPaymentCart (booking: Booking) {
      this.selectedBookings = this.selectedBookings.filter((item: Booking) => !(item.id === booking.id))
    },
    isInPaymentCart (booking: Booking) {
      return !!this.selectedBookings.find((item) => item.id === booking.id)
    },
    payForSelectedBookings () {
      this.payBookings(this.selectedBookings, this.promoCode)
    }
  }
})
