import { sendRequest } from '@/calendesk/api/SendRequest'
import { tokenOperations } from '@/calendesk/prototypes/token'
import * as storage from '@/calendesk/tools/storage'

export function refreshToken (): Promise<any> {
  const data = {
    refresh_token: storage.local.getItem('refreshToken')
  }

  return sendRequest('POST', 'auth/token/refresh', data, false)
}

export function refreshAuthLogic (failedRequest: any) {
  return refreshToken().then(tokenRefreshResponse => {
    tokenOperations(tokenRefreshResponse.data)
    failedRequest.response.config.headers.Authorization = 'Bearer ' + tokenRefreshResponse.data.access_token
    return Promise.resolve()
  })
}
