import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { Common } from './types'
import Snackbar from '@/calendesk/models/Snackbar'

export const actions: ActionTree<Common, RootState> = {
  show ({ commit }, snackbar: Snackbar): void {
    commit('SET_SNACKBAR', snackbar)
  }
}
