






























































import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import { mapActions, mapGetters } from 'vuex'
import FilterServices from '@/components/drawer/components/filters/FilterServices.vue'
import FilterEmployees from '@/components/drawer/components/filters/FilterEmployees.vue'
import FilterDates from '@/components/drawer/components/filters/FilterDates.vue'

export default mixins(DraftElement).extend({
  name: 'FilterBookings',
  components: { FilterDates, FilterEmployees, FilterServices },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      availableBookingFilters: 'booking/getAvailableFilters'
    })
  },
  created () {
    if (!this.availableBookingFilters) {
      this.isLoading = true
      this.fetchBookingFilters().finally(() => {
        this.isLoading = false
      })
    }
  },
  methods: {
    ...mapActions({
      fetchBookingFilters: 'booking/fetchFilters',
      resetBookingFilters: 'booking/resetFilters'
    }),
    resetFilters () {
      this.resetBookingFilters()
      this.closeSideDrawer()
    }
  }
})
