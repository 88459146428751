import DialogSize from '@/calendesk/models/DialogSize'

export default class ConfirmDialog {
  public open: boolean;
  public type: number;
  public size: number;
  public data: Record<string, any>;
  public confirmHandler: Function;

  constructor (open = false, type = 0, size = DialogSize.SMALL, data: Record<string, any> = {}, confirmHandler: Function = () => {
    //
  }) {
    this.open = open
    this.type = type
    this.size = size
    this.data = data
    this.confirmHandler = confirmHandler
  }
}
