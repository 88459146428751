import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { BookingSelect } from './types'
import APIClient from '@/calendesk/api/APIClient'
import { plainToClass } from 'class-transformer'
import BookingFilter from '@/calendesk/models/DTO/Response/BookingFilter'
import Service from '@/calendesk/models/DTO/Response/Service'
import cloneClassObject from '@/calendesk/tools/cloneClassObject'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import DateRange from '@/calendesk/models/DTO/Response/DateRange'
import BookingsResponse from '@/calendesk/models/DTO/Response/BookingsResponse'
import BookingCreateRequestData from '@/calendesk/models/DTO/Request/BookingCreateRequestData'
import BookingFiltersRequestData from '@/calendesk/models/DTO/Request/BookingFiltersRequestData'
import Booking from '@/calendesk/models/DTO/Response/Booking'
import cleanObj from '@/calendesk/tools/cleanObj'
import BookingRescheduleRequestData from '@/calendesk/models/DTO/Request/BookingRescheduleRequestData'

export const actions: ActionTree<BookingSelect, RootState> = {
  resetState ({ commit }) {
    commit('SET_BOOKINGS', null)
    commit('SET_AVAILABLE_BOOKING_FILTERS', null)
    commit('SET_SELECTED_BOOKING_FILTERS', null)
    commit('SET_BOOKING_TO_PROCESS', null)
  },
  fetchAll ({ commit }, data: BookingFiltersRequestData): Promise<BookingsResponse> {
    return new Promise((resolve, reject) => {
      APIClient.getBookings(data)
        .then(({ data }) => {
          const bookingsResponse = plainToClass(BookingsResponse, data as BookingsResponse)
          commit('SET_BOOKINGS', bookingsResponse.data)
          resolve(bookingsResponse)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchBookingByControl (context, control: string): Promise<Booking> {
    return new Promise((resolve, reject) => {
      APIClient.getBookingByControl(control)
        .then(({ data }) => {
          const booking = plainToClass(Booking, data as Booking)
          resolve(booking)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchFilters ({ commit }): Promise<BookingFilter> {
    return new Promise((resolve, reject) => {
      APIClient.getBookingFilters()
        .then(({ data }) => {
          const bookingFilter = plainToClass(BookingFilter, data as BookingFilter)
          commit('SET_AVAILABLE_BOOKING_FILTERS', bookingFilter)
          resolve(bookingFilter)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchAvailableBookingSlots (context, data: Record<string, any>): Promise<Record<string, any>> {
    return new Promise((resolve, reject) => {
      APIClient.getAvailableBookingSlots(data)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  cancel (context, control: string): Promise<void> {
    return new Promise((resolve, reject) => {
      APIClient.cancelBooking(control)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  canBePaidWithSubscription (context, bookingId: number): Promise<Record<string, boolean>> {
    return new Promise((resolve, reject) => {
      APIClient.checkIfBookingCanBePaidWithSubscription(bookingId)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  reschedule (context, data: BookingRescheduleRequestData): Promise<Booking> {
    return new Promise((resolve, reject) => {
      APIClient.rescheduleBooking(data)
        .then(async ({ data }) => {
          const booking = plainToClass(Booking, data)

          if (typeof (window as any).calendeskBookingsRescheduled === 'function') {
            await (window as any).calendeskBookingsRescheduled(booking)
          }

          resolve(booking)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  create ({ rootGetters }, data: BookingCreateRequestData): Promise<Booking[]> {
    const isUserLogged = rootGetters['user/isUserLogged']

    return new Promise((resolve, reject) => {
      // Some properties cannot be sent to APIs even null.
      cleanObj(data)
      APIClient.createBooking(data, isUserLogged)
        .then(async ({ data }) => {
          const booking = plainToClass(Booking, data as Booking[])

          if (typeof (window as any).calendeskBookingsCreated === 'function') {
            await (window as any).calendeskBookingsCreated(booking)
          }

          resolve(booking)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  resetFilters ({ commit }) {
    commit('SET_SELECTED_BOOKING_FILTERS', null)
    commit('SET_RELOAD_ALL_BOOKINGS', true)
  },
  setReloadAllBookings ({ commit }, reload: boolean) {
    commit('SET_RELOAD_ALL_BOOKINGS', reload)
  },
  setSelectedFiltersForServices ({ state, commit, dispatch }, services: Array<Service>) {
    let bookingFilter: BookingFilter
    if (state.selectedBookingFilters) {
      bookingFilter = cloneClassObject(state.selectedBookingFilters)
      bookingFilter.services = services
    } else {
      bookingFilter = new BookingFilter(null, services, null)
    }

    commit('SET_SELECTED_BOOKING_FILTERS', bookingFilter)
    dispatch('setReloadAllBookings', true)
  },
  setSelectedFiltersForEmployees ({ state, commit, dispatch }, employees: Array<Employee>) {
    let bookingFilter: BookingFilter
    if (state.selectedBookingFilters) {
      bookingFilter = cloneClassObject(state.selectedBookingFilters)
      bookingFilter.employees = employees
    } else {
      bookingFilter = new BookingFilter(employees, null, null)
    }

    commit('SET_SELECTED_BOOKING_FILTERS', bookingFilter)
    dispatch('setReloadAllBookings', true)
  },
  setSelectedFiltersForDates ({ state, commit, dispatch }, dates: DateRange) {
    let bookingFilter: BookingFilter
    if (state.selectedBookingFilters) {
      bookingFilter = cloneClassObject(state.selectedBookingFilters)
      bookingFilter.dates = dates
    } else {
      bookingFilter = new BookingFilter(null, null, dates)
    }

    commit('SET_SELECTED_BOOKING_FILTERS', bookingFilter)
    dispatch('setReloadAllBookings', true)
  }
}
