import { NavbarSectionType } from '@/calendesk/models/BuilderTypes'
import SectionComponent from '@/calendesk/models/SectionComponent'

const navbars: Array<SectionComponent> = [
  {
    componentId: NavbarSectionType.NAVBAR1,
    component: () => import('./navbar1/Navbar1.vue')
  },
  {
    componentId: NavbarSectionType.NAVBAR2,
    component: () => import('./navbar2/Navbar2.vue')
  },
  {
    componentId: NavbarSectionType.NAVBAR3,
    component: () => import('./navbar3/Navbar3.vue')
  },
  {
    componentId: NavbarSectionType.NAVBAR4,
    component: () => import('./navbar4/Navbar4.vue')
  },
  {
    componentId: NavbarSectionType.NAVBAR5,
    component: () => import('./navbar5/Navbar5.vue')
  },
  {
    componentId: NavbarSectionType.NAVBAR6,
    component: () => import('./navbar6/Navbar6.vue')
  },
  {
    componentId: NavbarSectionType.NAVBAR7,
    component: () => import('./navbar7/Navbar7.vue')
  },
  {
    componentId: NavbarSectionType.FLEXIBLE_NAVBAR_1,
    component: () => import('./shared/FlexibleNavbar1.vue')
  }
]

export default navbars
