import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { StripeSelect } from './types'
import APIClient from '@/calendesk/api/APIClient'
import BookingCreatePaymentRequestData from '@/calendesk/models/DTO/Request/BookingCreatePaymentRequestData'
import SimpleStoreProductCreatePaymentRequestData
  from '@/calendesk/models/DTO/Request/SimpleStoreProductCreatePaymentRequestData'
import CreatePaymentForBookingsRequestData from '@/calendesk/models/DTO/Request/CreatePaymentForBookingsRequestData'
import PaymentMethodRequestData from '@/calendesk/models/DTO/Request/PaymentMethodRequestData'
import User from '@/calendesk/models/DTO/Response/User'
import { plainToClass } from 'class-transformer'
import StripeConnectSubscriptionData from '@/calendesk/models/DTO/Request/StripeConnectSubscriptionData'
import StripeConnectSetupIntentRequestData from '@/calendesk/models/DTO/Request/StripeConnectSetupIntentRequestData'

export const actions: ActionTree<StripeSelect, RootState> = {
  init ({ commit }): Promise<void> {
    return new Promise((resolve, reject) => {
      APIClient.getStripeAccount()
        .then(({ data }) => {
          if (data.stripe_account_id && data.charges_enabled) {
            const stripe = new Stripe(process.env.VUE_APP_STRIPE_API_KEY as string, {
              stripeAccount: data.stripe_account_id
            })
            commit('SET_STRIPE', stripe)
            commit('SET_STRIPE_ACCOUNT_ID', data.stripe_account_id)
          } else {
            commit('SET_STRIPE', null)
            commit('SET_STRIPE_ACCOUNT_ID', null)
          }

          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  createPaymentForBooking (context, data: BookingCreatePaymentRequestData): Promise<string> {
    return new Promise((resolve, reject) => {
      APIClient.createPaymentForBooking(data).then(({ data }) => {
        resolve(data.session_id as string)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  createStripeCustomerForConnectedAccount ({ commit }): Promise<User> {
    return new Promise((resolve, reject) => {
      APIClient.createStripeCustomerForConnectedAccount().then(({ data }) => {
        const user = plainToClass(User, data)
        commit('user/SET_USER', user, { root: true })
        resolve(user)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  createUserSubscription (context, payload: StripeConnectSubscriptionData): Promise<any> {
    return new Promise((resolve, reject) => {
      APIClient.createUserSubscription(payload).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  createPaymentForBookings (context, data: CreatePaymentForBookingsRequestData): Promise<string> {
    return new Promise((resolve, reject) => {
      APIClient.createPaymentForBookings(data).then(({ data }) => {
        resolve(data.session_id as string)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  createPaymentForSimpleStoreProduct ({ rootGetters }, data: SimpleStoreProductCreatePaymentRequestData): Promise<string> {
    const isUserLogged = rootGetters['user/isUserLogged']

    return new Promise((resolve, reject) => {
      APIClient.createPaymentForSimpleStoreProduct(data, isUserLogged).then(({ data }) => {
        resolve(data.session_id as string)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getStripeConnectSetupIntentClientSecret (context, data: StripeConnectSetupIntentRequestData): Promise<string> {
    return new Promise((resolve, reject) => {
      APIClient.getStripeConnectSetupIntent(data).then(({ data }) => {
        resolve(data.client_secret as string)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  attachPaymentMethodToStripeConnectCustomerAccount (context, data: PaymentMethodRequestData): Promise<any> {
    return new Promise((resolve, reject) => {
      APIClient.attachPaymentMethodToStripeConnectCustomerAccount(data).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  detachPaymentMethodFromStripeConnectCustomerAccount (): Promise<any> {
    return new Promise((resolve, reject) => {
      APIClient.detachPaymentMethodFromStripeConnectCustomerAccount().then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}
