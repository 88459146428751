<template>
  <v-snackbar
    v-model="isVisible"
    color="info_light"
    multi-line
    timeout="-1"
    vertical
  >
    <v-icon
      class="mr-2"
      color="info"
    >
      $information
    </v-icon>
    <span class="black--text">{{ $trans('new_content_available') }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn
        v-bind="attrs"
        color="primary"
        text
        @click="clickAction"
      >
        {{ $trans('refresh_button_title') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import APIClient from '@/calendesk/api/APIClient'
import compareVersionNumbers from '@/calendesk/tools/compareVersionNumbers'

export default mixins(DraftElement).extend({
  name: 'ReloadSnackbar',
  props: {
    versionType: {
      type: String,
      default: 'wb_c_version'
    }
  },
  data () {
    return {
      isVisible: false,
      interval: null
    }
  },
  created () {
    this.fire()
  },
  methods: {
    fire () {
      APIClient.healthyCentral().then(({ data }) => {
        const fromHtml = compareVersionNumbers(data[this.versionType], window.APP_VERSION)
        const fromJs = compareVersionNumbers(data[this.versionType], window.calendesk.version)
        if (fromHtml > 0 || fromJs > 0) {
          this.isVisible = true
        }
      }).catch(() => {
        // silent
      })
      setTimeout(this.fire, 1800000)
    },
    clickAction () {
      window.location.reload(true)
    }
  }
})
</script>
