export default class Country {
  public name: string;
  public iso2: string;
  public iso2UpperCase: string;
  public dialCode: string;
  public priority = 0;
  public areaCodes: Array<string>;
  public showDialCode: string
  public examplePhoneNumber: string;

  constructor (name: string, iso2: string, dialCode: string, priority: number, areaCodes: Array<string>, showDialCode: string, examplePhoneNumber: string) {
    this.name = name
    this.iso2 = iso2
    this.iso2UpperCase = iso2.toUpperCase()
    this.dialCode = dialCode
    this.priority = priority
    this.areaCodes = areaCodes
    this.showDialCode = showDialCode
    this.examplePhoneNumber = examplePhoneNumber
  }
}
