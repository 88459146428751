import log from '@/calendesk/prototypes/log'
import * as storage from '@/calendesk/tools/storage'

export const resetApp = (message: string, forceReload = false) => {
  log.error(message)

  localStorage.clear()
  storage.local.clear()

  if (forceReload) {
    window.location.reload()
  } else {
    if (window.confirm(message)) {
      window.location.reload()
    }
  }
}
