






































import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
export default mixins(DraftElement).extend({
  name: 'NoDataInfo',
  props: {
    description: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: require('@/assets/empty.png')
    },
    buttonText: {
      type: String,
      default: null
    },
    redirectToRouteName: {
      type: String,
      default: null
    }
  },
  data () {
    return {

    }
  },
  computed: {

  }
})
