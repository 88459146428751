import { Expose, Type } from 'class-transformer'
import DefaultImage from '@/calendesk/models/DTO/Response/DefaultImage'
import WorkingHour from '@/calendesk/models/DTO/Response/WorkingHour'

export default class Configuration {
  @Expose({ name: 'beta_features_enabled' })
  public betaFeaturesEnabled!: boolean

  @Expose({ name: 'schedules_v2_enabled' })
  public schedulesV2enabled!: boolean

  @Expose({ name: 'company_name' })
  public companyName!: string

  @Expose({ name: 'company_country_iso_code' })
  public companyCountryIsoCode!: string

  @Expose({ name: 'image_base_url' })
  public imageBaseUrl!: string

  @Expose({ name: 'preferred_phone_countries' })
  public preferredPhoneCountries!: Array<string>

  @Expose({ name: 'time_zone' })
  public timeZone!: string

  @Expose({ name: 'company_location' })
  @Type(() => DefaultImage)
  public companyLocation!: DefaultImage

  @Expose({ name: 'calendar_slots_duration' })
  public calendarSlotsDuration!: string

  @Expose({ name: 'calendar_slots_label_interval' })
  public calendarSlotsLabelInterval!: string

  @Expose({ name: 'is_web_for_tenants_enabled' })
  public isWebForTenantsEnabled!: boolean

  @Expose({ name: 'company_domain' })
  public companyDomain!: string

  @Expose()
  public currency!: string

  @Expose()
  public facebook!: string

  @Expose()
  public instagram!: string

  @Expose()
  public twitter!: string

  @Expose()
  public youtube!: string

  @Expose()
  public language!: string

  @Expose({ name: 'web_draft_id' })
  public webDraftId!: number

  @Expose({ name: 'web_template_id' })
  public webTemplateId!: number

  @Expose({ name: 'web_draft_id_publishing' })
  public webDraftIdPublishing!: number

  @Expose({ name: 'company_email' })
  public companyEmail!: string

  @Expose({ name: 'company_street' })
  public companyStreet!: string

  @Expose({ name: 'company_address' })
  public companyAddress!: string

  @Expose({ name: 'company_postal_code' })
  public companyPostalCode!: string

  @Expose({ name: 'company_city' })
  public companyCity!: string

  @Expose({ name: 'company_phone' })
  public companyPhone!: string

  @Expose({ name: 'company_working_hours' })
  @Type(() => WorkingHour)
  public companyWorkingHours!: WorkingHour[]

  @Expose({ name: 'display_company_working_hours' })
  public displayCompanyWorkingHours!: Array<Record<string, string | null>>
}
