export default class StripeConnectSubscriptionData {
  public subscription_id: number
  public coupon: string | null
  public gdpr_fields: string | null

  public constructor (subscription_id: number, coupon: string | null, gdprFields: string | null) {
    this.subscription_id = subscription_id
    this.coupon = coupon
    this.gdpr_fields = gdprFields
  }
}
