import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import GetCompanyDataRequestData from '@/calendesk/models/DTO/Request/GetCompanyDataRequestData'
import CompanyData from '@/calendesk/models/DTO/Response/CompanyData'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import { mapActions } from 'vuex'

export default mixins(DraftElement).extend({
  data () {
    return {
      companyVatIsoPrefix: null as string | null,
      companyTaxNumber: null as string | null,
      companyName: null as string | null,
      companyStreet: null as string | null,
      companyPostalCode: null as string | null,
      companyCity: null as string | null,
      companyCountryIsoCode: null as string | null,
      email: null as string | null,
      name: null as string | null,
      surname: null as string | null,
      phone: null as string | null,
      showBillingData: false,
      isLoadingCompanyData: false,
      billingDataType: 0,
      billingDataTypeForcedToCompany: false
    }
  },
  computed: {
    companyPostalCodeIsRequired (): boolean {
      const countriesThatNotRequirePostalCode = ['cl']

      return !(countriesThatNotRequirePostalCode.includes(this.appConfiguration.companyCountryIsoCode))
    },
    companyDataFilled (): boolean {
      return (!!this.companyTaxNumber ||
        !!this.companyName ||
        !!this.companyStreet ||
        !!this.companyPostalCode ||
        !!this.companyCity)
    },
    billingDataTypeCompany (): boolean {
      return this.billingDataType === 0
    },
    billingDataTypePerson (): boolean {
      return this.billingDataType === 1
    },
    requireBillingData (): boolean {
      // Abstract, implement yourself.
      return false
    }
  },
  watch: {
    isUserLogged () {
      this.reloadUserData()
      this.reloadCompanyData()
    },
    billingDataTypeForcedToCompany () {
      this.setBillingDataType()
    }
  },
  created () {
    this.reloadUserData()
    this.reloadCompanyData()
    this.setFormDataFromUrl()
  },
  methods: {
    ...mapActions({
      getCompanyData: 'user/getCompanyData'
    }),
    setFormDataFromUrl () {
      const queryParams = this.$route.query

      if (queryParams && Object.keys(queryParams).length > 0) {
        // Define a helper function to safely get the first item if the query parameter is an array, or return the string/null value.
        const getQueryParamValue = (param: string | (string | null)[] | null): string | null => {
          if (Array.isArray(param)) {
            // If it's an array, return the first item or null if the array is empty.
            return param.length > 0 ? param[0] : null
          }
          // If it's not an array, return it directly.
          return param
        }

        // Special handling for the phone field to ensure it includes the '+' prefix if missing
        const getPhoneParamValue = (phoneParam: string | (string | null)[] | null): string | null => {
          let phoneValue = getQueryParamValue(phoneParam)
          // Check if the phone value exists and doesn't start with '+', then prepend it
          if (phoneValue && !phoneValue.startsWith('+')) {
            phoneValue = `+${phoneValue}`
          }
          return phoneValue
        }

        // Use the helper function to safely assign values to your component's data properties.
        this.companyVatIsoPrefix = getQueryParamValue(queryParams.companyVatIsoPrefix) || this.companyVatIsoPrefix
        this.companyTaxNumber = getQueryParamValue(queryParams.companyTaxNumber) || this.companyTaxNumber
        this.companyName = getQueryParamValue(queryParams.companyName) || this.companyName
        this.companyStreet = getQueryParamValue(queryParams.companyStreet) || this.companyStreet
        this.companyPostalCode = getQueryParamValue(queryParams.companyPostalCode) || this.companyPostalCode
        this.companyCity = getQueryParamValue(queryParams.companyCity) || this.companyCity
        this.companyCountryIsoCode = getQueryParamValue(queryParams.companyCountryIsoCode) || this.companyCountryIsoCode
        this.email = getQueryParamValue(queryParams.email) || this.email
        this.name = getQueryParamValue(queryParams.name) || this.name
        this.surname = getQueryParamValue(queryParams.surname) || this.surname
        this.phone = getPhoneParamValue(queryParams.phone) || this.phone

        this.setBillingDataType()

        const addressParamsExist = this.companyName || this.companyStreet || this.companyCity ||
          this.companyPostalCode || this.companyTaxNumber

        if (addressParamsExist) {
          this.showBillingData = true
        }
      }
    },

    reloadUserData () {
      if (this.isUserLogged) {
        this.name = this.user.name
        this.surname = this.user.surname
        this.email = this.user.email
        this.phone = this.user.defaultPhone ? this.user.defaultPhone.e164 : null
      }
    },
    reloadCompanyData () {
      if (this.isUserLogged) {
        if (this.user.hasDefaultAddress()) {
          this.showBillingData = true
          this.companyName = this.user.defaultAddress.name
          this.companyVatIsoPrefix = this.user.defaultAddress.vatIsoPrefix
          this.companyTaxNumber = this.user.defaultAddress.taxNumber
          this.companyStreet = this.user.defaultAddress.street
          this.companyCity = this.user.defaultAddress.city
          this.companyPostalCode = this.user.defaultAddress.postalCode
          this.companyCountryIsoCode = this.user.defaultAddress.countryIsoCode
        } else {
          this.companyVatIsoPrefix = (this.appConfiguration.companyCountryIsoCode || this.appConfiguration.language).toUpperCase()
          this.companyCountryIsoCode = (this.appConfiguration.companyCountryIsoCode || this.appConfiguration.language).toLowerCase()
        }
      } else {
        this.companyVatIsoPrefix = (this.appConfiguration.companyCountryIsoCode || this.appConfiguration.language).toUpperCase()
        this.companyCountryIsoCode = (this.appConfiguration.companyCountryIsoCode || this.appConfiguration.language).toLowerCase()
      }

      this.setBillingDataType()
    },
    setBillingDataType () {
      if ((window.defaultBillingDataType && window.defaultBillingDataType === 1) ||
        (window.defaultBillingDataType !== 1 &&
          !this.billingDataTypeForcedToCompany &&
          !this.companyTaxNumber &&
          !this.companyName &&
          !this.companyVatIsoPrefix)) {
        this.billingDataType = 1
      } else {
        this.billingDataType = 0
      }
    },
    getCompanyDataHandler () {
      this.isLoadingCompanyData = true

      let prefix = this.companyVatIsoPrefix

      if (!prefix) {
        prefix = this.appConfiguration.language
      }

      this.getCompanyData(new GetCompanyDataRequestData(prefix, this.companyTaxNumber))
        .then((data: CompanyData) => {
          this.companyName = data.name
          this.companyStreet = data.street
          this.companyCity = data.city
          this.companyPostalCode = data.postalCode
          this.companyCountryIsoCode = data.countryIsoCode
          this.companyTaxNumber = data.taxNumber
        }).catch(() => {
          errorNotification('get_company_data_failed', null, false)
        }).finally(() => {
          this.isLoadingCompanyData = false
        })
    }
  }
})
