import { Expose, Type } from 'class-transformer'
import { BookingStatus } from '@/calendesk/models/DTO/Response/BookingStatus'
import Service from '@/calendesk/models/DTO/Response/Service'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import ServiceType from '@/calendesk/models/DTO/Response/ServiceType'
import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'
import CustomField from '@/calendesk/models/DTO/Response/CustomField'
import moment from 'moment'
import Product from '@/calendesk/models/DTO/Response/Product'

export default class Booking {
  @Expose()
  public id!: number

  @Expose({ name: 'employee_id' })
  public employeeId!: number | null

  @Expose({ name: 'service_id' })
  public serviceId!: number | null

  @Expose({ name: 'service_type_id' })
  public serviceTypeId!: string | null

  @Expose({ name: 'start_date' })
  public startDate!: string

  @Expose({ name: 'end_date' })
  public endDate!: string

  @Expose({ name: 'start_time' })
  public startTime!: string | null

  @Expose({ name: 'end_time' })
  public endTime!: string | null

  @Expose({ name: 'starts_at' })
  public startsAt!: string

  @Expose({ name: 'ends_at' })
  public endsAt!: string

  @Expose({ name: 'customer_time_zone' })
  public customerTimeZone!: string | null

  @Expose()
  public status!: BookingStatus

  @Expose()
  public control!: string

  @Type(() => ServiceLocation)
  public location!: ServiceLocation | null

  @Expose()
  public paid!: boolean

  @Expose({ name: 'payment_method' })
  public paymentMethod!: string | null

  @Expose({ name: 'paid_with_user_subscription_id' })
  public paidWithUserSubscriptionId!: string | null

  @Expose({ name: 'google_meet_url' })
  public googleMeetUrl!: string | null

  @Expose({ name: 'zoom_join_url' })
  public zoomJoinUrl!: string | null

  @Expose({ name: 'teams_url' })
  public teamsUrl!: string | null

  @Expose({ name: 'skype_url' })
  public skypeUrl!: string | null

  @Expose({ name: 'customer_whatsapp_url' })
  public customerWhatsAppUrl!: string | null

  @Expose({ name: 'employee_whatsapp_url' })
  public employeeWhatsAppUrl!: string | null

  @Expose({ name: 'custom_fields' })
  @Type(() => CustomField)
  public customFields!: Array<CustomField> | null

  @Expose({ name: 'payment_transaction' })
  public paymentTransaction!: string | null

  @Expose()
  @Type(() => Service)
  public service!: Service | null

  @Expose({ name: 'service_type' })
  @Type(() => ServiceType)
  public serviceType!: ServiceType | null

  @Expose()
  @Type(() => Employee)
  public employee!: Employee | null

  @Expose({ name: 'simple_store_products' })
  public simpleStoreProducts!: Array<Product> | null

  public getPrice () {
    if (this.service) {
      let basePrice = this.service.price
      if (this.serviceType) {
        basePrice = this.serviceType.price
      }

      let productPrice = 0

      if (this.simpleStoreProducts) {
        this.simpleStoreProducts.forEach(product => {
          productPrice += product.price
        })
      }

      return basePrice + productPrice
    }

    return 0
  }

  public canBeCanceled (): boolean {
    const maxCancelTime = moment(this.startsAt).subtract(this.service?.cancelTimeBefore, 'minutes')
    return this.status !== BookingStatus.CANCELLED && moment(maxCancelTime).isAfter(moment())
  }

  public canBeRescheduled (): boolean {
    const maxRescheduleTime = moment(this.startsAt).subtract(this.service?.changeTimeBefore, 'minutes')
    return this.status !== BookingStatus.CANCELLED && moment(maxRescheduleTime).isAfter(moment())
  }

  public getColorForStatus (): string {
    if (this.status !== 'approved') {
      return 'warning'
    }

    return 'success2'
  }

  public getDuration () {
    if (this.serviceType) {
      return this.serviceType.duration
    }

    return this.service?.duration
  }
}
