import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import { BookingSelect } from './types'
import Booking from '@/calendesk/models/DTO/Response/Booking'
import BookingFilter from '@/calendesk/models/DTO/Response/BookingFilter'
import BookingCreateRequestData from '@/calendesk/models/DTO/Request/BookingCreateRequestData'

export const getters: GetterTree<BookingSelect, RootState> = {
  getBookings (state): Booking[] | null {
    return state.bookings
  },
  getReloadAllBookings (state): boolean {
    return state.reloadAllBookings
  },
  getAvailableFilters (state): BookingFilter | null {
    return state.availableBookingFilters
  },
  getSelectedFilters (state): BookingFilter | null {
    return state.selectedBookingFilters
  },
  getBookingDataToProcess (state): BookingCreateRequestData | null {
    return state.bookingToProcess
  }
}
