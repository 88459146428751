





















import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ConfirmDialog',
  props: {
    componentList: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      dialog: this.$store.state.dialog.confirmDialog
    }
  },
  computed: {
    ...mapGetters({
      confirmDialog: 'dialog/getConfirmDialog'
    }),
    getComponent (): any {
      const component = this.componentList.find((dialog: any) => dialog.type === this.confirmDialog.type)
      return component || null
    }
  },
  watch: {
    'confirmDialog.open' (): void {
      this.dialog = { ...this.confirmDialog }
    },
    'confirmDialog.type' (): void {
      this.dialog = { ...this.confirmDialog }
      this.isLoading = false
    },
    'dialog.open' (): void {
      if (this.dialog.open !== this.confirmDialog.open) {
        this.closeConfirmDialog()
      }
    }
  },
  methods: {
    ...mapActions({
      closeConfirmDialog: 'dialog/closeConfirmDialog'
    })
  }
})
