import { FooterSectionType } from '@/calendesk/models/BuilderTypes'
import SectionComponent from '@/calendesk/models/SectionComponent'

const footers: Array<SectionComponent> = [
  {
    componentId: FooterSectionType.FOOTER1,
    component: () => import('./footer1/Footer1.vue')
  },
  {
    componentId: FooterSectionType.FOOTER2,
    component: () => import('./footer2/Footer2.vue')
  },
  {
    componentId: FooterSectionType.FOOTER3,
    component: () => import('./footer3/Footer3.vue')
  },
  {
    componentId: FooterSectionType.FOOTER4,
    component: () => import('./footer4/Footer4.vue')
  },
  {
    componentId: FooterSectionType.FOOTER5,
    component: () => import('./footer5/Footer5.vue')
  },
  {
    componentId: FooterSectionType.FOOTER6,
    component: () => import('./footer6/Footer6.vue')
  },
  {
    componentId: FooterSectionType.FOOTER7,
    component: () => import('./footer7/Footer7.vue')
  },
  {
    componentId: FooterSectionType.FOOTER8,
    component: () => import('./footer8/Footer8.vue')
  }
]

export default footers
