


























import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
export default Vue.extend({
  name: 'PreviewAsset',
  data () {
    return {
      dialog: this.$store.state.preview.previewAsset
    }
  },
  computed: {
    ...mapGetters({
      previewAsset: 'preview/getPreviewAsset'
    })
  },
  watch: {
    'previewAsset.open' (): void {
      this.dialog = {
        ...this.previewAsset
      }
    },
    'dialog.open' (): void {
      if (this.dialog.open === this.previewAsset.open) {
        return
      }
      this.closePreviewAsset()
    }
  },
  methods: {
    ...mapActions({
      closePreviewAsset: 'preview/closePreviewAsset'
    })
  }
})
