
































































































import Subheader from '@/views/components/Subheader.vue'
import mixins from 'vue-typed-mixins'
import Renderer from '@/calendesk/mixins/Renderer'
import { mapActions, mapGetters } from 'vuex'
import BookingPaymentTransactionsRequestData from '@/calendesk/models/DTO/Request/BookingPaymentTransactionsRequestData'
import CNoDataInfo from '@/components/CNoDataInfo.vue'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import BookingPaymentTransactionResponse from '@/calendesk/models/DTO/Response/BookingPaymentTransactionResponse'
import PaymentTransaction from '@/calendesk/models/DTO/Response/PaymentTransaction'
import AccountNotActivatedInfo from '@/views/components/AccountNotActivatedInfo.vue'
import SimpleStoreProductTransactionResponse
  from '@/calendesk/models/DTO/Response/SimpleStoreProductTransactionResponse'
import { PaginatedResponse } from '@/calendesk/models/DTO/Response/Interface/PaginatedResponse'
import SimpleStoreProductTransaction from '@/calendesk/models/DTO/Response/SimpleStoreProductTransaction'
import SimpleStoreProductTransactionRequestData
  from '@/calendesk/models/DTO/Request/SimpleStoreProductTransactionRequestData'
import UserSubscriptionPaymentTransactionsRequestData
  from '@/calendesk/models/DTO/Request/UserSubscriptionPaymentTransactionsRequestData'
import UserSubscriptionPaymentTransactionResponse
  from '@/calendesk/models/DTO/Response/UserSubscriptionPaymentTransactionResponse'
import UserSubscriptionPaymentTransaction from '@/calendesk/models/DTO/Response/UserSubscriptionPaymentTransaction'
import Page from '@/calendesk/models/DTO/Response/Page'
import { PageType } from '@/calendesk/models/BuilderTypes'
import DataTableFooter from '@/calendesk/models/DataTableFooter'

export default mixins(Renderer).extend({
  name: 'Payments',
  components: { AccountNotActivatedInfo, Subheader, CNoDataInfo },
  data () {
    return {
      dataTableFooterProps: DataTableFooter,
      paymentsTypeRequest: 1,
      paymentTypes: [
        {
          text: this.$trans('booking_payments'),
          value: 1
        },
        {
          text: this.$trans('subscriptions'),
          value: 2
        },
        {
          text: this.$trans('simple_store_product_transactions'),
          value: 3
        }
      ],
      dateFormat: DateTimeFormats,
      items: [] as PaymentTransaction[] | SimpleStoreProductTransaction[] | UserSubscriptionPaymentTransaction[],
      headers: [] as Record<string, any>[],
      isLoadingPayments: false,
      total: 0,
      lastPaginationPage: 1,
      tableOptions: {
        itemsPerPage: 25,
        page: 1,
        sortBy: ['id'],
        sortDesc: [true]
      }
    }
  },
  computed: {
    ...mapGetters({
      bookingPaymentTransactions: 'payments/getBookingPaymentTransactions'
    })
  },
  created () {
    this.reloadHeaders()

    const page = new Page(
      null,
      null,
      this.$trans('payments'),
      PageType.STATIC_PAGE,
      this.$router.currentRoute.name as string,
      null,
      true,
      null)
    this.setCurrentPage(page)

    this.reloadDataDebounced()
    this.$watch('tableOptions', this.reloadDataDebounced)
    this.$watch('paymentsTypeRequest', this.forceReload)
  },
  methods: {
    ...mapActions({
      fetchUserSubscriptionPaymentTransactions: 'subscription/fetchUserSubscriptionPaymentTransactions',
      fetchBookingPaymentTransactions: 'payments/fetchBookingPaymentTransactions',
      fetchSimpleStoreProductPaymentTransactions: 'payments/fetchSimpleStoreProductPaymentTransactions'
    }),
    reloadHeaders () {
      if (this.paymentsTypeRequest === 1) {
        this.headers = [
          { text: this.$trans('number'), value: 'booking.id', sortable: false, class: 'header-style' },
          { text: this.$trans('payment_date'), value: 'paidAt', sortable: false, class: 'header-style' },
          { text: this.$trans('payment_service'), value: 'name', sortable: false, class: 'header-style' },
          { text: this.$trans('payment_price'), value: 'price', sortable: false, class: 'header-style' },
          { text: this.$trans('provider_tag'), value: 'providerTag', sortable: false, class: 'header-style' },
          { text: this.$trans('invoice_number'), value: 'invoiceNumber', sortable: false, class: 'header-style' }
        ]
      } else if (this.paymentsTypeRequest === 2) {
        this.headers = [
          { text: this.$trans('payment_date'), value: 'paidAt', sortable: false, class: 'header-style' },
          { text: this.$trans('subscription'), value: 'name', sortable: false, class: 'header-style' },
          { text: this.$trans('payment_price'), value: 'price', sortable: false, class: 'header-style' },
          { text: this.$trans('provider_tag'), value: 'providerTag', sortable: false, class: 'header-style' },
          { text: this.$trans('invoice_number'), value: 'invoiceNumber', sortable: false, class: 'header-style' }

        ]
      } else if (this.paymentsTypeRequest === 3) {
        this.headers = [
          { text: this.$trans('payment_date'), value: 'paidAt', sortable: false, class: 'header-style' },
          { text: this.$trans('payment_description'), value: 'product.name', sortable: false, class: 'header-style' },
          { text: this.$trans('payment_price'), value: 'price', sortable: false, class: 'header-style' },
          { text: this.$trans('provider_tag'), value: 'providerTag', sortable: false, class: 'header-style' },
          { text: this.$trans('invoice_number'), value: 'invoiceNumber', sortable: false, class: 'header-style' }
        ]
      } else {
        this.headers = []
      }
    },
    forceReload () {
      this.tableOptions.page = 1
      this.reloadDataDebounced()
    },
    reloadDataDebounced () {
      this.isLoadingPayments = true
      this.debounce(this.reloadData, 400)()
    },
    reloadData () {
      this.isLoadingPayments = true

      if (this.paymentsTypeRequest === 1) {
        const data = new BookingPaymentTransactionsRequestData()
        this.fetchBookingPaymentTransactions(this.fillData(data)).then((response: BookingPaymentTransactionResponse) => {
          this.items = response.data
          this.parsePaginatedResponse(response)
        }).finally(() => {
          this.isLoadingPayments = false
        })
      } else if (this.paymentsTypeRequest === 2) {
        const data = new UserSubscriptionPaymentTransactionsRequestData()
        this.fetchUserSubscriptionPaymentTransactions(this.fillData(data)).then((response: UserSubscriptionPaymentTransactionResponse) => {
          this.items = response.data
          this.parsePaginatedResponse(response)
        }).finally(() => {
          this.isLoadingPayments = false
        })
      } else if (this.paymentsTypeRequest === 3) {
        const data = new SimpleStoreProductTransactionRequestData()
        this.fetchSimpleStoreProductPaymentTransactions(this.fillData(data)).then((response: SimpleStoreProductTransactionResponse) => {
          this.items = response.data
          this.parsePaginatedResponse(response)
        }).finally(() => {
          this.isLoadingPayments = false
        })
      }
    },
    fillData (data:
      BookingPaymentTransactionsRequestData |
      UserSubscriptionPaymentTransactionsRequestData |
      SimpleStoreProductTransactionRequestData):
      BookingPaymentTransactionsRequestData |
      UserSubscriptionPaymentTransactionsRequestData |
      SimpleStoreProductTransactionRequestData {
      data.page = this.tableOptions.page
      data.limit = this.tableOptions.itemsPerPage
      data.order_by = this.tableOptions.sortBy[0] ? this.tableOptions.sortBy[0] : 'id'
      data.ascending = this.tableOptions.sortDesc[0] ? 0 : 1

      return data
    },
    parsePaginatedResponse (response: PaginatedResponse) {
      this.lastPaginationPage = response.lastPage
      this.total = response.total
      this.tableOptions.page = response.currentPage

      this.reloadHeaders()
    }
  }
})
