import { MutationTree } from 'vuex'
import { DrawerState } from './types'
import { SideDrawerTypes } from '@/calendesk/models/BuilderTypes'

export const mutations: MutationTree<DrawerState> = {
  SET_SHOW_NAVIGATION_DRAWER (state, showNavigationDrawer: boolean) {
    state.showNavigationDrawer = showNavigationDrawer
  },
  SET_SHOW_SIDE_DRAWER (state, showSideDrawer: boolean) {
    state.showSideDrawer = showSideDrawer
  },
  SET_SHOW_SIDE_DRAWER_TYPE (state, type: SideDrawerTypes) {
    state.sideDrawerType = type
  }
}
