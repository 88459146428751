import PreviewAsset from '@/calendesk/models/PreviewAsset'
import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { Preview } from './types'

export const actions: ActionTree<Preview, RootState> = {
  openPreviewAsset ({ commit }, dialog: PreviewAsset): void {
    commit('SET_PREVIEW_ASSET', dialog)
  },
  closePreviewAsset ({ commit }): void {
    commit('SET_PREVIEW_ASSET', new PreviewAsset())
  }
}
