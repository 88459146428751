import Vue from 'vue'
import Vuetify from 'vuetify'
import icons from '@/styles/icons'
import { pl, en, es } from 'vuetify/lib/locale'

Vue.use(Vuetify)

const theme = {
  primary: '#FFFFFF',
  wb_color_primary__color__: '#FFFFFF',
  wb_color_bg__color__: '#FFFFFF',
  wb_color_text__color__: '#FFFFFF',
  wb_color_bg_2__color__: '#FFFFFF',
  wb_color_text_2__color__: '#FFFFFF',
  subheader_bg: '#F0F0F0',
  error: '#E52121',
  info: '#4FB8FF',
  info_light: '#e9f7ff',
  success: '#DCFFDB',
  success2: '#2ECC71',
  warning: '#FFC107',
  accent: '#979797',
  light_text: '#FFFFFF',
  dark_text: '#263238'
}

export default new Vuetify({
  customVariables: ['@/styles/variables.scss'],
  lang: {
    locales: { pl, en, es },
    current: 'en'
  },
  icons: {
    iconfont: 'mdiSvg',
    values: icons
  },
  theme: {
    dark: false,
    themes: {
      light: theme,
      dark: theme
    },
    options: {
      customProperties: true
    }
  }
})
