import { UserStatus } from '@/calendesk/models/DTO/Response/UserStatus'
import DefaultImage from '@/calendesk/models/DTO/Response/DefaultImage'
import { Expose, Type } from 'class-transformer'
import Address from '@/calendesk/models/DTO/Response/Address'
import Phone from '@/calendesk/models/DTO/Response/Phone'
import store from '@/store'
import UserPreferences from '@/calendesk/models/DTO/UserPreferences'
import BasicUserSubscription from '@/calendesk/models/DTO/Response/BasicUserSubscription'

export default class User {
  public id!: number
  public name!: string | null
  public surname!: string | null
  public email?: string | null

  @Expose({ name: 'card_brand' })
  public cardBrand?: string | null

  @Expose({ name: 'card_last_four' })
  public cardLastFour?: string | null

  @Expose({ name: 'stripe_id' })
  public stripeId?: string | null

  public status!: UserStatus

  public preferences?: UserPreferences | null

  @Type(() => BasicUserSubscription)
  public subscriptions?: Array<BasicUserSubscription>

  @Expose({ name: 'default_image' })
  @Type(() => DefaultImage)
  public defaultImage!: DefaultImage

  @Expose({ name: 'default_address' })
  @Type(() => Address)
  public defaultAddress?: Address | null

  @Expose({ name: 'default_phone' })
  @Type(() => Phone)
  public defaultPhone?: Phone | null

  @Expose({ name: 'date_of_birth' })
  public dateOfBirth?: string | null

  @Expose({ name: 'email_verified_at' })
  public emailVerifiedAt?: string | null

  public getFullName (): string {
    if (this.name && this.surname) {
      return this.name + ' ' + this.surname
    } else if (this.name) {
      return this.name
    } else if (this.surname) {
      return this.surname
    }

    return ''
  }

  public getDefaultImageUrl (): string | null {
    if (this.defaultImage && store.getters['setup/getAppConfiguration']) {
      return store.getters['setup/getAppConfiguration'].imageBaseUrl + this.defaultImage.name
    }

    return require('@/assets/placeholder.jpg')
  }

  public hasDefaultAddress () {
    return (this.defaultAddress !== null &&
      (this.defaultAddress?.name ||
        this.defaultAddress?.street ||
        this.defaultAddress?.postalCode ||
        this.defaultAddress?.city ||
        this.defaultAddress?.countryIsoCode))
  }
}
