import { Expose } from 'class-transformer'

export default class CompanyData {
  public name!: string | null;

  @Expose({ name: 'tax_number' })
  public taxNumber!: string | null;

  public street!: string | null;
  public city!: string | null;

  @Expose({ name: 'postal_code' })
  public postalCode!: string | null;

  @Expose({ name: 'country_iso_code' })
  public countryIsoCode!: string | null;
}
