import { Expose } from 'class-transformer'

export default class WorkingHour {
  public id!: number
  public day!: string
  public open!: string | null
  public close!: string | null

  @Expose({ name: 'is_open' })
  public isOpen!: boolean
}
