import User from '@/calendesk/models/DTO/Response/User'
import SimpleService from '@/calendesk/models/DTO/Response/SimpleService'
import { Type } from 'class-transformer'

export default class Employee {
  public id!: number
  public title!: string | null
  public description!: string | null
  public facebook!: string | null
  public instagram!: string | null
  public youtube!: string | null
  public twitter!: string | null
  public linkedin!: string | null

  @Type(() => User)
  public user!: User

  @Type(() => SimpleService)
  public services!: Array<SimpleService> | null

  public getFullName (): string {
    return this.user.getFullName()
  }

  public getTitle (): string | null {
    return this.title
  }

  public getDescription (): string | null {
    return this.description
  }

  public getDefaultImageUrl (): string | null {
    return this.user.getDefaultImageUrl()
  }

  public getFacebookUrl (): string | null {
    return this.facebook
  }

  public getInstagramUrl (): string | null {
    return this.instagram
  }

  public getYoutubeUrl (): string | null {
    return this.youtube
  }

  public getTwitterUrl (): string | null {
    return this.twitter
  }

  public getLinkedInUrl (): string | null {
    return this.linkedin
  }
}
