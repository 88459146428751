import { MutationTree } from 'vuex'
import { UserState } from './types'
import User from '@/calendesk/models/DTO/Response/User'

export const mutations: MutationTree<UserState> = {
  SET_USER (state, user: User) {
    state.user = user
  },
  SET_AFTER_LOGIN_ACTION (state, afterLoginAction: Function) {
    state.afterLoginAction = afterLoginAction
  }
}
