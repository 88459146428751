import store from '@/store'
import Category from '@/calendesk/models/DTO/Response/Category'
import Service from '@/calendesk/models/DTO/Response/Service'
import cloneClassObject from '@/calendesk/tools/cloneClassObject'

export function findServiceById (serviceId: number): Service | null {
  const categoryAndServices = store.state.service.categoryAndServices

  if (categoryAndServices) {
    let foundCategory: Category | null = null
    let foundService: Service | null = null

    for (const category of categoryAndServices) {
      if (category.services && category.services.length > 0) {
        const service = category.services.find((service: Service) => service.id === serviceId)
        if (service) {
          foundCategory = cloneClassObject(category)
          foundService = cloneClassObject(service)
          break
        }
      }
    }

    if (foundCategory && foundService) {
      foundCategory.services = null
      foundService.category = foundCategory
      return foundService
    }
  }

  return null
}
