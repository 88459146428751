import { MutationTree } from 'vuex'
import { PaymentState } from './types'
import PaymentTransaction from '@/calendesk/models/DTO/Response/PaymentTransaction'
import SimpleStoreProductTransaction from '@/calendesk/models/DTO/Response/SimpleStoreProductTransaction'

export const mutations: MutationTree<PaymentState> = {
  SET_BOOKING_PAYMENT_TRANSACTIONS (state, payload: PaymentTransaction[]) {
    state.bookingPaymentTransactions = payload
  },
  SET_SIMPLE_STORE_PRODUCT_PAYMENT_TRANSACTIONS (state, payload: SimpleStoreProductTransaction[]) {
    state.simpleStoreProductPaymentTransactions = payload
  }
}
