import { DialogTypes } from './DialogTypes'

const dialogList: Array<Record<string, any>> = [
  {
    type: DialogTypes.LOGIN,
    content: () => import('./components/LoginDialog/LoginDialog.vue')
  },
  {
    type: DialogTypes.SIGN_UP,
    content: () => import('./components/SignUpDialog/SignUpDialog.vue')
  },
  {
    type: DialogTypes.FORGOTTEN_PASSWORD,
    content: () => import('./components/ForgottenPasswordDialog/ForgottenPasswordDialog.vue')
  },
  {
    type: DialogTypes.CONFIRMATION_DIALOG,
    content: () => import('./components/ConfirmationDialog/ConfirmationDialog.vue')
  },
  {
    type: DialogTypes.CREATE_BOOKING,
    content: () => import('./components/BookingDialog/BookingDialog.vue')
  },
  {
    type: DialogTypes.ACCOUNT_ACTIVATION_REQUEST,
    content: () => import('./components/AccountActivationDialog.vue')
  },
  {
    type: DialogTypes.RESET_PASSWORD,
    content: () => import('./components/ResetPasswordDialog/ResetPasswordDialog.vue')
  },
  {
    type: DialogTypes.BOOKING_DIALOG_1,
    content: () => import('./components/BookingDialog1/BookingDialog1.vue')
  },
  {
    type: DialogTypes.BOOKING_FORM_DIALOG,
    content: () => import('./components/BookingFormDialog/BookingFormDialog.vue')
  },
  {
    type: DialogTypes.BOOKING_FORM_CONFIRMATION_DIALOG,
    content: () => import('./components/BookingFormConfirmationDialog/BookingFormConfirmationDialog.vue')
  },
  {
    type: DialogTypes.BOOKING_SERVICE_DESCRIPTION_DIALOG,
    content: () => import('./components/BookingServiceDescriptionDialog.vue')
  },
  {
    type: DialogTypes.SIMPLE_STORE_PRODUCT_CONFIRMATION_DIALOG,
    content: () => import('./components/SimpleStoreProductConfirmationDialog.vue')
  },
  {
    type: DialogTypes.BOOKING_CALENDAR_SERVICE_TYPES_DIALOG,
    content: () => import('./components/BookingCalendarServiceTypesDialog.vue')
  }
]

export default dialogList
