import PreviewAsset from '@/calendesk/models/PreviewAsset'
import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import { Preview } from './types'

export const getters: GetterTree<Preview, RootState> = {
  getPreviewAsset (state): PreviewAsset {
    const { previewAsset } = state
    return previewAsset
  }
}
