import truncate from '@/calendesk/filters/truncate'
import { findServiceById } from '@/calendesk/prototypes/findServiceById'

export function getServiceNameForId (serviceId: number): string | null {
  const service = findServiceById(serviceId)
  if (service) {
    return `${truncate(service.category.name, 55)} - ${truncate(service.name, 55)}`
  }

  return null
}
