import Section from './Section'
import { PageType } from '@/calendesk/models/BuilderTypes'
import { Expose, Type } from 'class-transformer'

export default class Page {
  public id!: number | null
  public uuid!: string | null
  public name!: string
  public type!: PageType
  public route!: string | null
  public action!: string | null

  @Expose({ name: 'auth_only' })
  public authOnly!: boolean

  public configuration!: Record<string, any> | null

  @Type(() => Section)
  public sections!: Array<Section> | null

  public constructor (id: number | null, uuid: string | null, name: string, type: PageType, route: string | null, action: string | null, authOnly = false, configuration: Record<string, any> | null) {
    this.uuid = uuid
    this.name = name
    this.type = type
    this.route = route
    this.action = action
    this.authOnly = authOnly
    this.configuration = configuration
  }
}
