import store from '@/store'
export const money = (value: string | number): string => {
  if (!value && value !== 0) {
    return ''
  }

  const currency = store.getters['setup/getAppConfiguration'].currency.toLowerCase() || 'eur'
  const language = store.getters['setup/getAppConfiguration'].language.toLowerCase() || 'en'

  const data = {
    style: 'currency',
    currency
  }

  if (value === 0) {
    return value.toLocaleString(language, data)
  }

  value = Number(value) / 100
  value = value.toLocaleString(language, data)

  return value
}
