import Section from '@/calendesk/models/DTO/Response/Section'
import SectionComponent from '@/calendesk/models/SectionComponent'
import PreviewSection from '@/calendesk/models/PreviewSection'
import Page from '@/calendesk/models/DTO/Response/Page'
import { PageType } from '@/calendesk/models/BuilderTypes'
import Sections from '@/calendesk/sections/section/Sections'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import Vue from 'vue'
import VueGtm from 'vue-gtm'
import router from '@/router'
import { mapActions, mapGetters } from 'vuex'
import log from '@/calendesk/prototypes/log'

export default mixins(DraftElement).extend({
  data () {
    return {
      sectionsRequiredLogin: [] as Array<number>
    }
  },
  mounted () {
    if (!this.getInitialSetup) {
      this.setInitialSetup(true)
      this.initialSetup()
    }
  },
  computed: {
    ...mapGetters({
      getInitialSetup: 'setup/getInitialSetup'
    }),
    sectionComponents (): Array<PreviewSection> | null {
      return this.currentPage && this.currentPage.sections ? this.createPreviewSections(this.currentPage.sections, Sections) : []
    }
  },
  methods: {
    ...mapActions({
      setInitialSetup: 'setup/setInitialSetup'
    }),
    initialSetup () {
      this.setThemeColors()
      this.setGoogleTag()
      this.injectCustomHtml()
      this.initStripe().catch(() => {
        // silent
      })
    },
    setGoogleTag () {
      if (this.draft.configuration.wb_google_tag_id__text__ !== null) {
        try {
          Vue.use(VueGtm, {
            id: this.draft.configuration.wb_google_tag_id__text__, // or ['GTM-xxxxxx', 'GTM-yyyyyy'], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
            defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
            compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
            enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
            debug: false, // Whether or not display console logs debugs (optional)
            loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
            vueRouter: router, // Pass the router instance to automatically sync with router (optional)
            trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
          })
        } catch (error) {
          log.warn('GTM error', error)
        }
      }
    },
    setThemeColors () {
      this.$vuetify.theme.dark = this.draft.configuration.wb_dark_mode__checkbox__

      this.$vuetify.theme.themes.light.primary = this.draft.configuration.wb_color_primary__color__
      this.$vuetify.theme.themes.light.wb_color_primary__color__ = this.draft.configuration.wb_color_primary__color__
      this.$vuetify.theme.themes.light.wb_color_bg__color__ = this.draft.configuration.wb_color_bg__color__
      this.$vuetify.theme.themes.light.wb_color_text__color__ = this.draft.configuration.wb_color_text__color__
      this.$vuetify.theme.themes.light.wb_color_bg_2__color__ = this.draft.configuration.wb_color_bg_2__color__
      this.$vuetify.theme.themes.light.wb_color_text_2__color__ = this.draft.configuration.wb_color_text_2__color__

      this.$vuetify.theme.themes.dark = this.$vuetify.theme.themes.light
    },
    createPreviewSections (sections: Array<Section>, sectionComponent: Array<SectionComponent>): Array<PreviewSection> {
      const sortedSections = sections.sort(
        (sectionA: Section, sectionB: Section) => sectionA.configuration.wb_position - sectionB.configuration.wb_position
      )

      const result: Array<PreviewSection> = []
      sortedSections.forEach((section: Section) => {
        if (this.isLoginHidden && this.sectionsRequiredLogin.includes(section.configuration.component_id)) {
          return
        }
        const previewSection = this.createPreviewSection(section, sectionComponent)

        if (previewSection) {
          result.push(previewSection)
        }
      })

      return result
    },
    createPreviewSection (section: Section, sectionComponents: Array<SectionComponent>): PreviewSection | null {
      if (section.configuration && section.configuration.component_id) {
        const type = sectionComponents.find(_ => _.componentId === section.configuration.component_id)

        if (type) {
          return new PreviewSection(section, type.component)
        }
      }

      return null
    },
    findCurrentPage (): Page | null {
      const path = window.location.pathname
      let currentPage = this.draft.pages.find((page: Page) => '/' + page.route === path)

      if (!currentPage) {
        currentPage = this.draft.pages.find((page: Page) => page.type === PageType.MAIN_PAGE)
      }

      if (!currentPage) {
        currentPage = this.draft.pages.find((page: Page) => page.type === PageType.PAGE)
      }

      return currentPage ?? null
    },
    async addElementAndLoadScript (element: HTMLElement) {
      return new Promise(function (resolve, reject) {
        element.onload = () => {
          resolve(element)
        }

        element.onerror = () => {
          reject(element)
        }

        document.getElementsByTagName('head')[0].appendChild(element)
      })
    },
    async injectCustomHtml () {
      if (this.draft.configuration.wb_custom_code__long_text__ !== null) {
        const code = this.draft.configuration.wb_custom_code__long_text__
        const customJsDocument = new DOMParser().parseFromString(code, 'text/html')

        const elements: any = [
          ...customJsDocument.getElementsByTagName('link'),
          ...customJsDocument.getElementsByTagName('style'),
          ...customJsDocument.getElementsByTagName('script'),
          ...customJsDocument.getElementsByTagName('meta')
        ]

        for (const element of elements) {
          if (element.tagName) {
            const newElement = document.createElement(element.tagName)

            if (element.rel) {
              newElement.rel = element.rel
            }

            if (element.type) {
              newElement.setAttribute('type', element.type)
            }

            if (element.title) {
              newElement.setAttribute('title', element.title)
            }

            if (element.href) {
              newElement.setAttribute('href', element.href)
            }

            if (element.src) {
              newElement.setAttribute('src', element.src)
            }

            if (element.name) {
              newElement.setAttribute('name', element.name)
            }

            if (element.content) {
              newElement.setAttribute('content', element.content)
            }

            if (element.property) {
              newElement.setAttribute('property', element.property)
            }

            if (element.innerHTML) {
              newElement.innerHTML = element.innerHTML
            }

            if (element.tagName.toLowerCase() === 'script' && element.src) {
              newElement.setAttribute('async', !!element.async)

              try {
                await this.addElementAndLoadScript(newElement)
              } catch {
                log.warn('[1] Error while parsing custom code', {
                  element: newElement
                })
              }
            } else {
              try {
                document.getElementsByTagName('head')[0].appendChild(newElement)
              } catch (error) {
                log.warn('[2] Error while parsing custom code', {
                  error,
                  element: newElement
                })
              }
            }
          }
        }
      }
    }
  }
})
