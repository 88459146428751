



















import { errorNotification } from '@/calendesk/prototypes/notifications'
import { mapActions, mapGetters } from 'vuex'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'

export default mixins(DraftElement).extend({
  name: 'AccountNotActivatedInfo',
  mixins: [],
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'user/getUser'
    })
  },
  methods: {
    ...mapActions({
      resendActivationLink: 'user/resendActivationLink'
    }),
    sendAgain () {
      this.isLoading = true
      this.resendActivationLink(this.getUser.email)
        .then(() => {
          this.openDialog(new Dialog(
            true,
            DialogTypes.CONFIRMATION_DIALOG,
            this.$trans('confirm_send_activate_link_dialog_subtitle'),
            DialogSize.SMALL,
            false,
            'center'))
        })
        .catch(error => {
          errorNotification('send_again_fail', error, false)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
})
