import { ActionTree } from 'vuex'
import { UserState } from './types'
import { RootState } from '../../types'
import api from '@/calendesk/api/APIClient'
import { getAccessToken, tokenOperations } from '@/calendesk/prototypes/token'
import User from '@/calendesk/models/DTO/Response/User'
import { plainToClass } from 'class-transformer'
import UpdateUserRequestData from '@/calendesk/models/DTO/Request/UpdateUserRequestData'
import ResetPasswordRequestData from '@/calendesk/models/DTO/Request/ResetPasswordRequestData'
import NewsletterSignupRequestData from '@/calendesk/models/DTO/Request/NewsletterSignupRequestData'
import GetCompanyDataRequestData from '@/calendesk/models/DTO/Request/GetCompanyDataRequestData'
import CompanyData from '@/calendesk/models/DTO/Response/CompanyData'
import UserPreferences from '@/calendesk/models/DTO/UserPreferences'
import * as storage from '@/calendesk/tools/storage'

export const actions: ActionTree<UserState, RootState> = {
  loginUserAndStoreSession ({ dispatch }, credentials: Record<string, string>): Promise<void> {
    return new Promise((resolve, reject) => {
      api.login(credentials).then(({ data }) => {
        tokenOperations(data)
          dispatch('fetchData')
            ?.then(() => {
              resolve()
            })
            ?.catch(error => {
              reject(error)
            })
      })
        ?.catch(error => {
          reject(error)
        })
    })
  },
  logoutUserAndDestroySession ({ commit }): Promise<void> {
    return new Promise((resolve, reject) => {
      api
        .logout()
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        }).finally(() => {
          const tenant = storage.local.getItem('tenant')
          const draftUuid = storage.local.getItem('draft_uuid')

          storage.local.clear()
          if (tenant && draftUuid) {
            storage.local.setItem('tenant', tenant)
            storage.local.setItem('draft_uuid', draftUuid)
          }
          commit('SET_USER', null)
        })
    })
  },
  signUpUserAndStoreSession ({ dispatch }, payload: Record<string, string>): Promise<void> {
    return new Promise((resolve, reject) => {
      api.signUpUserAndStoreSession(payload)
        ?.then(({ data }) => {
          tokenOperations(data)

          dispatch('fetchData')
            ?.then(async (user) => {
              if (typeof (window as any).calendeskUserCreated === 'function') {
                await (window as any).calendeskUserCreated(user)
              }

              resolve()
            })
            ?.catch(error => {
              reject(error)
            })
        })
        ?.catch(error => {
          reject(error)
        })
    })
  },
  forgottenPassword (state, email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      api.forgottenPassword(email)
        ?.then(() => {
          resolve()
        })
        ?.catch(error => {
          reject(error)
        })
    })
  },
  resetPassword (state, data: ResetPasswordRequestData): Promise<void> {
    return new Promise((resolve, reject) => {
      api.resetPassword(data, data.token)
        ?.then(() => {
          resolve()
        })
        ?.catch(error => {
          reject(error)
        })
    })
  },
  fetchData ({ commit }): Promise<User> {
    return new Promise((resolve, reject) => {
      api.getUserData().then(({ data }) => {
        const user = plainToClass(User, data)
        commit('SET_USER', user)
        resolve(user)
      }).catch(error => {
        reject(error)
      })
    })
  },
  uploadAvatar ({ dispatch }, data: FormData): Promise<User> {
    return new Promise((resolve, reject) => {
      api.uploadPicture(data).then(() => {
        dispatch('fetchData').then((user: User) => {
          resolve(user)
        })
      })
        ?.catch(error => {
          reject(error)
        })
    })
  },
  updateData ({ commit, dispatch }, data: UpdateUserRequestData): Promise<User> {
    return new Promise((resolve, reject) => {
      api.updateUsersData(data).then(({ data }) => {
        const user = plainToClass(User, data)
        commit('SET_USER', user)
        dispatch('stripe/init', null, { root: true }).catch(() => {
          // silent
        })
        resolve(user)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateUserPreferencesData (context, data: UserPreferences): Promise<void> {
    return new Promise((resolve, reject) => {
      api.updateUserPreferencesData(data).then(() => {
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  resendActivationLink (context, email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      api.resendActivationLink(email).then(() => {
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  activateAccount ({ dispatch }, token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      api.activateUserAccount(token).then(() => {
        if (getAccessToken()) {
          dispatch('fetchData').finally(() => {
            resolve()
          })
        } else {
          resolve()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  newsletterSignup (context, email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      api.newsletterSignup(new NewsletterSignupRequestData(email)).then(() => {
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  resendNewsletterActivationLink (context, email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      api.resendNewsletterActivationLink(email).then(() => {
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  newsletterActivateEmail (context, token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      api.newsletterActivateEmail(token).then(() => {
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  newsletterUnsubscribeEmail (context, token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      api.newsletterUnsubscribeEmail(token).then(() => {
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  getCompanyData (context, data: GetCompanyDataRequestData): Promise<CompanyData> {
    return new Promise((resolve, reject) => {
      if (data.countryISOCode && data.taxNumber) {
        api.getCompanyData(data.countryISOCode, data.taxNumber).then(({ data }) => {
          resolve(plainToClass(CompanyData, data))
        }).catch(error => {
          reject(error)
        })
      } else {
        reject(new Error('Missing countryISOCode or taxNumber.'))
      }
    })
  },
  setAfterLoginAction ({ commit }, afterLoginAction: Function | null): void {
    commit('SET_AFTER_LOGIN_ACTION', afterLoginAction)
  }
}
