import { MutationTree } from 'vuex'
import { StripeSelect } from './types'

export const mutations: MutationTree<StripeSelect> = {
  SET_STRIPE (state, payload: Stripe) {
    state.stripe = payload
  },
  SET_STRIPE_ACCOUNT_ID (state, payload: string) {
    state.accountId = payload
  }
}
