import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import { PaymentState } from './types'
import PaymentTransaction from '@/calendesk/models/DTO/Response/PaymentTransaction'
import SimpleStoreProductTransaction from '@/calendesk/models/DTO/Response/SimpleStoreProductTransaction'

export const getters: GetterTree<PaymentState, RootState> = {
  getBookingPaymentTransactions (state): PaymentTransaction[] | null {
    return state.bookingPaymentTransactions
  },
  getSimpleStoreProductPaymentTransactions (state): SimpleStoreProductTransaction[] | null {
    return state.simpleStoreProductPaymentTransactions
  }
}
