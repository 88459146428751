import * as storage from '@/calendesk/tools/storage'

export const tokenOperations = (data: Record<string, any>) => {
  storage.local.setItem('accessToken', data.access_token)
  storage.local.setItem('refreshToken', data.refresh_token)

  const expirationDate = new Date()
  expirationDate.setSeconds(expirationDate.getSeconds() + data.expires_in)
  storage.local.setItem('tokenExpiresAt', String(expirationDate.getTime()))
}

export function getAccessToken (): string | null {
  return storage.local.getItem('accessToken')
}
