import { Expose } from 'class-transformer'
import store from '@/store'

export default class DefaultImage {
  public id!: number
  public name!: string

  @Expose({ name: 'user_image_id' })
  public userImageId!: number

  public getUrl (): string | null {
    if (this.name && store.getters['setup/getAppConfiguration']) {
      return store.getters['setup/getAppConfiguration'].imageBaseUrl + this.name
    }

    return null
  }
}
