



















import mixins from 'vue-typed-mixins'
import Renderer from '@/calendesk/mixins/Renderer'
import { PageType } from '@/calendesk/models/BuilderTypes'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import { mapActions } from 'vuex'
import Booking from '@/calendesk/models/DTO/Response/Booking'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import PreviewSection from '@/calendesk/models/PreviewSection'
import EmailExtraVerification from '@/components/ExtraEmailVerificationDialog.vue'

export default mixins(Renderer).extend({
  name: 'Home',
  components: { EmailExtraVerification },
  mounted () {
    window.addEventListener('popstate', this.loadCurrentPage)

    this.checkForHiddenActions()
    this.loadCurrentPage()
  },
  beforeDestroy () {
    window.removeEventListener('popstate', this.loadCurrentPage)
  },
  methods: {
    ...mapActions({
      activateUserAccount: 'user/activateAccount',
      newsletterActivateEmail: 'user/newsletterActivateEmail',
      newsletterUnsubscribeEmail: 'user/newsletterUnsubscribeEmail',
      getBookingByControl: 'booking/fetchBookingByControl'
    }),
    checkForHiddenActions () {
      if (this.$route.name === 'account_activation') {
        this.handleAccountActivation()
      } else if (this.$route.name === 'password_reset') {
        this.handlePasswordReset()
      } else if (this.$route.name === 'newsletter_subscribe') {
        this.handleNewsletterSubscribe()
      } else if (this.$route.name === 'newsletter_unsubscribe') {
        this.handleNewsletterUnsubscribe()
      } else if (this.$route.name === 'payment_success') {
        this.handlePaymentSuccess()
      } else if (this.$route.name === 'payment_fail') {
        this.handlePaymentFail()
      } else if (this.$route.name === 'booking_control') {
        this.handleBookingControl(this.$route.params.control)
      }
    },
    loadCurrentPage () {
      if (this.initialLoading) {
        this.setInitialLoading(false)
        this.setPreloader(true)
      }

      const currentPage = this.findCurrentPage()

      if (currentPage) {
        if (currentPage.type === PageType.REDIRECT) {
          window.location.replace(currentPage.action as string)
        } else {
          if (currentPage.authOnly && !this.isUserLogged) {
            this.redirectToMainPage()
          } else {
            this.setRequestedPage(currentPage).then(() => {
              if (currentPage.type === PageType.MAIN_PAGE) {
                if (this.$router.currentRoute.path !== '/' && this.$router.currentRoute.path !== '') {
                  this.$router.push({ path: '/' })
                }
              } else if (currentPage.type === PageType.BLOG_HANDY) {
                this.addBlogHandyIntegration()
              }

              this.setPreloader(false)
            }).catch((error) => {
              errorNotification(null, error, false)
            })
          }
        }
      } else {
        errorNotification(null, null, false)
      }
    },
    handleAccountActivation () {
      this.activateUserAccount(this.$route.params.token).then(() => {
        if (this.draftConfiguration.wb_account_activation_success_url__url__) {
          window.location.replace(this.draftConfiguration.wb_account_activation_success_url__url__)
        } else {
          this.openDialog(new Dialog(
            true,
            DialogTypes.CONFIRMATION_DIALOG,
            this.$trans('account_activation_success'),
            DialogSize.SMALL,
            false,
            'center'))
        }
      }).catch(() => {
        this.openDialog(new Dialog(
          true,
          DialogTypes.CONFIRMATION_DIALOG,
          this.$trans('account_activation_fail'),
          DialogSize.SMALL,
          false,
          'center'))
      })
    },
    handlePasswordReset () {
      this.openDialog(new Dialog(
        true,
        DialogTypes.RESET_PASSWORD,
        this.$trans('reset_password_title'),
        DialogSize.SMALL,
        false,
        'center',
        {
          token: this.$route.params.token
        }))
    },
    handleNewsletterSubscribe () {
      this.newsletterActivateEmail(this.$route.params.token).then(() => {
        if (this.draftConfiguration.wb_newsletter_success_url__url__) {
          window.location.replace(this.draftConfiguration.wb_newsletter_success_url__url__)
        } else {
          this.openDialog(new Dialog(
            true,
            DialogTypes.CONFIRMATION_DIALOG,
            this.$trans('newsletter_subscribe_success'),
            DialogSize.SMALL,
            false,
            'center'))
        }
      }).catch(() => {
        this.openDialog(new Dialog(
          true,
          DialogTypes.CONFIRMATION_DIALOG,
          this.$trans('newsletter_subscribe_fail'),
          DialogSize.SMALL,
          false,
          'center'))
      })
    },
    handleNewsletterUnsubscribe () {
      this.newsletterUnsubscribeEmail(this.$route.params.token).then(() => {
        this.openDialog(new Dialog(
          true,
          DialogTypes.CONFIRMATION_DIALOG,
          this.$trans('newsletter_unsubscribe_success'),
          DialogSize.SMALL,
          false,
          'center'))
      }).catch(() => {
        this.openDialog(new Dialog(
          true,
          DialogTypes.CONFIRMATION_DIALOG,
          this.$trans('newsletter_unsubscribe_fail'),
          DialogSize.SMALL,
          false,
          'center'))
      })
    },
    handlePaymentSuccess () {
      const control = this.$route.query.control

      if (control) {
        this.handleBookingControl(control as string, this.$trans('payment_success_title'), 'calendeskBookingPaymentSuccessful')
      } else {
        this.openDialog(new Dialog(
          true,
          DialogTypes.CONFIRMATION_DIALOG,
          this.$trans('payment_success_title'),
          DialogSize.SMALL,
          false,
          'center'))
      }
    },
    handlePaymentFail () {
      const control = this.$route.query.control

      if (control) {
        this.handleBookingControl(control as string, this.$trans('payment_fail_title'), 'calendeskBookingPaymentUnsuccessful')
      } else {
        this.openDialog(new Dialog(
          true,
          DialogTypes.CONFIRMATION_DIALOG,
          this.$trans('payment_fail_title'),
          DialogSize.SMALL,
          false,
          'center'))
      }
    },
    handleBookingControl (control: string, info: string | null = null, internalAction: string | null = null) {
      this.getBookingByControl(control).then(async (booking: Booking) => {
        if (internalAction) {
          if (typeof (window as any)[internalAction] === 'function') {
            await (window as any)[internalAction](booking)
          }
        }

        this.openDialog(new Dialog(
          true,
          DialogTypes.BOOKING_FORM_CONFIRMATION_DIALOG,
          null,
          DialogSize.MIDDLE,
          false,
          'center',
          {
            bookings: [booking],
            section: null,
            info: info
          },
          true))
      }).catch(() => {
        errorNotification('booking_not_found', null, false)
      })
    },
    getSectionDynamicClass (sectionComponent: PreviewSection) {
      return {
        ['component_' + sectionComponent.section.configuration.component_id]: true
      }
    }
  }
})
