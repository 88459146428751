import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { Common } from './types'
import { RootState } from '../../types'
import Dialog from '@/calendesk/models/Dialog'
import ConfirmDialog from '@/calendesk/models/ConfirmDialog'

export const state: Common = {
  dialog: new Dialog(),
  closedDialogData: null,
  dialog2: new Dialog(),
  closedDialogData2: null,
  confirmDialog: new ConfirmDialog(),
  closedConfirmDialogData: null,
  confirmDialogLoader: false
}

const namespaced = true

export const dialog: Module<Common, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
