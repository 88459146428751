import { MutationTree } from 'vuex'
import { ServiceState } from './types'
import Service from '@/calendesk/models/DTO/Response/Service'
import Category from '@/calendesk/models/DTO/Response/Category'

export const mutations: MutationTree<ServiceState> = {
  SET_SERVICES (state, payload: Service[]) {
    state.services = payload
  },
  SET_CATEGORY_AND_SERVICES (state, payload: Category[]) {
    state.categoryAndServices = payload
  }
}
