import UserDefaultAddressRequestData from '@/calendesk/models/DTO/Request/UserDefaultAddressRequestData'

export default class UpdateUserRequestData {
  public name?: string | null
  public surname?: string | null
  public default_phone?: string | null
  public default_image_id?: number | null
  public email?: string | null
  public date_of_birth?: string | null
  public old_password?: string | null
  public new_password?: string | null
  public new_password_confirmation?: string | null
  public default_address?: UserDefaultAddressRequestData | null
}
