import SubscriptionProductPrice from '@/calendesk/models/DTO/Response/SubscriptionProductPrice'
import Service from '@/calendesk/models/DTO/Response/Service'
import { Expose, Type } from 'class-transformer'
import GdprFieldData from '@/calendesk/models/GdprFieldData'

export default class Subscription {
  public id!: number

  @Expose({ name: 'subscription_id' })
  public subscriptionId!: string

  public name!: string

  public description!: string | null

  @Expose({ name: 'success_url' })
  public successUrl!: string | null

  @Expose({ name: 'cancel_url' })
  public cancelUrl!: string | null

  @Expose({ name: 'recurring_interval' })
  public recurringInterval!: string | null

  @Expose({ name: 'interval_count' })
  public intervalCount!: number | null

  @Expose({ name: 'service_limit' })
  public serviceLimit!: number | null

  @Expose({ name: 'wants_invoice' })
  public wantsInvoice!: boolean

  @Expose({ name: 'require_billing_data' })
  public requireBillingData!: boolean

  @Expose({ name: 'disable_robot_indexing' })
  public disableRobotIndexing!: boolean

  @Expose({ name: 'only_company_invoice_data_type' })
  public onlyCompanyInvoiceDataType!: boolean

  public tax!: string | null

  @Expose({ name: 'created_at' })
  public createdAt!: string

  @Expose({ name: 'updated_at' })
  public updatedAt!: string

  @Type(() => GdprFieldData)
  @Expose({ name: 'gdpr_fields' })
  public gdprFields!: Array<GdprFieldData> | null

  @Type(() => SubscriptionProductPrice)
  public price!: SubscriptionProductPrice

  @Type(() => Service)
  public services!: Array<Service> | null
}
