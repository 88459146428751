import Dialog from '@/calendesk/models/Dialog'
import { MutationTree } from 'vuex'
import { Common } from './types'
import ConfirmDialog from '@/calendesk/models/ConfirmDialog'

export const mutations: MutationTree<Common> = {
  SET_DIALOG (state, payload: Dialog) {
    state.dialog = payload
  },
  SET_CLOSED_DIALOG_DATA (state, payload: any) {
    state.closedDialogData = payload
  },
  SET_DIALOG_2 (state, payload: Dialog) {
    state.dialog2 = payload
  },
  SET_CLOSED_DIALOG_DATA_2 (state, payload: any) {
    state.closedDialogData2 = payload
  },
  SET_CONFIRM_DIALOG (state, payload: ConfirmDialog) {
    state.confirmDialog = payload
  },
  SET_CLOSED_CONFIRM_DIALOG_DATA (state, payload: any) {
    state.closedConfirmDialogData = payload
  },
  SET_CONFIRM_DIALOG_LOADER (state, loader: boolean) {
    state.confirmDialogLoader = loader
  }
}
