export async function getCaptchaToken (action: string): Promise<string | null> {
  try {
    if (typeof grecaptcha === 'object') {
      // Allowed Characters:
      // - Uppercase letters: A-Z
      // - Lowercase letters: a-z
      // - Underscores: _
      // - Slashes: /
      const sanitizedAction = action
        .replace(/-/g, '_')
        .replace(/[^A-Za-z/_]/g, '')

      return await grecaptcha.enterprise.execute(
        process.env.VUE_APP_RECAPTCHA_CLIENT_KEY,
        { action: sanitizedAction })
    } else {
      return null
    }
  } catch (e) {
    return null
  }
}
