





















import Vue from 'vue'
import { mapGetters } from 'vuex'
import Snackbar from '@/calendesk/models/Snackbar'

export default Vue.extend({
  name: 'Snackbar',
  data () {
    return {
      show: false,
      text: '',
      color: 'success',
      timeout: 5000,
      closable: true
    }
  },
  watch: {
    snackbar (snackbar: Snackbar) {
      this.text = snackbar.text
      this.color = snackbar.color
      this.timeout = snackbar.timeout
      this.closable = snackbar.closable
      this.show = true
    }
  },
  computed: {
    ...mapGetters({
      snackbar: 'snackbar/get'
    }),
    getTextColor () {
      if (this.color === 'success') {
        return 'green--text'
      }

      return 'white--text'
    }
  }
})
