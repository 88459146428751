import { GetterTree } from 'vuex'
import { BuilderState } from './types'
import { RootState } from '../../types'
import Draft from '@/calendesk/models/DTO/Response/Draft'
import Page from '@/calendesk/models/DTO/Response/Page'
import Section from '@/calendesk/models/DTO/Response/Section'

export const getters: GetterTree<BuilderState, RootState> = {
  getDraft (state): Draft | null {
    return state.draft
  },
  getDraftConfiguration (state): Record<string, any> {
    return state.draft ? state.draft.configuration : {}
  },
  getDraftPages (state): Page[] | null {
    return state.draft ? state.draft.pages : null
  },
  getNavbar (state): Section | null {
    return state.draft ? state.draft.navbar : null
  },
  getFooter (state): Section | null {
    return state.draft ? state.draft.footer : null
  },
  getCurrentPage (state): Page | null {
    return state.draft ? state.currentPage : null
  },
  isSectionsLoaderVisible (state): boolean {
    return state.sectionsLoaderVisibility
  },
  getInitialLoading (state): boolean {
    return state.initialLoading
  }
}
