import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import { SubscriptionState } from './types'
import Subscription from '@/calendesk/models/DTO/Response/Subscription'
import UserSubscription from '@/calendesk/models/DTO/Response/UserSubscription'

export const getters: GetterTree<SubscriptionState, RootState> = {
  getAvailableSubscriptions (state): Subscription[] | null {
    return state.availableSubscriptions
  },
  getUserSubscriptions (state): UserSubscription[] | null {
    return state.userSubscriptions || []
  },
  getReloadAllSubscriptions (state): boolean {
    return state.reloadAllSubscriptions
  }
}
