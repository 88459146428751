import { Expose } from 'class-transformer'

export default class UserSubscriptionPaymentTransaction {
  public id!: number

  @Expose({ name: 'subscription_id' })
  public subscriptionId!: number

  @Expose({ name: 'provider_tag' })
  public providerTag!: string

  @Expose({ name: 'transaction_id' })
  public transactionId!: string

  @Expose({ name: 'fakturownia_invoice_id' })
  public fakturowniaInvoiceId!: string

  @Expose({ name: 'invoice_number' })
  public invoiceNumber!: string

  public status!: string

  @Expose({ name: 'paid_at' })
  public paidAt!: string

  public price!: number

  public quantity!: number

  @Expose({ name: 'user_subscription' })
  public userSubscription!: number
}
