















import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  name: 'App',
  computed: {
    ...mapGetters({
      preloader: 'setup/getPreloader'
    })
  }
})
