import Page from './Page'
import Section from './Section'
import { DraftType } from '@/calendesk/models/DTO/Response/DraftType'
import { Expose, Type } from 'class-transformer'

export default class Draft {
  public id!: number
  public type!: DraftType

  public description!: string | null
  public version!: number
  public configuration!: Record<string, any>
  public published!: boolean

  @Expose({ name: 'created_at' })
  public createdAt!: Date

  @Expose({ name: 'updated_at' })
  public updatedAt!: Date

  @Type(() => Section)
  public navbar!: Section

  @Type(() => Section)
  public footer!: Section

  @Type(() => Page)
  public pages!: Array<Page>
}
