import { RouteConfig } from 'vue-router'
import Index from '@/views/Index.vue'
import Home from '@/views/Home.vue'
import Bookings from '@/views/Bookings.vue'
import Account from '@/views/Account.vue'
import Payments from '@/views/Payments.vue'
import Subscription from '@/views/Subscription.vue'
import Subscriptions from '@/views/Subscriptions.vue'

const routes: Array<RouteConfig> = [
  {
    path: '*',
    component: Index,
    children: [
      {
        path: '/payment/success',
        name: 'payment_success',
        component: Home
      },
      {
        path: '/payment/fail',
        name: 'payment_fail',
        component: Home
      },
      {
        path: '/newsletter-subscribe/:token',
        name: 'newsletter_subscribe',
        component: Home
      },
      {
        path: '/newsletter-unsubscribe/:token',
        name: 'newsletter_unsubscribe',
        component: Home
      },
      {
        path: '/account-activation/:token',
        name: 'account_activation',
        component: Home
      },
      {
        path: '/password-reset/:token',
        name: 'password_reset',
        component: Home
      },
      {
        path: '/bookings/:control',
        name: 'booking_control',
        component: Home
      },
      {
        path: '/subscription/:id',
        name: 'subscription',
        component: Subscription
      },
      {
        path: '/account',
        name: 'account',
        component: Account,
        meta: {
          auth: true
        }
      },
      {
        path: '/payments',
        name: 'payments',
        component: Payments,
        meta: {
          auth: true
        }
      },
      {
        path: '/bookings',
        name: 'bookings',
        component: Bookings,
        meta: {
          auth: true
        }
      },
      {
        path: '/subscriptions',
        name: 'subscriptions',
        component: Subscriptions,
        meta: {
          auth: true
        }
      },
      {
        path: '*',
        name: 'home',
        component: Home
      }
    ]
  }
]

export default routes
