import { ServiceStatus } from '@/calendesk/models/DTO/Response/ServiceStatus'
import Category from '@/calendesk/models/DTO/Response/Category'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import ServiceType from '@/calendesk/models/DTO/Response/ServiceType'
import ServiceRange from '@/calendesk/models/DTO/Response/ServiceRange'
import DefaultImage from '@/calendesk/models/DTO/Response/DefaultImage'
import { Expose, Type } from 'class-transformer'
import store from '@/store'
import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'
import Product from '@/calendesk/models/DTO/Response/Product'

export default class Service {
  public id!: number

  @Expose({ name: 'category_id' })
  public categoryId!: number

  public name!: string
  public description!: string | null

  @Expose({ name: 'booking_time_before' })
  public bookingTimeBefore!: number

  @Expose({ name: 'cancel_time_before' })
  public cancelTimeBefore!: number

  @Expose({ name: 'change_time_before' })
  public changeTimeBefore!: number

  @Expose({ name: 'max_people' })
  public maxPeople!: number

  @Expose({ name: 'max_booking_time' })
  public maxBookingTime!: number

  public duration!: number

  @Expose({ name: 'slot_every' })
  public slotsEvery!: number

  public price!: number

  @Expose({ name: 'allow_dynamic_duration' })
  public allowDynamicDuration!: number

  @Expose({ name: 'allow_online_payment' })
  public allowOnlinePayment!: boolean

  @Expose({ name: 'payment_required' })
  public paymentRequired!: boolean

  @Expose({ name: 'only_subscriptions' })
  public onlySubscriptions!: boolean

  public status!: ServiceStatus

  @Expose({ name: 'order_position' })
  public orderPosition!: number

  @Expose({ name: 'default_image' })
  @Type(() => DefaultImage)
  public defaultImage!: DefaultImage | null

  @Type(() => Category)
  public category!: Category

  @Type(() => Employee)
  public employees!: Array<Employee>

  @Type(() => ServiceLocation)
  public locations!: Array<ServiceLocation>

  @Type(() => ServiceType)
  public types!: Array<ServiceType>

  @Expose({ name: 'duration_range' })
  public durationsRange!: ServiceRange

  @Expose({ name: 'service_range' })
  public pricesRange!: ServiceRange

  @Expose({ name: 'simple_store_products' })
  public simpleStoreProducts!: Array<Product>

  @Expose({ name: 'upselling_description' })
  public upsellingDescription: string|null = null

  public getDefaultImageUrl (): string | null {
    if (this.defaultImage && store.getters['setup/getAppConfiguration']) {
      return store.getters['setup/getAppConfiguration'].imageBaseUrl + this.defaultImage.name
    }

    return require('@/assets/placeholder.jpg')
  }

  public isDemoDefaultImage (): boolean {
    return (!this.defaultImage || (!!this.defaultImage && (this.defaultImage.name.includes('demo') || this.defaultImage.name.includes('placeholder'))))
  }

  public isGroupType (): boolean {
    return this.maxPeople > 1
  }

  public getMaxBookingDays (): number {
    const time = this.maxBookingTime // maxBookingTime is stored in minutes

    // Handle edge cases
    if (time === undefined || time === null) {
      // Consider logging or throwing an error if this case is truly a bug
      return 62 // Fallback value if undefined/null
    }

    if (time === 0) {
      // It's unclear what "unlimited" means in terms of days. Returning a high number like 365 could be misleading.
      return 365
    }

    if (time > 525600) { // 525600 minutes in a year (365 days)
      return 365
    }

    // Convert time from minutes to days and round up to the nearest whole number
    // +1 day added to ensure that slots from the next day are partially included (counting from hour to hour).
    return Math.ceil(time / (60 * 24)) + 1
  }
}
