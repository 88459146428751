<template>
  <div>
    <v-row>
      <v-col
        style="max-width: 140px; min-width: 110px;"
        class="mr-0 pr-0"
      >
        <v-autocomplete
          v-model="country"
          :items="countries"
          :filter="customFilter"
          return-object
          :label="$trans('area_code')"
          :prepend-icon="prependIcon"
          :outlined="outlined"
          :dark="dark"
          :dense="dense"
          :filled="filled"
          :flat="flat"
          :readonly="readonly"
          :rounded="rounded"
          :disabled="disabled"
          validate-on-blur
          :rules="[...rules]"
          single-line
          @input="handleChangeAction"
          @change="handleChangeAction"
          hide-details="auto"
          autocomplete="tel-country-code"
        >
          <template
            #selection="{ item }"
            class="d-flex align-center text-center"
          >
            <span>{{ item.showDialCode }}</span>
          </template>
          <template
            #item="{ item }"
            class="d-flex align-center"
          >
            <span>{{ item.showDialCode }}</span> <small class="ml-2">{{ item.name }}</small>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col class="ml-2 pl-0">
        <v-text-field
          v-model="phone"
          :label="$trans('phone')"
          :append-icon="appendIcon"
          :clearable="clearable"
          :dark="dark"
          :dense="dense"
          :filled="filled"
          :flat="flat"
          :outlined="outlined"
          :readonly="readonly"
          :rounded="rounded"
          :clear-icon="clearIcon"
          :placeholder="country ? country.examplePhoneNumber : null"
          :disabled="disabled"
          validate-on-blur
          :rules="[...rules, phoneNumberValidation]"
          @input="handleChangeAction"
          @blur="handleBlurAction"
          @keydown.enter.prevent="handleEnterClicked"
          hide-details="auto"
          autocomplete="tel-national"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import getCountries from '@/calendesk/tools/countries'

const PNF = require('google-libphonenumber').PhoneNumberFormat
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
export default {
  name: 'CPhoneInput',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    prependIcon: {
      type: String,
      default: ''
    },
    appendIcon: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    clearIcon: {
      type: String,
      default: '$close'
    },
    filled: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    defaultCountryCode: {
      type: String,
      default: () => 'pl'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    countries: getCountries(),
    phone: null,
    country: {},
    validationFailed: false
  }),
  computed: {
    phoneNumberValid () {
      if (!this.phone) {
        return true
      }

      if (!this.country || !this.country.iso2UpperCase || this.phone.length < 5 || this.phone.length > 17) {
        return false
      }

      try {
        const phone = this.phone.replace(/\D/g, '')
        const phoneNumber = this.generatePhoneNumber(phone, this.country.iso2UpperCase)

        if (phoneNumber) {
          return phoneUtil.isValidNumberForRegion(phoneNumber, this.country.iso2UpperCase)
        }
      } catch (error) {
        return false
      }

      return false
    }
  },
  watch: {
    value () {
      this.updateProps()
    }
  },
  created () {
    this.setDefaultCountry()
    this.updateProps()
  },
  methods: {
    emitValue () {
      if (!this.phoneNumberValid || !this.phone || !this.country) {
        this.$emit('input', null)
        return
      }

      try {
        const phoneNumber = this.generatePhoneNumber(this.phone, this.country.iso2UpperCase)
        this.$emit('input', phoneUtil.format(phoneNumber, PNF.E164))
      } catch (error) {
        this.$emit('input', null)
      }
    },
    updateProps () {
      if (!this.value || (this.value && (this.value.length > 17 || this.value.length < 4))) {
        return
      }

      try {
        const number = phoneUtil.parse(this.value)
        const countryCode = phoneUtil.getRegionCodeForNumber(number)

        if (countryCode) {
          this.country = this.searchCountry(this.countries, countryCode.toLowerCase())

          // The replace with a country code is done on the full value, there is always a country code there.
          this.phone = this.value.replace(/\D/g, '').replace(this.country.dialCode, '')
          this.emitValue()
        }
      } catch (error) {
        // ignore silent errors with parsing numbers
        // set country which is very likely to choose by user
        this.setDefaultCountry()
      }
    },
    setDefaultCountry () {
      if (this.defaultCountryCode) {
        this.country = this.searchCountry(this.countries, this.defaultCountryCode)

        if (!this.country) {
          this.country = this.searchCountry(this.countries, 'pl')
        }
      }
    },
    handleChangeAction () {
      this.emitValue()
    },
    handleEnterClicked () {
      this.handleBlurAction()
      this.$emit('enter')
    },
    handleBlurAction () {
      this.handleChangeAction()
    },
    phoneNumberValidation () {
      if (this.phoneNumberValid) {
        return true
      }

      return this.$trans('phone_is_invalid')
    },
    searchCountry (array, countryCode) {
      return array.find(country => {
        return country.iso2 === countryCode
      })
    },
    generatePhoneNumber (phoneNumber, countryCode) {
      return phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode)
    },
    getCountryInputText (country) {
      return `${country.showDialCode} ${country.iso2}`
    },
    customFilter (item, queryText) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.iso2
      const textThree = item.showDialCode
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textThree.replace('+', '') === searchText.replace('+', '')
    }
  }
}
</script>
