

















import getCountries from '@/calendesk/tools/countries'
import Vue from 'vue'

export default Vue.extend({
  name: 'CCountryAutocomplete',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Country'
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      country: this.value
    }
  },
  computed: {
    countryData (): Record<string, any> {
      return getCountries().map(el => {
        return {
          text: el.name,
          value: el.iso2
        }
      })
    }
  },
  watch: {
    value () {
      this.country = this.value
    }
  },
  methods: {
    inputEventHandle () {
      this.$emit('input', this.country)
    },
    changeEventHandle () {
      this.$emit('change', this.country)
    }
  }
})
