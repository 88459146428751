import { trans } from '@/calendesk/prototypes/trans'

export default function (value: number, key: string, lowercase = false, separator = ' '): string {
  const keyValue = (value > 5) ? 5 : value

  const result = value + separator + trans(key + '_plural_' + keyValue)

  if (lowercase) {
    return result.toLowerCase()
  }

  return result
}
