export default class Snackbar {
  public timeout: number;
  public color: string;
  public text: string;
  public closable: boolean;

  constructor (text = 'default text', color = 'success', timeout = 5000, closable = true) {
    this.timeout = timeout
    this.color = color
    this.text = text
    this.closable = closable
  }
}
