import Dialog from '@/calendesk/models/Dialog'
import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { Common } from './types'
import ConfirmDialog from '@/calendesk/models/ConfirmDialog'

export const actions: ActionTree<Common, RootState> = {
  openDialog ({ commit }, dialog: Dialog): void {
    commit('SET_DIALOG', dialog)
    commit('SET_CLOSED_DIALOG_DATA', null)
  },
  closeDialog ({ commit }, closedDialogData?: any): void {
    commit('SET_DIALOG', new Dialog())

    if (closedDialogData && closedDialogData.data) {
      commit('SET_CLOSED_DIALOG_DATA', closedDialogData)
    }
  },
  openDialog2 ({ commit }, dialog: Dialog): void {
    commit('SET_DIALOG_2', dialog)
    commit('SET_CLOSED_DIALOG_DATA_2', null)
  },
  closeDialog2 ({ commit }, closedDialogData?: any): void {
    commit('SET_DIALOG_2', new Dialog())

    if (closedDialogData && closedDialogData.data) {
      commit('SET_CLOSED_DIALOG_DATA_2', closedDialogData)
    }
  },
  openConfirmDialog ({ commit }, dialog: ConfirmDialog): void {
    commit('SET_CONFIRM_DIALOG', dialog)
    commit('SET_CLOSED_CONFIRM_DIALOG_DATA', null)
  },
  closeConfirmDialog ({ commit }, closedDialogData?: any): void {
    commit('SET_CONFIRM_DIALOG', new ConfirmDialog())

    if (closedDialogData && closedDialogData.data) {
      commit('SET_CLOSED_CONFIRM_DIALOG_DATA', closedDialogData)
    }
  },
  setConfirmDialogLoader ({ commit }, loader: boolean): void {
    commit('SET_CONFIRM_DIALOG_LOADER', loader)
  }
}
